/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsUsersChangeAnonymousUserName
 */
export interface MelcoCoreModelsUsersChangeAnonymousUserName {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUsersChangeAnonymousUserName
     */
    user_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUsersChangeAnonymousUserName
     */
    new_user_name?: string | null;
}

export function MelcoCoreModelsUsersChangeAnonymousUserNameFromJSON(json: any): MelcoCoreModelsUsersChangeAnonymousUserName {
    return MelcoCoreModelsUsersChangeAnonymousUserNameFromJSONTyped(json, false);
}

export function MelcoCoreModelsUsersChangeAnonymousUserNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsUsersChangeAnonymousUserName {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user_name': !exists(json, 'user_name') ? undefined : json['user_name'],
        'new_user_name': !exists(json, 'new_user_name') ? undefined : json['new_user_name'],
    };
}

export function MelcoCoreModelsUsersChangeAnonymousUserNameToJSON(value?: MelcoCoreModelsUsersChangeAnonymousUserName | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_name': value.user_name,
        'new_user_name': value.new_user_name,
    };
}


