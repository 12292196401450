/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsEfsUserFormatListItem
 */
export interface MelcoCoreModelsEfsUserFormatListItem {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsUserFormatListItem
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsUserFormatListItem
     */
    key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsUserFormatListItem
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsUserFormatListItem
     */
    desc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsUserFormatListItem
     */
    display_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsUserFormatListItem
     */
    xml_orders_xpath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsUserFormatListItem
     */
    unique_field?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsEfsUserFormatListItem
     */
    headers?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsEfsUserFormatListItem
     */
    config_count?: number;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsUserFormatListItem
     */
    type?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsEfsUserFormatListItem
     */
    created_date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsEfsUserFormatListItem
     */
    updated_date?: Date | null;
}

export function MelcoCoreModelsEfsUserFormatListItemFromJSON(json: any): MelcoCoreModelsEfsUserFormatListItem {
    return MelcoCoreModelsEfsUserFormatListItemFromJSONTyped(json, false);
}

export function MelcoCoreModelsEfsUserFormatListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsEfsUserFormatListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'desc': !exists(json, 'desc') ? undefined : json['desc'],
        'display_name': !exists(json, 'display_name') ? undefined : json['display_name'],
        'xml_orders_xpath': !exists(json, 'xml_orders_xpath') ? undefined : json['xml_orders_xpath'],
        'unique_field': !exists(json, 'unique_field') ? undefined : json['unique_field'],
        'headers': !exists(json, 'headers') ? undefined : json['headers'],
        'config_count': !exists(json, 'config_count') ? undefined : json['config_count'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'created_date': !exists(json, 'created_date') ? undefined : (json['created_date'] === null ? null : new Date(json['created_date'])),
        'updated_date': !exists(json, 'updated_date') ? undefined : (json['updated_date'] === null ? null : new Date(json['updated_date'])),
    };
}

export function MelcoCoreModelsEfsUserFormatListItemToJSON(value?: MelcoCoreModelsEfsUserFormatListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'key': value.key,
        'name': value.name,
        'desc': value.desc,
        'display_name': value.display_name,
        'xml_orders_xpath': value.xml_orders_xpath,
        'unique_field': value.unique_field,
        'headers': value.headers,
        'config_count': value.config_count,
        'type': value.type,
        'created_date': value.created_date === undefined ? undefined : (value.created_date === null ? null : value.created_date.toISOString()),
        'updated_date': value.updated_date === undefined ? undefined : (value.updated_date === null ? null : value.updated_date.toISOString()),
    };
}


