/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsEfsMappingFileAttribute,
    MelcoCoreModelsEfsMappingFileAttributeFromJSON,
    MelcoCoreModelsEfsMappingFileAttributeFromJSONTyped,
    MelcoCoreModelsEfsMappingFileAttributeToJSON,
    MelcoCoreModelsEfsSchemaListItem,
    MelcoCoreModelsEfsSchemaListItemFromJSON,
    MelcoCoreModelsEfsSchemaListItemFromJSONTyped,
    MelcoCoreModelsEfsSchemaListItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsEfsFormat
 */
export interface MelcoCoreModelsEfsFormat {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsFormat
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsFormat
     */
    key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsFormat
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsFormat
     */
    desc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsFormat
     */
    display_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsFormat
     */
    xml_orders_xpath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsFormat
     */
    unique_field?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsEfsFormat
     */
    headers?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsEfsFormat
     */
    config_count?: number;
    /**
     * 
     * @type {Array<MelcoCoreModelsEfsSchemaListItem>}
     * @memberof MelcoCoreModelsEfsFormat
     */
    schema_list?: Array<MelcoCoreModelsEfsSchemaListItem> | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsFormat
     */
    type?: string | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsEfsMappingFileAttribute>}
     * @memberof MelcoCoreModelsEfsFormat
     */
    filename_rules_list?: Array<MelcoCoreModelsEfsMappingFileAttribute> | null;
}

export function MelcoCoreModelsEfsFormatFromJSON(json: any): MelcoCoreModelsEfsFormat {
    return MelcoCoreModelsEfsFormatFromJSONTyped(json, false);
}

export function MelcoCoreModelsEfsFormatFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsEfsFormat {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'desc': !exists(json, 'desc') ? undefined : json['desc'],
        'display_name': !exists(json, 'display_name') ? undefined : json['display_name'],
        'xml_orders_xpath': !exists(json, 'xml_orders_xpath') ? undefined : json['xml_orders_xpath'],
        'unique_field': !exists(json, 'unique_field') ? undefined : json['unique_field'],
        'headers': !exists(json, 'headers') ? undefined : json['headers'],
        'config_count': !exists(json, 'config_count') ? undefined : json['config_count'],
        'schema_list': !exists(json, 'schema_list') ? undefined : (json['schema_list'] === null ? null : (json['schema_list'] as Array<any>).map(MelcoCoreModelsEfsSchemaListItemFromJSON)),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'filename_rules_list': !exists(json, 'filename_rules_list') ? undefined : (json['filename_rules_list'] === null ? null : (json['filename_rules_list'] as Array<any>).map(MelcoCoreModelsEfsMappingFileAttributeFromJSON)),
    };
}

export function MelcoCoreModelsEfsFormatToJSON(value?: MelcoCoreModelsEfsFormat | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'key': value.key,
        'name': value.name,
        'desc': value.desc,
        'display_name': value.display_name,
        'xml_orders_xpath': value.xml_orders_xpath,
        'unique_field': value.unique_field,
        'headers': value.headers,
        'config_count': value.config_count,
        'schema_list': value.schema_list === undefined ? undefined : (value.schema_list === null ? null : (value.schema_list as Array<any>).map(MelcoCoreModelsEfsSchemaListItemToJSON)),
        'type': value.type,
        'filename_rules_list': value.filename_rules_list === undefined ? undefined : (value.filename_rules_list === null ? null : (value.filename_rules_list as Array<any>).map(MelcoCoreModelsEfsMappingFileAttributeToJSON)),
    };
}


