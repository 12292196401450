import { MelcoCoreModelsDesignElement } from "melco-shared-logic/dist/api/models/MelcoCoreModelsDesignElement";
import { useTranslation } from "react-i18next";
import { ActiveView } from "../../hooks/useActiveView";
import { PersonalizeEditSection } from "../personalize/editSection/PersonalizeEditSection";
import { DesignColorItem } from "./DesignColorItem";

type DesignColorsProps = {
  colorObjects: MelcoCoreModelsDesignElement[];
  activeView: ActiveView | undefined;
  elementIndexes: Map<MelcoCoreModelsDesignElement, number>;
};

export const DesignColors: React.FC<DesignColorsProps> = ({
  colorObjects,
  activeView,
  elementIndexes,
}) => {
  const { t } = useTranslation();

  if (colorObjects.length <= 0 || !activeView) {
    return null;
  }

  return (
    <PersonalizeEditSection title={t("designColors.title")}>
      {colorObjects.map((c) => (
        <DesignColorItem
          key={c.id!}
          activeView={activeView}
          elementIndex={elementIndexes.get(c)}
        />
      ))}
    </PersonalizeEditSection>
  );
};
