import { useUnauthenticatedAPIQuery } from "melco-shared-logic/dist/hooks/api/useAPIQuery";
import { ConfiguratorApi } from "melco-shared-logic/dist/api/apis/ConfiguratorApi";
import { useAPIConfigOptions } from "./useAPIConfig";

export const useFetchDesignCollection = (
  designCollectionId: string | undefined,
  apiKey: string
) => {
  const apiConfigOptions = useAPIConfigOptions(apiKey);

  const { data, isLoading } = useUnauthenticatedAPIQuery(
    ConfiguratorApi,
    ["designCollection", designCollectionId],
    async (api) => {
      if (!designCollectionId) {
        return [];
      }

      const response = await api.configuratorSessionsDesignCollection({
        id: designCollectionId,
      });

      const { design_list } = response;

      return design_list;
    },
    {
      apiConfigOptions,
    }
  );

  return [data ?? [], isLoading] as const;
};
