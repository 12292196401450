/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsPersonalizationDownloads
 */
export interface MelcoCoreModelsPersonalizationDownloads {
    /**
     * 
     * @type {Array<string>}
     * @memberof MelcoCoreModelsPersonalizationDownloads
     */
    personalization_list?: Array<string> | null;
}

export function MelcoCoreModelsPersonalizationDownloadsFromJSON(json: any): MelcoCoreModelsPersonalizationDownloads {
    return MelcoCoreModelsPersonalizationDownloadsFromJSONTyped(json, false);
}

export function MelcoCoreModelsPersonalizationDownloadsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsPersonalizationDownloads {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'personalization_list': !exists(json, 'personalization_list') ? undefined : json['personalization_list'],
    };
}

export function MelcoCoreModelsPersonalizationDownloadsToJSON(value?: MelcoCoreModelsPersonalizationDownloads | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'personalization_list': value.personalization_list,
    };
}


