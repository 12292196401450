/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsUserProductAccess,
    MelcoCoreModelsUserProductAccessFromJSON,
    MelcoCoreModelsUserProductAccessFromJSONTyped,
    MelcoCoreModelsUserProductAccessToJSON,
    MelcoCoreModelsUserRole,
    MelcoCoreModelsUserRoleFromJSON,
    MelcoCoreModelsUserRoleFromJSONTyped,
    MelcoCoreModelsUserRoleToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsUserProductPermissions
 */
export interface MelcoCoreModelsUserProductPermissions {
    /**
     * 
     * @type {MelcoCoreModelsUserRole}
     * @memberof MelcoCoreModelsUserProductPermissions
     */
    role?: MelcoCoreModelsUserRole;
    /**
     * 
     * @type {Array<MelcoCoreModelsUserProductAccess>}
     * @memberof MelcoCoreModelsUserProductPermissions
     */
    user_product_access?: Array<MelcoCoreModelsUserProductAccess> | null;
}

export function MelcoCoreModelsUserProductPermissionsFromJSON(json: any): MelcoCoreModelsUserProductPermissions {
    return MelcoCoreModelsUserProductPermissionsFromJSONTyped(json, false);
}

export function MelcoCoreModelsUserProductPermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsUserProductPermissions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role': !exists(json, 'role') ? undefined : MelcoCoreModelsUserRoleFromJSON(json['role']),
        'user_product_access': !exists(json, 'user_product_access') ? undefined : (json['user_product_access'] === null ? null : (json['user_product_access'] as Array<any>).map(MelcoCoreModelsUserProductAccessFromJSON)),
    };
}

export function MelcoCoreModelsUserProductPermissionsToJSON(value?: MelcoCoreModelsUserProductPermissions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': MelcoCoreModelsUserRoleToJSON(value.role),
        'user_product_access': value.user_product_access === undefined ? undefined : (value.user_product_access === null ? null : (value.user_product_access as Array<any>).map(MelcoCoreModelsUserProductAccessToJSON)),
    };
}


