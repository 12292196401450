/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsUsersForcePasswordChange
 */
export interface MelcoCoreModelsUsersForcePasswordChange {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUsersForcePasswordChange
     */
    new_password?: string | null;
}

export function MelcoCoreModelsUsersForcePasswordChangeFromJSON(json: any): MelcoCoreModelsUsersForcePasswordChange {
    return MelcoCoreModelsUsersForcePasswordChangeFromJSONTyped(json, false);
}

export function MelcoCoreModelsUsersForcePasswordChangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsUsersForcePasswordChange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'new_password': !exists(json, 'new_password') ? undefined : json['new_password'],
    };
}

export function MelcoCoreModelsUsersForcePasswordChangeToJSON(value?: MelcoCoreModelsUsersForcePasswordChange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'new_password': value.new_password,
    };
}


