/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoCoreModelsError,
    MelcoCoreModelsErrorFromJSON,
    MelcoCoreModelsErrorToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsCountry,
    MelcoCoreModelsGenericList1MelcoCoreModelsCountryFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsCountryToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsCountryState,
    MelcoCoreModelsGenericList1MelcoCoreModelsCountryStateFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsCountryStateToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsCulture,
    MelcoCoreModelsGenericList1MelcoCoreModelsCultureFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsCultureToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsCurrency,
    MelcoCoreModelsGenericList1MelcoCoreModelsCurrencyFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsCurrencyToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsGender,
    MelcoCoreModelsGenericList1MelcoCoreModelsGenderFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsGenderToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsKeyValue,
    MelcoCoreModelsGenericList1MelcoCoreModelsKeyValueFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsKeyValueToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsLocaleGroup,
    MelcoCoreModelsGenericList1MelcoCoreModelsLocaleGroupFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsLocaleGroupToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsReferralListItem,
    MelcoCoreModelsGenericList1MelcoCoreModelsReferralListItemFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsReferralListItemToJSON,
} from '../models';

export interface SystemDefaultsApiDefaultsGetDocumentRequest {
    id: string;
}

export interface SystemDefaultsApiDefaultsGetLocaleStringListRequest {
    filter?: string;
}

export interface SystemDefaultsApiDefaultsGetStateListRequest {
    countryList?: string;
}

export interface SystemDefaultsApiDefaultsSearchCulturesRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
}

/**
 * 
 */
export class SystemDefaultsApi extends runtime.BaseAPI {

    /**
     * get list supported countries
     * get list of countries.
     */
    async defaultsGetCountryListRaw(): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsCountry>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/defaults/countries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsCountryFromJSON(jsonValue));
    }

    /**
     * get list supported countries
     * get list of countries.
     */
    async defaultsGetCountryList(): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsCountry> {
        const response = await this.defaultsGetCountryListRaw();
        return await response.value();
    }

    /**
     * get list of supported currencies
     * get list of currencies.
     */
    async defaultsGetCurrencyListRaw(): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsCurrency>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/defaults/currencies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsCurrencyFromJSON(jsonValue));
    }

    /**
     * get list of supported currencies
     * get list of currencies.
     */
    async defaultsGetCurrencyList(): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsCurrency> {
        const response = await this.defaultsGetCurrencyListRaw();
        return await response.value();
    }

    /**
     * get document (supported types: terms)
     * get document
     */
    async defaultsGetDocumentRaw(requestParameters: SystemDefaultsApiDefaultsGetDocumentRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsKeyValue>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling defaultsGetDocument.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/defaults/documents/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsKeyValueFromJSON(jsonValue));
    }

    /**
     * get document (supported types: terms)
     * get document
     */
    async defaultsGetDocument(requestParameters: SystemDefaultsApiDefaultsGetDocumentRequest): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsKeyValue> {
        const response = await this.defaultsGetDocumentRaw(requestParameters);
        return await response.value();
    }

    /**
     * get list of supported gender list
     * get list of genders.
     */
    async defaultsGetGenderListRaw(): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsGender>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/defaults/genders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsGenderFromJSON(jsonValue));
    }

    /**
     * get list of supported gender list
     * get list of genders.
     */
    async defaultsGetGenderList(): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsGender> {
        const response = await this.defaultsGetGenderListRaw();
        return await response.value();
    }

    /**
     * get list of locale groups and strings
     * get list of localization strings.
     */
    async defaultsGetLocaleStringListRaw(requestParameters: SystemDefaultsApiDefaultsGetLocaleStringListRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsLocaleGroup>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/defaults/localeStrings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsLocaleGroupFromJSON(jsonValue));
    }

    /**
     * get list of locale groups and strings
     * get list of localization strings.
     */
    async defaultsGetLocaleStringList(requestParameters: SystemDefaultsApiDefaultsGetLocaleStringListRequest): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsLocaleGroup> {
        const response = await this.defaultsGetLocaleStringListRaw(requestParameters);
        return await response.value();
    }

    /**
     * get referrals list
     * get referrals list
     */
    async defaultsGetReferralListRaw(): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsReferralListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/defaults/referrals`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsReferralListItemFromJSON(jsonValue));
    }

    /**
     * get referrals list
     * get referrals list
     */
    async defaultsGetReferralList(): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsReferralListItem> {
        const response = await this.defaultsGetReferralListRaw();
        return await response.value();
    }

    /**
     * get list supported states
     * get list of states.
     */
    async defaultsGetStateListRaw(requestParameters: SystemDefaultsApiDefaultsGetStateListRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsCountryState>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.countryList !== undefined) {
            queryParameters['CountryList'] = requestParameters.countryList;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/defaults/countries/states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsCountryStateFromJSON(jsonValue));
    }

    /**
     * get list supported states
     * get list of states.
     */
    async defaultsGetStateList(requestParameters: SystemDefaultsApiDefaultsGetStateListRequest): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsCountryState> {
        const response = await this.defaultsGetStateListRaw(requestParameters);
        return await response.value();
    }

    /**
     * list of cultures
     * search culture list
     */
    async defaultsSearchCulturesRaw(requestParameters: SystemDefaultsApiDefaultsSearchCulturesRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsCulture>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/defaults/cultures`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsCultureFromJSON(jsonValue));
    }

    /**
     * list of cultures
     * search culture list
     */
    async defaultsSearchCultures(requestParameters: SystemDefaultsApiDefaultsSearchCulturesRequest): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsCulture> {
        const response = await this.defaultsSearchCulturesRaw(requestParameters);
        return await response.value();
    }

}
