/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsEfsMappingGroup
 */
export interface MelcoCoreModelsEfsMappingGroup {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsMappingGroup
     */
    group_type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsMappingGroup
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsMappingGroup
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsMappingGroup
     */
    format_detail_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsMappingGroup
     */
    reg_expression_id?: string | null;
}

export function MelcoCoreModelsEfsMappingGroupFromJSON(json: any): MelcoCoreModelsEfsMappingGroup {
    return MelcoCoreModelsEfsMappingGroupFromJSONTyped(json, false);
}

export function MelcoCoreModelsEfsMappingGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsEfsMappingGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'group_type': !exists(json, 'group_type') ? undefined : json['group_type'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'format_detail_id': !exists(json, 'format_detail_id') ? undefined : json['format_detail_id'],
        'reg_expression_id': !exists(json, 'reg_expression_id') ? undefined : json['reg_expression_id'],
    };
}

export function MelcoCoreModelsEfsMappingGroupToJSON(value?: MelcoCoreModelsEfsMappingGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'group_type': value.group_type,
        'type': value.type,
        'value': value.value,
        'format_detail_id': value.format_detail_id,
        'reg_expression_id': value.reg_expression_id,
    };
}


