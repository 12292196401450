import { useCallback } from "react";
import { useField } from "formik";
import { MelcoCoreModelsUserImage } from "melco-shared-logic/dist/api/models/MelcoCoreModelsUserImage";
import { selectThumbnailForSize } from "melco-shared-logic/dist/helper/thumbnails/thumbnailHelper";

const width = 48;
const height = 48;

export const useViewThumbnailImage = (colorName: string | undefined) => {
  const [{ value: blankPictures }] = useField<
    MelcoCoreModelsUserImage[] | undefined
  >("product.blank.images");

  return useCallback(
    (viewName: string) => {
      const blankPicture = (blankPictures ?? []).find(
        (bp) => bp.color_name === colorName && bp.view_name === viewName
      );

      const thumbnail = selectThumbnailForSize(
        blankPicture?.previews ?? [],
        Math.max(width, height)
      );

      return thumbnail?.url;
    },
    [blankPictures, colorName]
  );
};
