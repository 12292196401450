import { MelcoCoreModelsSession } from "melco-shared-logic";

const DEFAULT_FEATURES = {
  ui: {
    labeled: false,
  },
};

export const useConfiguratorFeatures = (
  session: MelcoCoreModelsSession | undefined
) => {
  if (!session) {
    return DEFAULT_FEATURES;
  }

  const { configurator_setting: globalConfiguratorSetting, product } = session;

  const { configurator_version: productConfiguratorSetting } = product ?? {};

  let labeled = false;

  // check the product setting first, fall back to global setting if unavailable (= null)
  if (productConfiguratorSetting != null) {
    labeled = productConfiguratorSetting === 1;
  } else {
    labeled = globalConfiguratorSetting?.version === 1;
  }

  return {
    ui: {
      labeled,
    },
  };
};
