/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsCustomProductDesignCollection,
    MelcoCoreModelsCustomProductDesignCollectionFromJSON,
    MelcoCoreModelsCustomProductDesignCollectionFromJSONTyped,
    MelcoCoreModelsCustomProductDesignCollectionToJSON,
    MelcoCoreModelsCustomProductDesignHoop,
    MelcoCoreModelsCustomProductDesignHoopFromJSON,
    MelcoCoreModelsCustomProductDesignHoopFromJSONTyped,
    MelcoCoreModelsCustomProductDesignHoopToJSON,
    MelcoCoreModelsDesign,
    MelcoCoreModelsDesignFromJSON,
    MelcoCoreModelsDesignFromJSONTyped,
    MelcoCoreModelsDesignToJSON,
    MelcoCoreModelsRendererMatrix,
    MelcoCoreModelsRendererMatrixFromJSON,
    MelcoCoreModelsRendererMatrixFromJSONTyped,
    MelcoCoreModelsRendererMatrixToJSON,
    MelcoCoreModelsRendererRect,
    MelcoCoreModelsRendererRectFromJSON,
    MelcoCoreModelsRendererRectFromJSONTyped,
    MelcoCoreModelsRendererRectToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsCustomProductImageDesign
 */
export interface MelcoCoreModelsCustomProductImageDesign {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsCustomProductImageDesign
     */
    personalization_id?: string | null;
    /**
     * 
     * @type {MelcoCoreModelsRendererRect}
     * @memberof MelcoCoreModelsCustomProductImageDesign
     */
    renderer_rect?: MelcoCoreModelsRendererRect;
    /**
     * 
     * @type {MelcoCoreModelsRendererMatrix}
     * @memberof MelcoCoreModelsCustomProductImageDesign
     */
    renderer_matrix?: MelcoCoreModelsRendererMatrix;
    /**
     * 
     * @type {MelcoCoreModelsDesign}
     * @memberof MelcoCoreModelsCustomProductImageDesign
     */
    design?: MelcoCoreModelsDesign;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsCustomProductImageDesign
     */
    production_rotation_degree?: number;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsCustomProductImageDesign
     */
    production_instructions?: string | null;
    /**
     * 
     * @type {MelcoCoreModelsCustomProductDesignCollection}
     * @memberof MelcoCoreModelsCustomProductImageDesign
     */
    design_collection?: MelcoCoreModelsCustomProductDesignCollection;
    /**
     * 
     * @type {MelcoCoreModelsCustomProductDesignHoop}
     * @memberof MelcoCoreModelsCustomProductImageDesign
     */
    hoop?: MelcoCoreModelsCustomProductDesignHoop;
}

export function MelcoCoreModelsCustomProductImageDesignFromJSON(json: any): MelcoCoreModelsCustomProductImageDesign {
    return MelcoCoreModelsCustomProductImageDesignFromJSONTyped(json, false);
}

export function MelcoCoreModelsCustomProductImageDesignFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsCustomProductImageDesign {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'personalization_id': !exists(json, 'personalization_id') ? undefined : json['personalization_id'],
        'renderer_rect': !exists(json, 'renderer_rect') ? undefined : MelcoCoreModelsRendererRectFromJSON(json['renderer_rect']),
        'renderer_matrix': !exists(json, 'renderer_matrix') ? undefined : MelcoCoreModelsRendererMatrixFromJSON(json['renderer_matrix']),
        'design': !exists(json, 'design') ? undefined : MelcoCoreModelsDesignFromJSON(json['design']),
        'production_rotation_degree': !exists(json, 'production_rotation_degree') ? undefined : json['production_rotation_degree'],
        'production_instructions': !exists(json, 'production_instructions') ? undefined : json['production_instructions'],
        'design_collection': !exists(json, 'design_collection') ? undefined : MelcoCoreModelsCustomProductDesignCollectionFromJSON(json['design_collection']),
        'hoop': !exists(json, 'hoop') ? undefined : MelcoCoreModelsCustomProductDesignHoopFromJSON(json['hoop']),
    };
}

export function MelcoCoreModelsCustomProductImageDesignToJSON(value?: MelcoCoreModelsCustomProductImageDesign | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'personalization_id': value.personalization_id,
        'renderer_rect': MelcoCoreModelsRendererRectToJSON(value.renderer_rect),
        'renderer_matrix': MelcoCoreModelsRendererMatrixToJSON(value.renderer_matrix),
        'design': MelcoCoreModelsDesignToJSON(value.design),
        'production_rotation_degree': value.production_rotation_degree,
        'production_instructions': value.production_instructions,
        'design_collection': MelcoCoreModelsCustomProductDesignCollectionToJSON(value.design_collection),
        'hoop': MelcoCoreModelsCustomProductDesignHoopToJSON(value.hoop),
    };
}


