/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsCustomProductImageDesign,
    MelcoCoreModelsCustomProductImageDesignFromJSON,
    MelcoCoreModelsCustomProductImageDesignFromJSONTyped,
    MelcoCoreModelsCustomProductImageDesignToJSON,
    MelcoCoreModelsRendererRect,
    MelcoCoreModelsRendererRectFromJSON,
    MelcoCoreModelsRendererRectFromJSONTyped,
    MelcoCoreModelsRendererRectToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsCustomProductImage
 */
export interface MelcoCoreModelsCustomProductImage {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsCustomProductImage
     */
    id?: string | null;
    /**
     * 
     * @type {MelcoCoreModelsRendererRect}
     * @memberof MelcoCoreModelsCustomProductImage
     */
    renderer_rect?: MelcoCoreModelsRendererRect;
    /**
     * 
     * @type {Array<MelcoCoreModelsCustomProductImageDesign>}
     * @memberof MelcoCoreModelsCustomProductImage
     */
    design_list?: Array<MelcoCoreModelsCustomProductImageDesign> | null;
}

export function MelcoCoreModelsCustomProductImageFromJSON(json: any): MelcoCoreModelsCustomProductImage {
    return MelcoCoreModelsCustomProductImageFromJSONTyped(json, false);
}

export function MelcoCoreModelsCustomProductImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsCustomProductImage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'renderer_rect': !exists(json, 'renderer_rect') ? undefined : MelcoCoreModelsRendererRectFromJSON(json['renderer_rect']),
        'design_list': !exists(json, 'design_list') ? undefined : (json['design_list'] === null ? null : (json['design_list'] as Array<any>).map(MelcoCoreModelsCustomProductImageDesignFromJSON)),
    };
}

export function MelcoCoreModelsCustomProductImageToJSON(value?: MelcoCoreModelsCustomProductImage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'renderer_rect': MelcoCoreModelsRendererRectToJSON(value.renderer_rect),
        'design_list': value.design_list === undefined ? undefined : (value.design_list === null ? null : (value.design_list as Array<any>).map(MelcoCoreModelsCustomProductImageDesignToJSON)),
    };
}


