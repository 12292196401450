/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsEfsMappingOutputElement,
    MelcoCoreModelsEfsMappingOutputElementFromJSON,
    MelcoCoreModelsEfsMappingOutputElementFromJSONTyped,
    MelcoCoreModelsEfsMappingOutputElementToJSON,
    MelcoCoreModelsEfsMappingOutputPrintSettings,
    MelcoCoreModelsEfsMappingOutputPrintSettingsFromJSON,
    MelcoCoreModelsEfsMappingOutputPrintSettingsFromJSONTyped,
    MelcoCoreModelsEfsMappingOutputPrintSettingsToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsEfsMappingOutput
 */
export interface MelcoCoreModelsEfsMappingOutput {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsMappingOutput
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsMappingOutput
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsMappingOutput
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsMappingOutput
     */
    design_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsMappingOutput
     */
    config_type?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsEfsMappingOutput
     */
    patch?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsMappingOutput
     */
    patch_template?: string | null;
    /**
     * 
     * @type {MelcoCoreModelsEfsMappingOutputPrintSettings}
     * @memberof MelcoCoreModelsEfsMappingOutput
     */
    print_settings?: MelcoCoreModelsEfsMappingOutputPrintSettings;
    /**
     * 
     * @type {Array<MelcoCoreModelsEfsMappingOutputElement>}
     * @memberof MelcoCoreModelsEfsMappingOutput
     */
    elements?: Array<MelcoCoreModelsEfsMappingOutputElement> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsEfsMappingOutput
     */
    output_production_file_on_emptydata?: boolean;
}

export function MelcoCoreModelsEfsMappingOutputFromJSON(json: any): MelcoCoreModelsEfsMappingOutput {
    return MelcoCoreModelsEfsMappingOutputFromJSONTyped(json, false);
}

export function MelcoCoreModelsEfsMappingOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsEfsMappingOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'design_id': !exists(json, 'design_id') ? undefined : json['design_id'],
        'config_type': !exists(json, 'config_type') ? undefined : json['config_type'],
        'patch': !exists(json, 'patch') ? undefined : json['patch'],
        'patch_template': !exists(json, 'patch_template') ? undefined : json['patch_template'],
        'print_settings': !exists(json, 'print_settings') ? undefined : MelcoCoreModelsEfsMappingOutputPrintSettingsFromJSON(json['print_settings']),
        'elements': !exists(json, 'elements') ? undefined : (json['elements'] === null ? null : (json['elements'] as Array<any>).map(MelcoCoreModelsEfsMappingOutputElementFromJSON)),
        'output_production_file_on_emptydata': !exists(json, 'output_production_file_on_emptydata') ? undefined : json['output_production_file_on_emptydata'],
    };
}

export function MelcoCoreModelsEfsMappingOutputToJSON(value?: MelcoCoreModelsEfsMappingOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'type': value.type,
        'design_id': value.design_id,
        'config_type': value.config_type,
        'patch': value.patch,
        'patch_template': value.patch_template,
        'print_settings': MelcoCoreModelsEfsMappingOutputPrintSettingsToJSON(value.print_settings),
        'elements': value.elements === undefined ? undefined : (value.elements === null ? null : (value.elements as Array<any>).map(MelcoCoreModelsEfsMappingOutputElementToJSON)),
        'output_production_file_on_emptydata': value.output_production_file_on_emptydata,
    };
}


