/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsUsersUserDefaults
 */
export interface MelcoCoreModelsUsersUserDefaults {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUsersUserDefaults
     */
    default_language?: string | null;
}

export function MelcoCoreModelsUsersUserDefaultsFromJSON(json: any): MelcoCoreModelsUsersUserDefaults {
    return MelcoCoreModelsUsersUserDefaultsFromJSONTyped(json, false);
}

export function MelcoCoreModelsUsersUserDefaultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsUsersUserDefaults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'default_language': !exists(json, 'default_language') ? undefined : json['default_language'],
    };
}

export function MelcoCoreModelsUsersUserDefaultsToJSON(value?: MelcoCoreModelsUsersUserDefaults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'default_language': value.default_language,
    };
}


