/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoAnalyticsModelsAvailabilityAppListItem
 */
export interface MelcoAnalyticsModelsAvailabilityAppListItem {
    /**
     * 
     * @type {string}
     * @memberof MelcoAnalyticsModelsAvailabilityAppListItem
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoAnalyticsModelsAvailabilityAppListItem
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoAnalyticsModelsAvailabilityAppListItem
     */
    app_name?: string | null;
}

export function MelcoAnalyticsModelsAvailabilityAppListItemFromJSON(json: any): MelcoAnalyticsModelsAvailabilityAppListItem {
    return MelcoAnalyticsModelsAvailabilityAppListItemFromJSONTyped(json, false);
}

export function MelcoAnalyticsModelsAvailabilityAppListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoAnalyticsModelsAvailabilityAppListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'app_name': !exists(json, 'app_name') ? undefined : json['app_name'],
    };
}

export function MelcoAnalyticsModelsAvailabilityAppListItemToJSON(value?: MelcoAnalyticsModelsAvailabilityAppListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'app_name': value.app_name,
    };
}


