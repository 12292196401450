/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoCoreModelsError,
    MelcoCoreModelsErrorFromJSON,
    MelcoCoreModelsErrorToJSON,
    MelcoCoreModelsSettingsRestSetting,
    MelcoCoreModelsSettingsRestSettingFromJSON,
    MelcoCoreModelsSettingsRestSettingToJSON,
} from '../models';

/**
 * 
 */
export class SettingsApi extends runtime.BaseAPI {

    /**
     * get settings
     * get rest api\'s settings
     */
    async settingsGetRaw(): Promise<runtime.ApiResponse<MelcoCoreModelsSettingsRestSetting>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsSettingsRestSettingFromJSON(jsonValue));
    }

    /**
     * get settings
     * get rest api\'s settings
     */
    async settingsGet(): Promise<MelcoCoreModelsSettingsRestSetting> {
        const response = await this.settingsGetRaw();
        return await response.value();
    }

}
