/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsAppInfo,
    MelcoCoreModelsAppInfoFromJSON,
    MelcoCoreModelsAppInfoFromJSONTyped,
    MelcoCoreModelsAppInfoToJSON,
    MelcoCoreModelsIdentityDevice,
    MelcoCoreModelsIdentityDeviceFromJSON,
    MelcoCoreModelsIdentityDeviceFromJSONTyped,
    MelcoCoreModelsIdentityDeviceToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsSecurityMelcoAuth
 */
export interface MelcoCoreModelsSecurityMelcoAuth {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsSecurityMelcoAuth
     */
    user_name: string;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsSecurityMelcoAuth
     */
    password: string;
    /**
     * 
     * @type {MelcoCoreModelsIdentityDevice}
     * @memberof MelcoCoreModelsSecurityMelcoAuth
     */
    device_info: MelcoCoreModelsIdentityDevice;
    /**
     * 
     * @type {MelcoCoreModelsAppInfo}
     * @memberof MelcoCoreModelsSecurityMelcoAuth
     */
    app_info?: MelcoCoreModelsAppInfo;
}

export function MelcoCoreModelsSecurityMelcoAuthFromJSON(json: any): MelcoCoreModelsSecurityMelcoAuth {
    return MelcoCoreModelsSecurityMelcoAuthFromJSONTyped(json, false);
}

export function MelcoCoreModelsSecurityMelcoAuthFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsSecurityMelcoAuth {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user_name': json['user_name'],
        'password': json['password'],
        'device_info': MelcoCoreModelsIdentityDeviceFromJSON(json['device_info']),
        'app_info': !exists(json, 'app_info') ? undefined : MelcoCoreModelsAppInfoFromJSON(json['app_info']),
    };
}

export function MelcoCoreModelsSecurityMelcoAuthToJSON(value?: MelcoCoreModelsSecurityMelcoAuth | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_name': value.user_name,
        'password': value.password,
        'device_info': MelcoCoreModelsIdentityDeviceToJSON(value.device_info),
        'app_info': MelcoCoreModelsAppInfoToJSON(value.app_info),
    };
}


