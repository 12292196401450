/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsEfsMappingOutputAttribute,
    MelcoCoreModelsEfsMappingOutputAttributeFromJSON,
    MelcoCoreModelsEfsMappingOutputAttributeFromJSONTyped,
    MelcoCoreModelsEfsMappingOutputAttributeToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsEfsMappingOutputElement
 */
export interface MelcoCoreModelsEfsMappingOutputElement {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsMappingOutputElement
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsMappingOutputElement
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsMappingOutputElement
     */
    lettering_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsMappingOutputElement
     */
    mapping_id?: string | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsEfsMappingOutputAttribute>}
     * @memberof MelcoCoreModelsEfsMappingOutputElement
     */
    attributes?: Array<MelcoCoreModelsEfsMappingOutputAttribute> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsEfsMappingOutputElement
     */
    is_delete_empty?: boolean;
}

export function MelcoCoreModelsEfsMappingOutputElementFromJSON(json: any): MelcoCoreModelsEfsMappingOutputElement {
    return MelcoCoreModelsEfsMappingOutputElementFromJSONTyped(json, false);
}

export function MelcoCoreModelsEfsMappingOutputElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsEfsMappingOutputElement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'lettering_id': !exists(json, 'lettering_id') ? undefined : json['lettering_id'],
        'mapping_id': !exists(json, 'mapping_id') ? undefined : json['mapping_id'],
        'attributes': !exists(json, 'attributes') ? undefined : (json['attributes'] === null ? null : (json['attributes'] as Array<any>).map(MelcoCoreModelsEfsMappingOutputAttributeFromJSON)),
        'is_delete_empty': !exists(json, 'is_delete_empty') ? undefined : json['is_delete_empty'],
    };
}

export function MelcoCoreModelsEfsMappingOutputElementToJSON(value?: MelcoCoreModelsEfsMappingOutputElement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': value.type,
        'lettering_id': value.lettering_id,
        'mapping_id': value.mapping_id,
        'attributes': value.attributes === undefined ? undefined : (value.attributes === null ? null : (value.attributes as Array<any>).map(MelcoCoreModelsEfsMappingOutputAttributeToJSON)),
        'is_delete_empty': value.is_delete_empty,
    };
}


