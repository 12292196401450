/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsUserProductTrial
 */
export interface MelcoCoreModelsUserProductTrial {
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsUserProductTrial
     */
    expires_on?: Date | null;
}

export function MelcoCoreModelsUserProductTrialFromJSON(json: any): MelcoCoreModelsUserProductTrial {
    return MelcoCoreModelsUserProductTrialFromJSONTyped(json, false);
}

export function MelcoCoreModelsUserProductTrialFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsUserProductTrial {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expires_on': !exists(json, 'expires_on') ? undefined : (json['expires_on'] === null ? null : new Date(json['expires_on'])),
    };
}

export function MelcoCoreModelsUserProductTrialToJSON(value?: MelcoCoreModelsUserProductTrial | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'expires_on': value.expires_on === undefined ? undefined : (value.expires_on === null ? null : value.expires_on.toISOString()),
    };
}


