/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsLocaleCultureString,
    MelcoCoreModelsLocaleCultureStringFromJSON,
    MelcoCoreModelsLocaleCultureStringFromJSONTyped,
    MelcoCoreModelsLocaleCultureStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsLocaleString
 */
export interface MelcoCoreModelsLocaleString {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsLocaleString
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsLocaleString
     */
    key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsLocaleString
     */
    source?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsLocaleString
     */
    module?: string | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsLocaleCultureString>}
     * @memberof MelcoCoreModelsLocaleString
     */
    strings?: Array<MelcoCoreModelsLocaleCultureString> | null;
}

export function MelcoCoreModelsLocaleStringFromJSON(json: any): MelcoCoreModelsLocaleString {
    return MelcoCoreModelsLocaleStringFromJSONTyped(json, false);
}

export function MelcoCoreModelsLocaleStringFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsLocaleString {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'module': !exists(json, 'module') ? undefined : json['module'],
        'strings': !exists(json, 'strings') ? undefined : (json['strings'] === null ? null : (json['strings'] as Array<any>).map(MelcoCoreModelsLocaleCultureStringFromJSON)),
    };
}

export function MelcoCoreModelsLocaleStringToJSON(value?: MelcoCoreModelsLocaleString | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'key': value.key,
        'source': value.source,
        'module': value.module,
        'strings': value.strings === undefined ? undefined : (value.strings === null ? null : (value.strings as Array<any>).map(MelcoCoreModelsLocaleCultureStringToJSON)),
    };
}


