/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsCustomProduct,
    MelcoCoreModelsCustomProductFromJSON,
    MelcoCoreModelsCustomProductFromJSONTyped,
    MelcoCoreModelsCustomProductToJSON,
    MelcoCoreModelsFusionConfiguratorSetting,
    MelcoCoreModelsFusionConfiguratorSettingFromJSON,
    MelcoCoreModelsFusionConfiguratorSettingFromJSONTyped,
    MelcoCoreModelsFusionConfiguratorSettingToJSON,
    MelcoCoreModelsProductionStatusHistory,
    MelcoCoreModelsProductionStatusHistoryFromJSON,
    MelcoCoreModelsProductionStatusHistoryFromJSONTyped,
    MelcoCoreModelsProductionStatusHistoryToJSON,
    MelcoCoreModelsSessionPreview,
    MelcoCoreModelsSessionPreviewFromJSON,
    MelcoCoreModelsSessionPreviewFromJSONTyped,
    MelcoCoreModelsSessionPreviewToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsSession
 */
export interface MelcoCoreModelsSession {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsSession
     */
    session_id?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsSession
     */
    session_status?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsSession
     */
    production_status?: number;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsSession
     */
    start_timestamp?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsSession
     */
    end_timestamp?: Date | null;
    /**
     * 
     * @type {MelcoCoreModelsCustomProduct}
     * @memberof MelcoCoreModelsSession
     */
    product?: MelcoCoreModelsCustomProduct;
    /**
     * 
     * @type {Array<MelcoCoreModelsSessionPreview>}
     * @memberof MelcoCoreModelsSession
     */
    previews?: Array<MelcoCoreModelsSessionPreview> | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsSession
     */
    selected_color_image_id?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsSession
     */
    is_edits_applied?: boolean;
    /**
     * 
     * @type {MelcoCoreModelsFusionConfiguratorSetting}
     * @memberof MelcoCoreModelsSession
     */
    configurator_setting?: MelcoCoreModelsFusionConfiguratorSetting;
    /**
     * 
     * @type {Array<MelcoCoreModelsProductionStatusHistory>}
     * @memberof MelcoCoreModelsSession
     */
    production_status_history?: Array<MelcoCoreModelsProductionStatusHistory> | null;
}

export function MelcoCoreModelsSessionFromJSON(json: any): MelcoCoreModelsSession {
    return MelcoCoreModelsSessionFromJSONTyped(json, false);
}

export function MelcoCoreModelsSessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsSession {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'session_id': !exists(json, 'session_id') ? undefined : json['session_id'],
        'session_status': !exists(json, 'session_status') ? undefined : json['session_status'],
        'production_status': !exists(json, 'production_status') ? undefined : json['production_status'],
        'start_timestamp': !exists(json, 'start_timestamp') ? undefined : (new Date(json['start_timestamp'])),
        'end_timestamp': !exists(json, 'end_timestamp') ? undefined : (json['end_timestamp'] === null ? null : new Date(json['end_timestamp'])),
        'product': !exists(json, 'product') ? undefined : MelcoCoreModelsCustomProductFromJSON(json['product']),
        'previews': !exists(json, 'previews') ? undefined : (json['previews'] === null ? null : (json['previews'] as Array<any>).map(MelcoCoreModelsSessionPreviewFromJSON)),
        'selected_color_image_id': !exists(json, 'selected_color_image_id') ? undefined : json['selected_color_image_id'],
        'is_edits_applied': !exists(json, 'is_edits_applied') ? undefined : json['is_edits_applied'],
        'configurator_setting': !exists(json, 'configurator_setting') ? undefined : MelcoCoreModelsFusionConfiguratorSettingFromJSON(json['configurator_setting']),
        'production_status_history': !exists(json, 'production_status_history') ? undefined : (json['production_status_history'] === null ? null : (json['production_status_history'] as Array<any>).map(MelcoCoreModelsProductionStatusHistoryFromJSON)),
    };
}

export function MelcoCoreModelsSessionToJSON(value?: MelcoCoreModelsSession | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'session_id': value.session_id,
        'session_status': value.session_status,
        'production_status': value.production_status,
        'start_timestamp': value.start_timestamp === undefined ? undefined : (value.start_timestamp.toISOString()),
        'end_timestamp': value.end_timestamp === undefined ? undefined : (value.end_timestamp === null ? null : value.end_timestamp.toISOString()),
        'product': MelcoCoreModelsCustomProductToJSON(value.product),
        'previews': value.previews === undefined ? undefined : (value.previews === null ? null : (value.previews as Array<any>).map(MelcoCoreModelsSessionPreviewToJSON)),
        'selected_color_image_id': value.selected_color_image_id,
        'is_edits_applied': value.is_edits_applied,
        'configurator_setting': MelcoCoreModelsFusionConfiguratorSettingToJSON(value.configurator_setting),
        'production_status_history': value.production_status_history === undefined ? undefined : (value.production_status_history === null ? null : (value.production_status_history as Array<any>).map(MelcoCoreModelsProductionStatusHistoryToJSON)),
    };
}


