/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsEfsQueueJobDesignInfo,
    MelcoCoreModelsEfsQueueJobDesignInfoFromJSON,
    MelcoCoreModelsEfsQueueJobDesignInfoFromJSONTyped,
    MelcoCoreModelsEfsQueueJobDesignInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsEfsQueueJobRun
 */
export interface MelcoCoreModelsEfsQueueJobRun {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MelcoCoreModelsEfsQueueJobRun
     */
    output_files?: { [key: string]: string; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MelcoCoreModelsEfsQueueJobRun
     */
    files_list?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsEfsQueueJobRun
     */
    notification_id?: number;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsQueueJobRun
     */
    folder_structure?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsQueueJobRun
     */
    input_asset?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsQueueJobRun
     */
    input_asset_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsQueueJobRun
     */
    script?: string | null;
    /**
     * 
     * @type {MelcoCoreModelsEfsQueueJobDesignInfo}
     * @memberof MelcoCoreModelsEfsQueueJobRun
     */
    design_info?: MelcoCoreModelsEfsQueueJobDesignInfo;
}

export function MelcoCoreModelsEfsQueueJobRunFromJSON(json: any): MelcoCoreModelsEfsQueueJobRun {
    return MelcoCoreModelsEfsQueueJobRunFromJSONTyped(json, false);
}

export function MelcoCoreModelsEfsQueueJobRunFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsEfsQueueJobRun {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'output_files': !exists(json, 'output_files') ? undefined : json['output_files'],
        'files_list': !exists(json, 'files_list') ? undefined : json['files_list'],
        'notification_id': !exists(json, 'notification_id') ? undefined : json['notification_id'],
        'folder_structure': !exists(json, 'folder_structure') ? undefined : json['folder_structure'],
        'input_asset': !exists(json, 'input_asset') ? undefined : json['input_asset'],
        'input_asset_name': !exists(json, 'input_asset_name') ? undefined : json['input_asset_name'],
        'script': !exists(json, 'script') ? undefined : json['script'],
        'design_info': !exists(json, 'design_info') ? undefined : MelcoCoreModelsEfsQueueJobDesignInfoFromJSON(json['design_info']),
    };
}

export function MelcoCoreModelsEfsQueueJobRunToJSON(value?: MelcoCoreModelsEfsQueueJobRun | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'output_files': value.output_files,
        'files_list': value.files_list,
        'notification_id': value.notification_id,
        'folder_structure': value.folder_structure,
        'input_asset': value.input_asset,
        'input_asset_name': value.input_asset_name,
        'script': value.script,
        'design_info': MelcoCoreModelsEfsQueueJobDesignInfoToJSON(value.design_info),
    };
}


