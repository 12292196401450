/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsProductDefaults
 */
export interface MelcoCoreModelsProductDefaults {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsProductDefaults
     */
    log_level?: string | null;
}

export function MelcoCoreModelsProductDefaultsFromJSON(json: any): MelcoCoreModelsProductDefaults {
    return MelcoCoreModelsProductDefaultsFromJSONTyped(json, false);
}

export function MelcoCoreModelsProductDefaultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsProductDefaults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'log_level': !exists(json, 'log_level') ? undefined : json['log_level'],
    };
}

export function MelcoCoreModelsProductDefaultsToJSON(value?: MelcoCoreModelsProductDefaults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'log_level': value.log_level,
    };
}


