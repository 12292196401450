/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreConstantsFulfillmentClientRunStatus,
    MelcoCoreConstantsFulfillmentClientRunStatusFromJSON,
    MelcoCoreConstantsFulfillmentClientRunStatusFromJSONTyped,
    MelcoCoreConstantsFulfillmentClientRunStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsEfsRunStatus
 */
export interface MelcoCoreModelsEfsRunStatus {
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsEfsRunStatus
     */
    start_timestamp?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsEfsRunStatus
     */
    end_timestamp?: Date;
    /**
     * 
     * @type {MelcoCoreConstantsFulfillmentClientRunStatus}
     * @memberof MelcoCoreModelsEfsRunStatus
     */
    status?: MelcoCoreConstantsFulfillmentClientRunStatus;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsEfsRunStatus
     */
    files?: number;
}

export function MelcoCoreModelsEfsRunStatusFromJSON(json: any): MelcoCoreModelsEfsRunStatus {
    return MelcoCoreModelsEfsRunStatusFromJSONTyped(json, false);
}

export function MelcoCoreModelsEfsRunStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsEfsRunStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'start_timestamp': !exists(json, 'start_timestamp') ? undefined : (new Date(json['start_timestamp'])),
        'end_timestamp': !exists(json, 'end_timestamp') ? undefined : (new Date(json['end_timestamp'])),
        'status': !exists(json, 'status') ? undefined : MelcoCoreConstantsFulfillmentClientRunStatusFromJSON(json['status']),
        'files': !exists(json, 'files') ? undefined : json['files'],
    };
}

export function MelcoCoreModelsEfsRunStatusToJSON(value?: MelcoCoreModelsEfsRunStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start_timestamp': value.start_timestamp === undefined ? undefined : (value.start_timestamp.toISOString()),
        'end_timestamp': value.end_timestamp === undefined ? undefined : (value.end_timestamp.toISOString()),
        'status': MelcoCoreConstantsFulfillmentClientRunStatusToJSON(value.status),
        'files': value.files,
    };
}


