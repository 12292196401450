/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsEfsMappingOutputPrintSettings
 */
export interface MelcoCoreModelsEfsMappingOutputPrintSettings {
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsEfsMappingOutputPrintSettings
     */
    export_layers?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsEfsMappingOutputPrintSettings
     */
    hide_unavailable_layers?: boolean;
}

export function MelcoCoreModelsEfsMappingOutputPrintSettingsFromJSON(json: any): MelcoCoreModelsEfsMappingOutputPrintSettings {
    return MelcoCoreModelsEfsMappingOutputPrintSettingsFromJSONTyped(json, false);
}

export function MelcoCoreModelsEfsMappingOutputPrintSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsEfsMappingOutputPrintSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'export_layers': !exists(json, 'export_layers') ? undefined : json['export_layers'],
        'hide_unavailable_layers': !exists(json, 'hide_unavailable_layers') ? undefined : json['hide_unavailable_layers'],
    };
}

export function MelcoCoreModelsEfsMappingOutputPrintSettingsToJSON(value?: MelcoCoreModelsEfsMappingOutputPrintSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'export_layers': value.export_layers,
        'hide_unavailable_layers': value.hide_unavailable_layers,
    };
}


