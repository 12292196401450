/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsEfsJob
 */
export interface MelcoCoreModelsEfsJob {
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsEfsJob
     */
    format_id?: number;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsJob
     */
    computer_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsJob
     */
    file_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsJob
     */
    session_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsJob
     */
    xml_data?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsJob
     */
    output_format?: string | null;
}

export function MelcoCoreModelsEfsJobFromJSON(json: any): MelcoCoreModelsEfsJob {
    return MelcoCoreModelsEfsJobFromJSONTyped(json, false);
}

export function MelcoCoreModelsEfsJobFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsEfsJob {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'format_id': !exists(json, 'format_id') ? undefined : json['format_id'],
        'computer_name': !exists(json, 'computer_name') ? undefined : json['computer_name'],
        'file_name': !exists(json, 'file_name') ? undefined : json['file_name'],
        'session_id': !exists(json, 'session_id') ? undefined : json['session_id'],
        'xml_data': !exists(json, 'xml_data') ? undefined : json['xml_data'],
        'output_format': !exists(json, 'output_format') ? undefined : json['output_format'],
    };
}

export function MelcoCoreModelsEfsJobToJSON(value?: MelcoCoreModelsEfsJob | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'format_id': value.format_id,
        'computer_name': value.computer_name,
        'file_name': value.file_name,
        'session_id': value.session_id,
        'xml_data': value.xml_data,
        'output_format': value.output_format,
    };
}


