/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsRendererRect
 */
export interface MelcoCoreModelsRendererRect {
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsRendererRect
     */
    llx?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsRendererRect
     */
    lly?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsRendererRect
     */
    urx?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsRendererRect
     */
    ury?: number;
}

export function MelcoCoreModelsRendererRectFromJSON(json: any): MelcoCoreModelsRendererRect {
    return MelcoCoreModelsRendererRectFromJSONTyped(json, false);
}

export function MelcoCoreModelsRendererRectFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsRendererRect {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'llx': !exists(json, 'llx') ? undefined : json['llx'],
        'lly': !exists(json, 'lly') ? undefined : json['lly'],
        'urx': !exists(json, 'urx') ? undefined : json['urx'],
        'ury': !exists(json, 'ury') ? undefined : json['ury'],
    };
}

export function MelcoCoreModelsRendererRectToJSON(value?: MelcoCoreModelsRendererRect | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'llx': value.llx,
        'lly': value.lly,
        'urx': value.urx,
        'ury': value.ury,
    };
}


