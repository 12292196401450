import { useCallback } from "react";
import { Formik } from "formik";
import { MelcoCoreModelsSession } from "melco-shared-logic/dist/api/models/MelcoCoreModelsSession";
import { array, boolean, object, string } from "yup";
import { OnCancelCallback, OnCompleteCallback } from "../../index";
import { DisplayConfigurator } from "./DisplayConfigurator";
import { Confirm } from "../confirm/Confirm";
import { useConfirm } from "../../hooks/useConfirm";
import { SetDefaultDesigns } from "./SetDefaultDesigns";

type ConfiguratorSessionProps = {
  session: MelcoCoreModelsSession;
  apiKey: string;
  defaultColor?: string;
  defaultViewName?: string;
  onCancelCallback: OnCancelCallback;
  onCompleteCallback: OnCompleteCallback;
  onRestart: () => void;
  isProductColorChangeDisabled?: boolean;
  isDesignChangeDisabled?: boolean;
  defaultDesignIds?: string;
  isViewModeEnabled: boolean;
  setViewModeEnabled: (isViewModeEnabled: boolean) => void;
};

const configuratorSessionSchema = object({
  product: object({
    view_list: array(
      object({
        color_list: array(
          object({
            design_list: array(
              object({
                design: object({
                  element_list: array(
                    object({
                      type: string(),
                      is_required: boolean(),
                      lettering: object().when(["type", "is_required"], {
                        is: (type: string, is_required: boolean) =>
                          type === "Lettering" && is_required,
                        then: (schema) =>
                          schema.concat(
                            object({
                              text: string().required(
                                "help.lettering.text.required"
                              ),
                            })
                          ),
                      }),
                    })
                  ),
                }),
              })
            ),
          })
        ),
      })
    ),
  }),
});

export const ConfiguratorSession: React.FC<ConfiguratorSessionProps> = ({
  session,
  apiKey,
  defaultColor,
  defaultViewName,
  onCancelCallback,
  onCompleteCallback,
  onRestart,
  isProductColorChangeDisabled,
  isDesignChangeDisabled,
  defaultDesignIds,
  isViewModeEnabled,
  setViewModeEnabled,
}) => {
  const onSubmit = useCallback(() => {}, []);

  const { currentConfirm, actions: confirmActions } = useConfirm();

  return (
    <>
      <Formik
        initialValues={session}
        validationSchema={configuratorSessionSchema}
        validateOnBlur
        validateOnChange
        validateOnMount
        onSubmit={onSubmit}
      >
        <>
          <SetDefaultDesigns
            key={session.session_id ?? "stable-key"}
            apiKey={apiKey}
            defaultDesignIds={defaultDesignIds}
          />

          <DisplayConfigurator
            apiKey={apiKey}
            defaultColor={defaultColor}
            defaultViewName={defaultViewName}
            onCancelCallback={onCancelCallback}
            onCompleteCallback={onCompleteCallback}
            onRestart={onRestart}
            confirmActions={confirmActions}
            isProductColorChangeDisabled={isProductColorChangeDisabled}
            isDesignChangeDisabled={isDesignChangeDisabled}
            isViewModeEnabled={isViewModeEnabled}
            setViewModeEnabled={setViewModeEnabled}
          />
        </>
      </Formik>

      {currentConfirm && <Confirm config={currentConfirm} />}
    </>
  );
};
