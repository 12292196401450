/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsUsersUserAddress,
    MelcoCoreModelsUsersUserAddressFromJSON,
    MelcoCoreModelsUsersUserAddressFromJSONTyped,
    MelcoCoreModelsUsersUserAddressToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsUsersSubUser
 */
export interface MelcoCoreModelsUsersSubUser {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUsersSubUser
     */
    user_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUsersSubUser
     */
    first_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUsersSubUser
     */
    last_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUsersSubUser
     */
    company_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUsersSubUser
     */
    website?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUsersSubUser
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUsersSubUser
     */
    gender_code?: string | null;
    /**
     * 
     * @type {MelcoCoreModelsUsersUserAddress}
     * @memberof MelcoCoreModelsUsersSubUser
     */
    address?: MelcoCoreModelsUsersUserAddress;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsUsersSubUser
     */
    is_email?: boolean;
}

export function MelcoCoreModelsUsersSubUserFromJSON(json: any): MelcoCoreModelsUsersSubUser {
    return MelcoCoreModelsUsersSubUserFromJSONTyped(json, false);
}

export function MelcoCoreModelsUsersSubUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsUsersSubUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user_name': !exists(json, 'user_name') ? undefined : json['user_name'],
        'first_name': !exists(json, 'first_name') ? undefined : json['first_name'],
        'last_name': !exists(json, 'last_name') ? undefined : json['last_name'],
        'company_name': !exists(json, 'company_name') ? undefined : json['company_name'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'gender_code': !exists(json, 'gender_code') ? undefined : json['gender_code'],
        'address': !exists(json, 'address') ? undefined : MelcoCoreModelsUsersUserAddressFromJSON(json['address']),
        'is_email': !exists(json, 'is_email') ? undefined : json['is_email'],
    };
}

export function MelcoCoreModelsUsersSubUserToJSON(value?: MelcoCoreModelsUsersSubUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_name': value.user_name,
        'first_name': value.first_name,
        'last_name': value.last_name,
        'company_name': value.company_name,
        'website': value.website,
        'phone': value.phone,
        'gender_code': value.gender_code,
        'address': MelcoCoreModelsUsersUserAddressToJSON(value.address),
        'is_email': value.is_email,
    };
}


