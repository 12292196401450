/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsDefaultImage,
    MelcoCoreModelsDefaultImageFromJSON,
    MelcoCoreModelsDefaultImageFromJSONTyped,
    MelcoCoreModelsDefaultImageToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsUserBlankListItem
 */
export interface MelcoCoreModelsUserBlankListItem {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUserBlankListItem
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUserBlankListItem
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsUserBlankListItem
     */
    canvas_width?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsUserBlankListItem
     */
    canvas_height?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsUserBlankListItem
     */
    views_count?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsUserBlankListItem
     */
    colors_count?: number;
    /**
     * 
     * @type {MelcoCoreModelsDefaultImage}
     * @memberof MelcoCoreModelsUserBlankListItem
     */
    default_image?: MelcoCoreModelsDefaultImage;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsUserBlankListItem
     */
    publish_state?: number;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsUserBlankListItem
     */
    created_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsUserBlankListItem
     */
    publish_date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsUserBlankListItem
     */
    updated_date?: Date | null;
}

export function MelcoCoreModelsUserBlankListItemFromJSON(json: any): MelcoCoreModelsUserBlankListItem {
    return MelcoCoreModelsUserBlankListItemFromJSONTyped(json, false);
}

export function MelcoCoreModelsUserBlankListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsUserBlankListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'canvas_width': !exists(json, 'canvas_width') ? undefined : json['canvas_width'],
        'canvas_height': !exists(json, 'canvas_height') ? undefined : json['canvas_height'],
        'views_count': !exists(json, 'views_count') ? undefined : json['views_count'],
        'colors_count': !exists(json, 'colors_count') ? undefined : json['colors_count'],
        'default_image': !exists(json, 'default_image') ? undefined : MelcoCoreModelsDefaultImageFromJSON(json['default_image']),
        'publish_state': !exists(json, 'publish_state') ? undefined : json['publish_state'],
        'created_date': !exists(json, 'created_date') ? undefined : (new Date(json['created_date'])),
        'publish_date': !exists(json, 'publish_date') ? undefined : (json['publish_date'] === null ? null : new Date(json['publish_date'])),
        'updated_date': !exists(json, 'updated_date') ? undefined : (json['updated_date'] === null ? null : new Date(json['updated_date'])),
    };
}

export function MelcoCoreModelsUserBlankListItemToJSON(value?: MelcoCoreModelsUserBlankListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'canvas_width': value.canvas_width,
        'canvas_height': value.canvas_height,
        'views_count': value.views_count,
        'colors_count': value.colors_count,
        'default_image': MelcoCoreModelsDefaultImageToJSON(value.default_image),
        'publish_state': value.publish_state,
        'created_date': value.created_date === undefined ? undefined : (value.created_date.toISOString()),
        'publish_date': value.publish_date === undefined ? undefined : (value.publish_date === null ? null : value.publish_date.toISOString()),
        'updated_date': value.updated_date === undefined ? undefined : (value.updated_date === null ? null : value.updated_date.toISOString()),
    };
}


