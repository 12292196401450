/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsEfsQueueJobRun,
    MelcoCoreModelsEfsQueueJobRunFromJSON,
    MelcoCoreModelsEfsQueueJobRunFromJSONTyped,
    MelcoCoreModelsEfsQueueJobRunToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsEfsQueueJob
 */
export interface MelcoCoreModelsEfsQueueJob {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsQueueJob
     */
    last_error?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsQueueJob
     */
    queue_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsQueueJob
     */
    client_Id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsQueueJob
     */
    signature?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsQueueJob
     */
    client_job_id?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsEfsQueueJob
     */
    completed?: boolean;
    /**
     * 
     * @type {Array<MelcoCoreModelsEfsQueueJobRun>}
     * @memberof MelcoCoreModelsEfsQueueJob
     */
    runs?: Array<MelcoCoreModelsEfsQueueJobRun> | null;
}

export function MelcoCoreModelsEfsQueueJobFromJSON(json: any): MelcoCoreModelsEfsQueueJob {
    return MelcoCoreModelsEfsQueueJobFromJSONTyped(json, false);
}

export function MelcoCoreModelsEfsQueueJobFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsEfsQueueJob {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'last_error': !exists(json, 'last_error') ? undefined : json['last_error'],
        'queue_id': !exists(json, 'queue_id') ? undefined : json['queue_id'],
        'client_Id': !exists(json, 'client_Id') ? undefined : json['client_Id'],
        'signature': !exists(json, 'signature') ? undefined : json['signature'],
        'client_job_id': !exists(json, 'client_job_id') ? undefined : json['client_job_id'],
        'completed': !exists(json, 'completed') ? undefined : json['completed'],
        'runs': !exists(json, 'runs') ? undefined : (json['runs'] === null ? null : (json['runs'] as Array<any>).map(MelcoCoreModelsEfsQueueJobRunFromJSON)),
    };
}

export function MelcoCoreModelsEfsQueueJobToJSON(value?: MelcoCoreModelsEfsQueueJob | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'last_error': value.last_error,
        'queue_id': value.queue_id,
        'client_Id': value.client_Id,
        'signature': value.signature,
        'client_job_id': value.client_job_id,
        'completed': value.completed,
        'runs': value.runs === undefined ? undefined : (value.runs === null ? null : (value.runs as Array<any>).map(MelcoCoreModelsEfsQueueJobRunToJSON)),
    };
}


