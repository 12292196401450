/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoAnalyticsModelsActiveUserListItem
 */
export interface MelcoAnalyticsModelsActiveUserListItem {
    /**
     * 
     * @type {string}
     * @memberof MelcoAnalyticsModelsActiveUserListItem
     */
    product_code?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoAnalyticsModelsActiveUserListItem
     */
    created_date?: Date;
    /**
     * 
     * @type {number}
     * @memberof MelcoAnalyticsModelsActiveUserListItem
     */
    active_users?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoAnalyticsModelsActiveUserListItem
     */
    unique_active_users?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoAnalyticsModelsActiveUserListItem
     */
    company_id?: number;
}

export function MelcoAnalyticsModelsActiveUserListItemFromJSON(json: any): MelcoAnalyticsModelsActiveUserListItem {
    return MelcoAnalyticsModelsActiveUserListItemFromJSONTyped(json, false);
}

export function MelcoAnalyticsModelsActiveUserListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoAnalyticsModelsActiveUserListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'product_code': !exists(json, 'product_code') ? undefined : json['product_code'],
        'created_date': !exists(json, 'created_date') ? undefined : (new Date(json['created_date'])),
        'active_users': !exists(json, 'active_users') ? undefined : json['active_users'],
        'unique_active_users': !exists(json, 'unique_active_users') ? undefined : json['unique_active_users'],
        'company_id': !exists(json, 'company_id') ? undefined : json['company_id'],
    };
}

export function MelcoAnalyticsModelsActiveUserListItemToJSON(value?: MelcoAnalyticsModelsActiveUserListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product_code': value.product_code,
        'created_date': value.created_date === undefined ? undefined : (value.created_date.toISOString()),
        'active_users': value.active_users,
        'unique_active_users': value.unique_active_users,
        'company_id': value.company_id,
    };
}


