/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsPermissionsListItem,
    MelcoCoreModelsPermissionsListItemFromJSON,
    MelcoCoreModelsPermissionsListItemFromJSONTyped,
    MelcoCoreModelsPermissionsListItemToJSON,
    MelcoCoreModelsUserProductTrial,
    MelcoCoreModelsUserProductTrialFromJSON,
    MelcoCoreModelsUserProductTrialFromJSONTyped,
    MelcoCoreModelsUserProductTrialToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsAppStartUp
 */
export interface MelcoCoreModelsAppStartUp {
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsAppStartUp
     */
    show_service_terms?: boolean;
    /**
     * 
     * @type {Array<MelcoCoreModelsPermissionsListItem>}
     * @memberof MelcoCoreModelsAppStartUp
     */
    permission_list?: Array<MelcoCoreModelsPermissionsListItem> | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsAppStartUp
     */
    default_language?: string | null;
    /**
     * 
     * @type {MelcoCoreModelsUserProductTrial}
     * @memberof MelcoCoreModelsAppStartUp
     */
    trial?: MelcoCoreModelsUserProductTrial;
}

export function MelcoCoreModelsAppStartUpFromJSON(json: any): MelcoCoreModelsAppStartUp {
    return MelcoCoreModelsAppStartUpFromJSONTyped(json, false);
}

export function MelcoCoreModelsAppStartUpFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsAppStartUp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'show_service_terms': !exists(json, 'show_service_terms') ? undefined : json['show_service_terms'],
        'permission_list': !exists(json, 'permission_list') ? undefined : (json['permission_list'] === null ? null : (json['permission_list'] as Array<any>).map(MelcoCoreModelsPermissionsListItemFromJSON)),
        'default_language': !exists(json, 'default_language') ? undefined : json['default_language'],
        'trial': !exists(json, 'trial') ? undefined : MelcoCoreModelsUserProductTrialFromJSON(json['trial']),
    };
}

export function MelcoCoreModelsAppStartUpToJSON(value?: MelcoCoreModelsAppStartUp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'show_service_terms': value.show_service_terms,
        'permission_list': value.permission_list === undefined ? undefined : (value.permission_list === null ? null : (value.permission_list as Array<any>).map(MelcoCoreModelsPermissionsListItemToJSON)),
        'default_language': value.default_language,
        'trial': MelcoCoreModelsUserProductTrialToJSON(value.trial),
    };
}


