/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsCustomWarning,
    MelcoCoreModelsCustomWarningFromJSON,
    MelcoCoreModelsCustomWarningFromJSONTyped,
    MelcoCoreModelsCustomWarningToJSON,
    MelcoCoreModelsEfsMappingCondition,
    MelcoCoreModelsEfsMappingConditionFromJSON,
    MelcoCoreModelsEfsMappingConditionFromJSONTyped,
    MelcoCoreModelsEfsMappingConditionToJSON,
    MelcoCoreModelsEfsMappingGroup,
    MelcoCoreModelsEfsMappingGroupFromJSON,
    MelcoCoreModelsEfsMappingGroupFromJSONTyped,
    MelcoCoreModelsEfsMappingGroupToJSON,
    MelcoCoreModelsEfsMappingOutput,
    MelcoCoreModelsEfsMappingOutputFromJSON,
    MelcoCoreModelsEfsMappingOutputFromJSONTyped,
    MelcoCoreModelsEfsMappingOutputToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsEfsMapping
 */
export interface MelcoCoreModelsEfsMapping {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsMapping
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsMapping
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsEfsMapping
     */
    global?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsEfsMapping
     */
    configured?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsMapping
     */
    config_type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsMapping
     */
    format_id?: string | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsEfsMappingCondition>}
     * @memberof MelcoCoreModelsEfsMapping
     */
    conditions?: Array<MelcoCoreModelsEfsMappingCondition> | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsEfsMappingOutput>}
     * @memberof MelcoCoreModelsEfsMapping
     */
    outputs?: Array<MelcoCoreModelsEfsMappingOutput> | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsMapping
     */
    service_type?: string | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsEfsMappingGroup>}
     * @memberof MelcoCoreModelsEfsMapping
     */
    folder_groups?: Array<MelcoCoreModelsEfsMappingGroup> | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsCustomWarning>}
     * @memberof MelcoCoreModelsEfsMapping
     */
    warning_list?: Array<MelcoCoreModelsCustomWarning> | null;
}

export function MelcoCoreModelsEfsMappingFromJSON(json: any): MelcoCoreModelsEfsMapping {
    return MelcoCoreModelsEfsMappingFromJSONTyped(json, false);
}

export function MelcoCoreModelsEfsMappingFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsEfsMapping {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'global': !exists(json, 'global') ? undefined : json['global'],
        'configured': !exists(json, 'configured') ? undefined : json['configured'],
        'config_type': !exists(json, 'config_type') ? undefined : json['config_type'],
        'format_id': !exists(json, 'format_id') ? undefined : json['format_id'],
        'conditions': !exists(json, 'conditions') ? undefined : (json['conditions'] === null ? null : (json['conditions'] as Array<any>).map(MelcoCoreModelsEfsMappingConditionFromJSON)),
        'outputs': !exists(json, 'outputs') ? undefined : (json['outputs'] === null ? null : (json['outputs'] as Array<any>).map(MelcoCoreModelsEfsMappingOutputFromJSON)),
        'service_type': !exists(json, 'service_type') ? undefined : json['service_type'],
        'folder_groups': !exists(json, 'folder_groups') ? undefined : (json['folder_groups'] === null ? null : (json['folder_groups'] as Array<any>).map(MelcoCoreModelsEfsMappingGroupFromJSON)),
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(MelcoCoreModelsCustomWarningFromJSON)),
    };
}

export function MelcoCoreModelsEfsMappingToJSON(value?: MelcoCoreModelsEfsMapping | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'global': value.global,
        'configured': value.configured,
        'config_type': value.config_type,
        'format_id': value.format_id,
        'conditions': value.conditions === undefined ? undefined : (value.conditions === null ? null : (value.conditions as Array<any>).map(MelcoCoreModelsEfsMappingConditionToJSON)),
        'outputs': value.outputs === undefined ? undefined : (value.outputs === null ? null : (value.outputs as Array<any>).map(MelcoCoreModelsEfsMappingOutputToJSON)),
        'service_type': value.service_type,
        'folder_groups': value.folder_groups === undefined ? undefined : (value.folder_groups === null ? null : (value.folder_groups as Array<any>).map(MelcoCoreModelsEfsMappingGroupToJSON)),
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(MelcoCoreModelsCustomWarningToJSON)),
    };
}


