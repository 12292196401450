/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsUsersReGeneratePassword
 */
export interface MelcoCoreModelsUsersReGeneratePassword {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUsersReGeneratePassword
     */
    user_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUsersReGeneratePassword
     */
    new_password?: string | null;
}

export function MelcoCoreModelsUsersReGeneratePasswordFromJSON(json: any): MelcoCoreModelsUsersReGeneratePassword {
    return MelcoCoreModelsUsersReGeneratePasswordFromJSONTyped(json, false);
}

export function MelcoCoreModelsUsersReGeneratePasswordFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsUsersReGeneratePassword {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user_name': !exists(json, 'user_name') ? undefined : json['user_name'],
        'new_password': !exists(json, 'new_password') ? undefined : json['new_password'],
    };
}

export function MelcoCoreModelsUsersReGeneratePasswordToJSON(value?: MelcoCoreModelsUsersReGeneratePassword | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_name': value.user_name,
        'new_password': value.new_password,
    };
}


