import {
  ConfiguratorApi,
  ConfiguratorApiConfiguratorSessionsStartRequest,
} from "melco-shared-logic/dist/api/apis/ConfiguratorApi";
import { convertToAPIErrors } from "melco-shared-logic/dist/hooks/error/apiErrorConverter";
import { useMutation } from "react-query";
import { useAPIConfig } from "./useAPIConfig";

type UseConfiguratorVariables = {
  token: string;
  sessionId?: string;
  getExistingSession: boolean;
};

export const useConfigurator = (apiKey: string) => {
  const apiConfig = useAPIConfig(apiKey);

  return useMutation(
    async (variables: UseConfiguratorVariables) => {
      const { token, sessionId, getExistingSession } = variables;

      const api = new ConfiguratorApi(apiConfig);

      try {
        if (getExistingSession && sessionId) {
          return api.configuratorSessionsGetSession({
            sessionId,
          });
        } else {
          const requestParams: ConfiguratorApiConfiguratorSessionsStartRequest =
            {
              productToken: token,
            };

          if (sessionId && sessionId !== "") {
            requestParams.sessionId = sessionId;
          }

          return api.configuratorSessionsStart(requestParams);
        }
      } catch (e) {
        // we need to parse the response and get hold of the error here because in the retry mechanismn we cannot use async code to access the JSON payload
        throw await convertToAPIErrors(e);
      }
    },
    {
      retry: (failureCount, error) => {
        const isRetriable =
          error &&
          error[0] &&
          String(error[0].key).toLowerCase() === "not_exist" &&
          String(error[0].source).toLowerCase() === "cache";

        return isRetriable && failureCount < 5;
      },
      retryDelay: 2000,
    }
  );
};
