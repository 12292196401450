import { MelcoCoreModelsDesignElement } from "melco-shared-logic/dist/api/models/MelcoCoreModelsDesignElement";
import { PersonalizeEditSection } from "../personalize/editSection/PersonalizeEditSection";
import { LetteringText } from "./LetteringText";
import { LetteringFont } from "./LetteringFont";
import { LetteringColor } from "./LetteringColor";
import { ActiveView } from "../../hooks/useActiveView";

type LetteringProps = {
  letteringElement: MelcoCoreModelsDesignElement;
  activeView: ActiveView | undefined;
  elementIndex: number | undefined;
};

export const Lettering: React.FC<LetteringProps> = ({
  activeView,
  elementIndex,
}) => {
  if (!activeView || elementIndex == null) {
    return null;
  }

  return (
    <PersonalizeEditSection>
      <LetteringText activeView={activeView} elementIndex={elementIndex} />

      <LetteringFont activeView={activeView} elementIndex={elementIndex} />

      <LetteringColor activeView={activeView} elementIndex={elementIndex} />
    </PersonalizeEditSection>
  );
};
