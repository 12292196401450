/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsCustomWarning,
    MelcoCoreModelsCustomWarningFromJSON,
    MelcoCoreModelsCustomWarningFromJSONTyped,
    MelcoCoreModelsCustomWarningToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsDRMLicenseCreateResponse
 */
export interface MelcoCoreModelsDRMLicenseCreateResponse {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMLicenseCreateResponse
     */
    melco_customer_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMLicenseCreateResponse
     */
    language?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMLicenseCreateResponse
     */
    email_address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMLicenseCreateResponse
     */
    serial_number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMLicenseCreateResponse
     */
    product_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMLicenseCreateResponse
     */
    product_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMLicenseCreateResponse
     */
    product_level_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMLicenseCreateResponse
     */
    subscription_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMLicenseCreateResponse
     */
    referral_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMLicenseCreateResponse
     */
    batch?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsDRMLicenseCreateResponse
     */
    cancelled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMLicenseCreateResponse
     */
    version_number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMLicenseCreateResponse
     */
    version_id?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsDRMLicenseCreateResponse
     */
    type?: number;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsDRMLicenseCreateResponse
     */
    created_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsDRMLicenseCreateResponse
     */
    updated_date?: Date | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsCustomWarning>}
     * @memberof MelcoCoreModelsDRMLicenseCreateResponse
     */
    warning_list?: Array<MelcoCoreModelsCustomWarning> | null;
}

export function MelcoCoreModelsDRMLicenseCreateResponseFromJSON(json: any): MelcoCoreModelsDRMLicenseCreateResponse {
    return MelcoCoreModelsDRMLicenseCreateResponseFromJSONTyped(json, false);
}

export function MelcoCoreModelsDRMLicenseCreateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsDRMLicenseCreateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'melco_customer_id': !exists(json, 'melco_customer_id') ? undefined : json['melco_customer_id'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'email_address': !exists(json, 'email_address') ? undefined : json['email_address'],
        'serial_number': !exists(json, 'serial_number') ? undefined : json['serial_number'],
        'product_code': !exists(json, 'product_code') ? undefined : json['product_code'],
        'product_name': !exists(json, 'product_name') ? undefined : json['product_name'],
        'product_level_name': !exists(json, 'product_level_name') ? undefined : json['product_level_name'],
        'subscription_code': !exists(json, 'subscription_code') ? undefined : json['subscription_code'],
        'referral_code': !exists(json, 'referral_code') ? undefined : json['referral_code'],
        'batch': !exists(json, 'batch') ? undefined : json['batch'],
        'cancelled': !exists(json, 'cancelled') ? undefined : json['cancelled'],
        'version_number': !exists(json, 'version_number') ? undefined : json['version_number'],
        'version_id': !exists(json, 'version_id') ? undefined : json['version_id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'created_date': !exists(json, 'created_date') ? undefined : (new Date(json['created_date'])),
        'updated_date': !exists(json, 'updated_date') ? undefined : (json['updated_date'] === null ? null : new Date(json['updated_date'])),
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(MelcoCoreModelsCustomWarningFromJSON)),
    };
}

export function MelcoCoreModelsDRMLicenseCreateResponseToJSON(value?: MelcoCoreModelsDRMLicenseCreateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'melco_customer_id': value.melco_customer_id,
        'language': value.language,
        'email_address': value.email_address,
        'serial_number': value.serial_number,
        'product_code': value.product_code,
        'product_name': value.product_name,
        'product_level_name': value.product_level_name,
        'subscription_code': value.subscription_code,
        'referral_code': value.referral_code,
        'batch': value.batch,
        'cancelled': value.cancelled,
        'version_number': value.version_number,
        'version_id': value.version_id,
        'type': value.type,
        'created_date': value.created_date === undefined ? undefined : (value.created_date.toISOString()),
        'updated_date': value.updated_date === undefined ? undefined : (value.updated_date === null ? null : value.updated_date.toISOString()),
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(MelcoCoreModelsCustomWarningToJSON)),
    };
}


