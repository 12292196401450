/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsProductTrial
 */
export interface MelcoCoreModelsProductTrial {
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsProductTrial
     */
    is_started?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsProductTrial
     */
    started_on?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsProductTrial
     */
    expires_on?: Date | null;
}

export function MelcoCoreModelsProductTrialFromJSON(json: any): MelcoCoreModelsProductTrial {
    return MelcoCoreModelsProductTrialFromJSONTyped(json, false);
}

export function MelcoCoreModelsProductTrialFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsProductTrial {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'is_started': !exists(json, 'is_started') ? undefined : json['is_started'],
        'started_on': !exists(json, 'started_on') ? undefined : (json['started_on'] === null ? null : new Date(json['started_on'])),
        'expires_on': !exists(json, 'expires_on') ? undefined : (json['expires_on'] === null ? null : new Date(json['expires_on'])),
    };
}

export function MelcoCoreModelsProductTrialToJSON(value?: MelcoCoreModelsProductTrial | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_started': value.is_started,
        'started_on': value.started_on === undefined ? undefined : (value.started_on === null ? null : value.started_on.toISOString()),
        'expires_on': value.expires_on === undefined ? undefined : (value.expires_on === null ? null : value.expires_on.toISOString()),
    };
}


