/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsUpdateSupplierProduct
 */
export interface MelcoCoreModelsUpdateSupplierProduct {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUpdateSupplierProduct
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUpdateSupplierProduct
     */
    code?: string | null;
}

export function MelcoCoreModelsUpdateSupplierProductFromJSON(json: any): MelcoCoreModelsUpdateSupplierProduct {
    return MelcoCoreModelsUpdateSupplierProductFromJSONTyped(json, false);
}

export function MelcoCoreModelsUpdateSupplierProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsUpdateSupplierProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'code': !exists(json, 'code') ? undefined : json['code'],
    };
}

export function MelcoCoreModelsUpdateSupplierProductToJSON(value?: MelcoCoreModelsUpdateSupplierProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'code': value.code,
    };
}


