/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsCreateApiKey
 */
export interface MelcoCoreModelsCreateApiKey {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsCreateApiKey
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsCreateApiKey
     */
    sub_group?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsCreateApiKey
     */
    product_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsCreateApiKey
     */
    scope_id?: string | null;
}

export function MelcoCoreModelsCreateApiKeyFromJSON(json: any): MelcoCoreModelsCreateApiKey {
    return MelcoCoreModelsCreateApiKeyFromJSONTyped(json, false);
}

export function MelcoCoreModelsCreateApiKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsCreateApiKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'sub_group': !exists(json, 'sub_group') ? undefined : json['sub_group'],
        'product_code': !exists(json, 'product_code') ? undefined : json['product_code'],
        'scope_id': !exists(json, 'scope_id') ? undefined : json['scope_id'],
    };
}

export function MelcoCoreModelsCreateApiKeyToJSON(value?: MelcoCoreModelsCreateApiKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'sub_group': value.sub_group,
        'product_code': value.product_code,
        'scope_id': value.scope_id,
    };
}


