/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsEfsGlobalFormatListItem
 */
export interface MelcoCoreModelsEfsGlobalFormatListItem {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsGlobalFormatListItem
     */
    key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsGlobalFormatListItem
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsGlobalFormatListItem
     */
    desc?: string | null;
}

export function MelcoCoreModelsEfsGlobalFormatListItemFromJSON(json: any): MelcoCoreModelsEfsGlobalFormatListItem {
    return MelcoCoreModelsEfsGlobalFormatListItemFromJSONTyped(json, false);
}

export function MelcoCoreModelsEfsGlobalFormatListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsEfsGlobalFormatListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'desc': !exists(json, 'desc') ? undefined : json['desc'],
    };
}

export function MelcoCoreModelsEfsGlobalFormatListItemToJSON(value?: MelcoCoreModelsEfsGlobalFormatListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'name': value.name,
        'desc': value.desc,
    };
}


