/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsUserImage,
    MelcoCoreModelsUserImageFromJSON,
    MelcoCoreModelsUserImageFromJSONTyped,
    MelcoCoreModelsUserImageToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsCreateBlank
 */
export interface MelcoCoreModelsCreateBlank {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsCreateBlank
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsCreateBlank
     */
    canvas_width?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsCreateBlank
     */
    canvas_height?: number;
    /**
     * 
     * @type {Array<MelcoCoreModelsUserImage>}
     * @memberof MelcoCoreModelsCreateBlank
     */
    images?: Array<MelcoCoreModelsUserImage> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MelcoCoreModelsCreateBlank
     */
    colors?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MelcoCoreModelsCreateBlank
     */
    views?: Array<string> | null;
}

export function MelcoCoreModelsCreateBlankFromJSON(json: any): MelcoCoreModelsCreateBlank {
    return MelcoCoreModelsCreateBlankFromJSONTyped(json, false);
}

export function MelcoCoreModelsCreateBlankFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsCreateBlank {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'canvas_width': !exists(json, 'canvas_width') ? undefined : json['canvas_width'],
        'canvas_height': !exists(json, 'canvas_height') ? undefined : json['canvas_height'],
        'images': !exists(json, 'images') ? undefined : (json['images'] === null ? null : (json['images'] as Array<any>).map(MelcoCoreModelsUserImageFromJSON)),
        'colors': !exists(json, 'colors') ? undefined : json['colors'],
        'views': !exists(json, 'views') ? undefined : json['views'],
    };
}

export function MelcoCoreModelsCreateBlankToJSON(value?: MelcoCoreModelsCreateBlank | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'canvas_width': value.canvas_width,
        'canvas_height': value.canvas_height,
        'images': value.images === undefined ? undefined : (value.images === null ? null : (value.images as Array<any>).map(MelcoCoreModelsUserImageToJSON)),
        'colors': value.colors,
        'views': value.views,
    };
}


