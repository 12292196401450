import { useField } from "formik";
import { MelcoCoreModelsCanvasPreview } from "melco-shared-logic/dist/api/models/MelcoCoreModelsCanvasPreview";
import { MelcoCoreModelsUserImage } from "melco-shared-logic/dist/api/models/MelcoCoreModelsUserImage";
import { ActiveView } from "./useActiveView";
import { ActiveColor } from "./useProductColor";

export const useBlankPictureUrl = (
  activeView: ActiveView | undefined,
  activeColor: ActiveColor | undefined
) => {
  const [{ value: blankPictures }] = useField<
    MelcoCoreModelsUserImage[] | undefined
  >("product.blank.images");

  const [{ value: canvasPreviews }] = useField<
    MelcoCoreModelsCanvasPreview[] | undefined
  >("product.blank.canvas_previews");

  // get blank picture to find out the correct blank picture id for current view and color
  const blankPicture = (blankPictures ?? []).find(
    (bp) =>
      activeView &&
      activeColor &&
      bp.view_name === activeView.name &&
      bp.color_name === activeColor.name
  );

  const canvasPreview = (canvasPreviews ?? []).find(
    (cp) => blankPicture && cp.id === blankPicture.id
  );

  return canvasPreview?.url;
};
