/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsCanvasPreview,
    MelcoCoreModelsCanvasPreviewFromJSON,
    MelcoCoreModelsCanvasPreviewFromJSONTyped,
    MelcoCoreModelsCanvasPreviewToJSON,
    MelcoCoreModelsCustomWarning,
    MelcoCoreModelsCustomWarningFromJSON,
    MelcoCoreModelsCustomWarningFromJSONTyped,
    MelcoCoreModelsCustomWarningToJSON,
    MelcoCoreModelsDefaultImage,
    MelcoCoreModelsDefaultImageFromJSON,
    MelcoCoreModelsDefaultImageFromJSONTyped,
    MelcoCoreModelsDefaultImageToJSON,
    MelcoCoreModelsUserImage,
    MelcoCoreModelsUserImageFromJSON,
    MelcoCoreModelsUserImageFromJSONTyped,
    MelcoCoreModelsUserImageToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsUserBlank
 */
export interface MelcoCoreModelsUserBlank {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUserBlank
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUserBlank
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsUserBlank
     */
    canvas_width?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsUserBlank
     */
    canvas_height?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsUserBlank
     */
    views_count?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsUserBlank
     */
    colors_count?: number;
    /**
     * 
     * @type {MelcoCoreModelsDefaultImage}
     * @memberof MelcoCoreModelsUserBlank
     */
    default_image?: MelcoCoreModelsDefaultImage;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsUserBlank
     */
    publish_state?: number;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsUserBlank
     */
    created_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsUserBlank
     */
    publish_date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsUserBlank
     */
    updated_date?: Date | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsUserImage>}
     * @memberof MelcoCoreModelsUserBlank
     */
    images?: Array<MelcoCoreModelsUserImage> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MelcoCoreModelsUserBlank
     */
    colors?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MelcoCoreModelsUserBlank
     */
    views?: Array<string> | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsCanvasPreview>}
     * @memberof MelcoCoreModelsUserBlank
     */
    canvas_previews?: Array<MelcoCoreModelsCanvasPreview> | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsCustomWarning>}
     * @memberof MelcoCoreModelsUserBlank
     */
    warning_list?: Array<MelcoCoreModelsCustomWarning> | null;
}

export function MelcoCoreModelsUserBlankFromJSON(json: any): MelcoCoreModelsUserBlank {
    return MelcoCoreModelsUserBlankFromJSONTyped(json, false);
}

export function MelcoCoreModelsUserBlankFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsUserBlank {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'canvas_width': !exists(json, 'canvas_width') ? undefined : json['canvas_width'],
        'canvas_height': !exists(json, 'canvas_height') ? undefined : json['canvas_height'],
        'views_count': !exists(json, 'views_count') ? undefined : json['views_count'],
        'colors_count': !exists(json, 'colors_count') ? undefined : json['colors_count'],
        'default_image': !exists(json, 'default_image') ? undefined : MelcoCoreModelsDefaultImageFromJSON(json['default_image']),
        'publish_state': !exists(json, 'publish_state') ? undefined : json['publish_state'],
        'created_date': !exists(json, 'created_date') ? undefined : (new Date(json['created_date'])),
        'publish_date': !exists(json, 'publish_date') ? undefined : (json['publish_date'] === null ? null : new Date(json['publish_date'])),
        'updated_date': !exists(json, 'updated_date') ? undefined : (json['updated_date'] === null ? null : new Date(json['updated_date'])),
        'images': !exists(json, 'images') ? undefined : (json['images'] === null ? null : (json['images'] as Array<any>).map(MelcoCoreModelsUserImageFromJSON)),
        'colors': !exists(json, 'colors') ? undefined : json['colors'],
        'views': !exists(json, 'views') ? undefined : json['views'],
        'canvas_previews': !exists(json, 'canvas_previews') ? undefined : (json['canvas_previews'] === null ? null : (json['canvas_previews'] as Array<any>).map(MelcoCoreModelsCanvasPreviewFromJSON)),
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(MelcoCoreModelsCustomWarningFromJSON)),
    };
}

export function MelcoCoreModelsUserBlankToJSON(value?: MelcoCoreModelsUserBlank | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'canvas_width': value.canvas_width,
        'canvas_height': value.canvas_height,
        'views_count': value.views_count,
        'colors_count': value.colors_count,
        'default_image': MelcoCoreModelsDefaultImageToJSON(value.default_image),
        'publish_state': value.publish_state,
        'created_date': value.created_date === undefined ? undefined : (value.created_date.toISOString()),
        'publish_date': value.publish_date === undefined ? undefined : (value.publish_date === null ? null : value.publish_date.toISOString()),
        'updated_date': value.updated_date === undefined ? undefined : (value.updated_date === null ? null : value.updated_date.toISOString()),
        'images': value.images === undefined ? undefined : (value.images === null ? null : (value.images as Array<any>).map(MelcoCoreModelsUserImageToJSON)),
        'colors': value.colors,
        'views': value.views,
        'canvas_previews': value.canvas_previews === undefined ? undefined : (value.canvas_previews === null ? null : (value.canvas_previews as Array<any>).map(MelcoCoreModelsCanvasPreviewToJSON)),
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(MelcoCoreModelsCustomWarningToJSON)),
    };
}


