/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsThreadColor,
    MelcoCoreModelsThreadColorFromJSON,
    MelcoCoreModelsThreadColorFromJSONTyped,
    MelcoCoreModelsThreadColorToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsColorListItem
 */
export interface MelcoCoreModelsColorListItem {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsColorListItem
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsColorListItem
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsColorListItem
     */
    code?: string | null;
    /**
     * 
     * @type {MelcoCoreModelsThreadColor}
     * @memberof MelcoCoreModelsColorListItem
     */
    color?: MelcoCoreModelsThreadColor;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsColorListItem
     */
    type?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsColorListItem
     */
    weight?: number;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsColorListItem
     */
    supplier_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsColorListItem
     */
    product_name?: string | null;
}

export function MelcoCoreModelsColorListItemFromJSON(json: any): MelcoCoreModelsColorListItem {
    return MelcoCoreModelsColorListItemFromJSONTyped(json, false);
}

export function MelcoCoreModelsColorListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsColorListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'color': !exists(json, 'color') ? undefined : MelcoCoreModelsThreadColorFromJSON(json['color']),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'supplier_name': !exists(json, 'supplier_name') ? undefined : json['supplier_name'],
        'product_name': !exists(json, 'product_name') ? undefined : json['product_name'],
    };
}

export function MelcoCoreModelsColorListItemToJSON(value?: MelcoCoreModelsColorListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'code': value.code,
        'color': MelcoCoreModelsThreadColorToJSON(value.color),
        'type': value.type,
        'weight': value.weight,
        'supplier_name': value.supplier_name,
        'product_name': value.product_name,
    };
}


