/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoCoreModelsError,
    MelcoCoreModelsErrorFromJSON,
    MelcoCoreModelsErrorToJSON,
    MelcoCoreModelsHoop,
    MelcoCoreModelsHoopFromJSON,
    MelcoCoreModelsHoopToJSON,
    MelcoCoreModelsHoopListItem,
    MelcoCoreModelsHoopListItemFromJSON,
    MelcoCoreModelsHoopListItemToJSON,
} from '../models';

export interface SyncServicesApiSyncHoopsGetDefRequest {
    id: string;
    publishState?: string;
}

export interface SyncServicesApiSyncHoopsSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    publishState?: string;
    searchText?: string;
    name?: string;
    productList?: string;
    machineList?: string;
    categoryList?: string;
    version?: string;
}

/**
 * 
 */
export class SyncServicesApi extends runtime.BaseAPI {

    /**
     * get hoop
     * get hoop by Id
     */
    async syncHoopsGetDefRaw(requestParameters: SyncServicesApiSyncHoopsGetDefRequest): Promise<runtime.ApiResponse<MelcoCoreModelsHoop>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling syncHoopsGetDef.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sync/hoops/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsHoopFromJSON(jsonValue));
    }

    /**
     * get hoop
     * get hoop by Id
     */
    async syncHoopsGetDef(requestParameters: SyncServicesApiSyncHoopsGetDefRequest): Promise<MelcoCoreModelsHoop> {
        const response = await this.syncHoopsGetDefRaw(requestParameters);
        return await response.value();
    }

    /**
     * get hoop
     * get hoop list
     */
    async syncHoopsSearchRaw(requestParameters: SyncServicesApiSyncHoopsSearchRequest): Promise<runtime.ApiResponse<MelcoCoreModelsHoopListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.productList !== undefined) {
            queryParameters['product_list'] = requestParameters.productList;
        }

        if (requestParameters.machineList !== undefined) {
            queryParameters['machine_list'] = requestParameters.machineList;
        }

        if (requestParameters.categoryList !== undefined) {
            queryParameters['category_list'] = requestParameters.categoryList;
        }

        if (requestParameters.version !== undefined) {
            queryParameters['version'] = requestParameters.version;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sync/hoops`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsHoopListItemFromJSON(jsonValue));
    }

    /**
     * get hoop
     * get hoop list
     */
    async syncHoopsSearch(requestParameters: SyncServicesApiSyncHoopsSearchRequest): Promise<MelcoCoreModelsHoopListItem> {
        const response = await this.syncHoopsSearchRaw(requestParameters);
        return await response.value();
    }

}
