import { MelcoCoreModelsCustomProduct } from "melco-shared-logic/dist/api/models/MelcoCoreModelsCustomProduct";
import { getDesignCollectionIdForView } from "../helper/designCollectionHelper";

export type DesignCollectionOfView = {
  viewName: string;
  designCollectionId?: string;
};

export const useDesignCollectionsOfProduct = (
  product: MelcoCoreModelsCustomProduct | undefined
) => {
  if (!product) {
    return [];
  }

  const { view_list: viewList } = product;

  return (viewList ?? []).map((view) => {
    return {
      viewName: view.name ?? "",
      designCollectionId: getDesignCollectionIdForView(view),
    } as DesignCollectionOfView;
  });
};
