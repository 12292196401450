/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsAppInfo,
    MelcoCoreModelsAppInfoFromJSON,
    MelcoCoreModelsAppInfoFromJSONTyped,
    MelcoCoreModelsAppInfoToJSON,
    MelcoCoreModelsIdentityDevice,
    MelcoCoreModelsIdentityDeviceFromJSON,
    MelcoCoreModelsIdentityDeviceFromJSONTyped,
    MelcoCoreModelsIdentityDeviceToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsSecurityVirtualAuth
 */
export interface MelcoCoreModelsSecurityVirtualAuth {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsSecurityVirtualAuth
     */
    user_name: string;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsSecurityVirtualAuth
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsSecurityVirtualAuth
     */
    token?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsSecurityVirtualAuth
     */
    product_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsSecurityVirtualAuth
     */
    version_number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsSecurityVirtualAuth
     */
    validation_string?: string | null;
    /**
     * 
     * @type {MelcoCoreModelsIdentityDevice}
     * @memberof MelcoCoreModelsSecurityVirtualAuth
     */
    device_info: MelcoCoreModelsIdentityDevice;
    /**
     * 
     * @type {MelcoCoreModelsAppInfo}
     * @memberof MelcoCoreModelsSecurityVirtualAuth
     */
    app_info?: MelcoCoreModelsAppInfo;
}

export function MelcoCoreModelsSecurityVirtualAuthFromJSON(json: any): MelcoCoreModelsSecurityVirtualAuth {
    return MelcoCoreModelsSecurityVirtualAuthFromJSONTyped(json, false);
}

export function MelcoCoreModelsSecurityVirtualAuthFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsSecurityVirtualAuth {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user_name': json['user_name'],
        'password': json['password'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'product_code': !exists(json, 'product_code') ? undefined : json['product_code'],
        'version_number': !exists(json, 'version_number') ? undefined : json['version_number'],
        'validation_string': !exists(json, 'validation_string') ? undefined : json['validation_string'],
        'device_info': MelcoCoreModelsIdentityDeviceFromJSON(json['device_info']),
        'app_info': !exists(json, 'app_info') ? undefined : MelcoCoreModelsAppInfoFromJSON(json['app_info']),
    };
}

export function MelcoCoreModelsSecurityVirtualAuthToJSON(value?: MelcoCoreModelsSecurityVirtualAuth | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_name': value.user_name,
        'password': value.password,
        'token': value.token,
        'product_code': value.product_code,
        'version_number': value.version_number,
        'validation_string': value.validation_string,
        'device_info': MelcoCoreModelsIdentityDeviceToJSON(value.device_info),
        'app_info': MelcoCoreModelsAppInfoToJSON(value.app_info),
    };
}


