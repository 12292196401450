/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsEfsExceptionListItem
 */
export interface MelcoCoreModelsEfsExceptionListItem {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsExceptionListItem
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsExceptionListItem
     */
    key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsExceptionListItem
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsExceptionListItem
     */
    desc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsExceptionListItem
     */
    value1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsExceptionListItem
     */
    value2?: string | null;
}

export function MelcoCoreModelsEfsExceptionListItemFromJSON(json: any): MelcoCoreModelsEfsExceptionListItem {
    return MelcoCoreModelsEfsExceptionListItemFromJSONTyped(json, false);
}

export function MelcoCoreModelsEfsExceptionListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsEfsExceptionListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'desc': !exists(json, 'desc') ? undefined : json['desc'],
        'value1': !exists(json, 'value1') ? undefined : json['value1'],
        'value2': !exists(json, 'value2') ? undefined : json['value2'],
    };
}

export function MelcoCoreModelsEfsExceptionListItemToJSON(value?: MelcoCoreModelsEfsExceptionListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'key': value.key,
        'name': value.name,
        'desc': value.desc,
        'value1': value.value1,
        'value2': value.value2,
    };
}


