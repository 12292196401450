import { MelcoCoreModelsSession } from "melco-shared-logic/dist/api/models/MelcoCoreModelsSession";
import { createRoot } from "react-dom/client";
import { v4 as uuidv4 } from "uuid";
import { App } from "./App";
import { DesignSelector } from "./DesignSelector";

export type OnCompleteCallback = (session: MelcoCoreModelsSession) => void;

export type OnCancelCallback = () => void;

export type SupportedLanguages = "en" | "de" | "fr" | "it" | "nl" | "sv";

export type ConfiguratorOptions = {
  onComplete: OnCompleteCallback;
  onCancel: OnCancelCallback;
  color?: string;
  defaultDesignIDs?: string;
  language?: SupportedLanguages;
  sessionId?: string;
  isProductColorChangeDisabled?: boolean;
  isDesignChangeDisabled?: boolean;
  startInViewMode?: boolean;
  defaultViewName?: string;
};

export type DesignSelectorOptions = {
  onDesignSelection?: (designId: string, viewName: string) => void;
  defaultDesignIDs?: string;
};

const rootDOMElementId = "melco-configurator";

export class MelcoConfigurator {
  constructor() {}

  start(token: string, apiKey: string, options: ConfiguratorOptions) {
    let rootElement = document.getElementById(rootDOMElementId);

    if (!rootElement) {
      rootElement = document.createElement("div");
      rootElement.setAttribute("id", rootDOMElementId);
      document.body.appendChild(rootElement);
    }

    // generate random key each time a session is started to force main application component to be unmounted and mounted again
    const key = uuidv4();

    const root = createRoot(rootElement);
    root.render(
      <App key={key} token={token} apiKey={apiKey} options={options} />
    );
  }

  renderDesignSelector(
    targetElement: HTMLElement,
    token: string,
    apiKey: string,
    options: DesignSelectorOptions
  ) {
    const root = createRoot(targetElement);
    root.render(
      <DesignSelector token={token} apiKey={apiKey} options={options} />
    );
  }
}
