/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsUsersResendCode
 */
export interface MelcoCoreModelsUsersResendCode {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUsersResendCode
     */
    code?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsUsersResendCode
     */
    result?: boolean;
}

export function MelcoCoreModelsUsersResendCodeFromJSON(json: any): MelcoCoreModelsUsersResendCode {
    return MelcoCoreModelsUsersResendCodeFromJSONTyped(json, false);
}

export function MelcoCoreModelsUsersResendCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsUsersResendCode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'result': !exists(json, 'result') ? undefined : json['result'],
    };
}

export function MelcoCoreModelsUsersResendCodeToJSON(value?: MelcoCoreModelsUsersResendCode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'result': value.result,
    };
}


