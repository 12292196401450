/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsFusionAppSetting
 */
export interface MelcoCoreModelsFusionAppSetting {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsFusionAppSetting
     */
    measurement_unit: string;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsFusionAppSetting
     */
    default_embroidery_production_file_format: string;
}

export function MelcoCoreModelsFusionAppSettingFromJSON(json: any): MelcoCoreModelsFusionAppSetting {
    return MelcoCoreModelsFusionAppSettingFromJSONTyped(json, false);
}

export function MelcoCoreModelsFusionAppSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsFusionAppSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'measurement_unit': json['measurement_unit'],
        'default_embroidery_production_file_format': json['default_embroidery_production_file_format'],
    };
}

export function MelcoCoreModelsFusionAppSettingToJSON(value?: MelcoCoreModelsFusionAppSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'measurement_unit': value.measurement_unit,
        'default_embroidery_production_file_format': value.default_embroidery_production_file_format,
    };
}


