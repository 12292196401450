/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsColorListItem,
    MelcoCoreModelsColorListItemFromJSON,
    MelcoCoreModelsColorListItemFromJSONTyped,
    MelcoCoreModelsColorListItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsUpdateColorCollection
 */
export interface MelcoCoreModelsUpdateColorCollection {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUpdateColorCollection
     */
    name?: string | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsColorListItem>}
     * @memberof MelcoCoreModelsUpdateColorCollection
     */
    color_list?: Array<MelcoCoreModelsColorListItem> | null;
}

export function MelcoCoreModelsUpdateColorCollectionFromJSON(json: any): MelcoCoreModelsUpdateColorCollection {
    return MelcoCoreModelsUpdateColorCollectionFromJSONTyped(json, false);
}

export function MelcoCoreModelsUpdateColorCollectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsUpdateColorCollection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'color_list': !exists(json, 'color_list') ? undefined : (json['color_list'] === null ? null : (json['color_list'] as Array<any>).map(MelcoCoreModelsColorListItemFromJSON)),
    };
}

export function MelcoCoreModelsUpdateColorCollectionToJSON(value?: MelcoCoreModelsUpdateColorCollection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'color_list': value.color_list === undefined ? undefined : (value.color_list === null ? null : (value.color_list as Array<any>).map(MelcoCoreModelsColorListItemToJSON)),
    };
}


