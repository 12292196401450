/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsDRMUserSerialNumber,
    MelcoCoreModelsDRMUserSerialNumberFromJSON,
    MelcoCoreModelsDRMUserSerialNumberFromJSONTyped,
    MelcoCoreModelsDRMUserSerialNumberToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsSecurityToken
 */
export interface MelcoCoreModelsSecurityToken {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsSecurityToken
     */
    token?: string | null;
    /**
     * 
     * @type {MelcoCoreModelsDRMUserSerialNumber}
     * @memberof MelcoCoreModelsSecurityToken
     */
    subscription?: MelcoCoreModelsDRMUserSerialNumber;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsSecurityToken
     */
    user_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsSecurityToken
     */
    parent_user_id?: string | null;
}

export function MelcoCoreModelsSecurityTokenFromJSON(json: any): MelcoCoreModelsSecurityToken {
    return MelcoCoreModelsSecurityTokenFromJSONTyped(json, false);
}

export function MelcoCoreModelsSecurityTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsSecurityToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': !exists(json, 'token') ? undefined : json['token'],
        'subscription': !exists(json, 'subscription') ? undefined : MelcoCoreModelsDRMUserSerialNumberFromJSON(json['subscription']),
        'user_id': !exists(json, 'user_id') ? undefined : json['user_id'],
        'parent_user_id': !exists(json, 'parent_user_id') ? undefined : json['parent_user_id'],
    };
}

export function MelcoCoreModelsSecurityTokenToJSON(value?: MelcoCoreModelsSecurityToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'subscription': MelcoCoreModelsDRMUserSerialNumberToJSON(value.subscription),
        'user_id': value.user_id,
        'parent_user_id': value.parent_user_id,
    };
}


