/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsAlphabetListItem,
    MelcoCoreModelsAlphabetListItemFromJSON,
    MelcoCoreModelsAlphabetListItemFromJSONTyped,
    MelcoCoreModelsAlphabetListItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsAddAlphabetCollection
 */
export interface MelcoCoreModelsAddAlphabetCollection {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsAddAlphabetCollection
     */
    name?: string | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsAlphabetListItem>}
     * @memberof MelcoCoreModelsAddAlphabetCollection
     */
    alphabet_list?: Array<MelcoCoreModelsAlphabetListItem> | null;
}

export function MelcoCoreModelsAddAlphabetCollectionFromJSON(json: any): MelcoCoreModelsAddAlphabetCollection {
    return MelcoCoreModelsAddAlphabetCollectionFromJSONTyped(json, false);
}

export function MelcoCoreModelsAddAlphabetCollectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsAddAlphabetCollection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'alphabet_list': !exists(json, 'alphabet_list') ? undefined : (json['alphabet_list'] === null ? null : (json['alphabet_list'] as Array<any>).map(MelcoCoreModelsAlphabetListItemFromJSON)),
    };
}

export function MelcoCoreModelsAddAlphabetCollectionToJSON(value?: MelcoCoreModelsAddAlphabetCollection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'alphabet_list': value.alphabet_list === undefined ? undefined : (value.alphabet_list === null ? null : (value.alphabet_list as Array<any>).map(MelcoCoreModelsAlphabetListItemToJSON)),
    };
}


