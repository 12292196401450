/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsSelectListItem
 */
export interface MelcoCoreModelsSelectListItem {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsSelectListItem
     */
    key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsSelectListItem
     */
    display_text?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsSelectListItem
     */
    is_default?: boolean;
}

export function MelcoCoreModelsSelectListItemFromJSON(json: any): MelcoCoreModelsSelectListItem {
    return MelcoCoreModelsSelectListItemFromJSONTyped(json, false);
}

export function MelcoCoreModelsSelectListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsSelectListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'display_text': !exists(json, 'display_text') ? undefined : json['display_text'],
        'is_default': !exists(json, 'is_default') ? undefined : json['is_default'],
    };
}

export function MelcoCoreModelsSelectListItemToJSON(value?: MelcoCoreModelsSelectListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'display_text': value.display_text,
        'is_default': value.is_default,
    };
}


