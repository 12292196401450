/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsIdentityDevice
 */
export interface MelcoCoreModelsIdentityDevice {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsIdentityDevice
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsIdentityDevice
     */
    operating_system_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsIdentityDevice
     */
    operating_system_version?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsIdentityDevice
     */
    cpu_architecture?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsIdentityDevice
     */
    system_uuid?: string | null;
}

export function MelcoCoreModelsIdentityDeviceFromJSON(json: any): MelcoCoreModelsIdentityDevice {
    return MelcoCoreModelsIdentityDeviceFromJSONTyped(json, false);
}

export function MelcoCoreModelsIdentityDeviceFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsIdentityDevice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'operating_system_name': !exists(json, 'operating_system_name') ? undefined : json['operating_system_name'],
        'operating_system_version': !exists(json, 'operating_system_version') ? undefined : json['operating_system_version'],
        'cpu_architecture': !exists(json, 'cpu_architecture') ? undefined : json['cpu_architecture'],
        'system_uuid': !exists(json, 'system_uuid') ? undefined : json['system_uuid'],
    };
}

export function MelcoCoreModelsIdentityDeviceToJSON(value?: MelcoCoreModelsIdentityDevice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'operating_system_name': value.operating_system_name,
        'operating_system_version': value.operating_system_version,
        'cpu_architecture': value.cpu_architecture,
        'system_uuid': value.system_uuid,
    };
}


