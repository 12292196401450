/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsDocContent
 */
export interface MelcoCoreModelsDocContent {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDocContent
     */
    tags?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDocContent
     */
    content?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDocContent
     */
    type?: string | null;
}

export function MelcoCoreModelsDocContentFromJSON(json: any): MelcoCoreModelsDocContent {
    return MelcoCoreModelsDocContentFromJSONTyped(json, false);
}

export function MelcoCoreModelsDocContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsDocContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function MelcoCoreModelsDocContentToJSON(value?: MelcoCoreModelsDocContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tags': value.tags,
        'content': value.content,
        'type': value.type,
    };
}


