import { useField } from "formik";
import { ElementListHelper } from "melco-shared-logic/dist/helper/elementList/elementListHelper";
import { MelcoCoreModelsDesignElement } from "melco-shared-logic/dist/api/models/MelcoCoreModelsDesignElement";
import { useDesignFormikPath } from "./useDesignFormikPath";
import { ActiveView } from "./useActiveView";
import { ActiveColor } from "./useProductColor";

const { isLettering, isColor, isNotActiveGroupColor, isEditable } =
  ElementListHelper;

export const useElementList = (
  activeView: ActiveView | undefined,
  activeColor: ActiveColor | undefined
) => {
  const designFormikPath = useDesignFormikPath(activeView, activeColor);

  const elementListFormikPath = `${designFormikPath}.element_list`;

  const [{ value: elementList }] = useField<MelcoCoreModelsDesignElement[]>(
    elementListFormikPath
  );

  const elementIndexes = new Map<MelcoCoreModelsDesignElement, number>();

  const letteringObjects: MelcoCoreModelsDesignElement[] = [];
  const colorObjects: MelcoCoreModelsDesignElement[] = [];

  (elementList ?? []).forEach((e, idx) => {
    if (!isEditable(e) || !isNotActiveGroupColor(e)) {
      return;
    }

    if (isLettering(e)) {
      letteringObjects.push(e);
      elementIndexes.set(e, idx);
    } else if (isColor(e)) {
      colorObjects.push(e);
      elementIndexes.set(e, idx);
    }
  });

  return { letteringObjects, colorObjects, elementIndexes };
};
