/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsDRMGenerateSerialNumber
 */
export interface MelcoCoreModelsDRMGenerateSerialNumber {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMGenerateSerialNumber
     */
    product_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMGenerateSerialNumber
     */
    subscription_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMGenerateSerialNumber
     */
    referral_code?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsDRMGenerateSerialNumber
     */
    number_of_licenses?: number;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMGenerateSerialNumber
     */
    version_id?: string | null;
}

export function MelcoCoreModelsDRMGenerateSerialNumberFromJSON(json: any): MelcoCoreModelsDRMGenerateSerialNumber {
    return MelcoCoreModelsDRMGenerateSerialNumberFromJSONTyped(json, false);
}

export function MelcoCoreModelsDRMGenerateSerialNumberFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsDRMGenerateSerialNumber {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'product_code': !exists(json, 'product_code') ? undefined : json['product_code'],
        'subscription_code': !exists(json, 'subscription_code') ? undefined : json['subscription_code'],
        'referral_code': !exists(json, 'referral_code') ? undefined : json['referral_code'],
        'number_of_licenses': !exists(json, 'number_of_licenses') ? undefined : json['number_of_licenses'],
        'version_id': !exists(json, 'version_id') ? undefined : json['version_id'],
    };
}

export function MelcoCoreModelsDRMGenerateSerialNumberToJSON(value?: MelcoCoreModelsDRMGenerateSerialNumber | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product_code': value.product_code,
        'subscription_code': value.subscription_code,
        'referral_code': value.referral_code,
        'number_of_licenses': value.number_of_licenses,
        'version_id': value.version_id,
    };
}


