import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useUserToken } from "../user/useUserToken";
import { useImpersonationForId } from "../user/useImpersonationForId";

import { Configuration, Middleware, RequestContext } from "../../api";

const getBasePath = (basePath?: string) =>
  basePath || process.env.REACT_APP_API_BASE_URL!;

const addTokenToHeaderMiddleware = (token: string) =>
  addHeaderMiddleware("Authorization", `melco ${token}`);

const addImpersonationMiddleware = (impersonationForId: string) =>
  addHeaderMiddleware("x-melco-impersonate", impersonationForId);

const addAPIKeyToHeaderMiddleware = (apiKey?: string) =>
  addHeaderMiddleware("x-api-key", apiKey || process.env.REACT_APP_API_KEY!);

const addUserCultureToHeaderMiddleware = (languageIsoCode?: string) =>
  addHeaderMiddleware("x-user-culture", languageIsoCode ?? "en-US");

const addDisplayCultureToHeaderMiddleware = (languageIsoCode?: string) =>
  addHeaderMiddleware("x-display-culture", languageIsoCode ?? "en-US");

const addHeaderMiddleware = (header: string, value: string) => {
  return {
    pre: (context: RequestContext) => {
      return new Promise<void>((resolve) => {
        context.init.headers = {
          ...context.init.headers,
          [header]: value,
        };
        resolve();
      });
    },
  };
};

const includeCredentialsMiddleware: Middleware = {
  pre: (context: RequestContext) => {
    return new Promise<void>((resolve) => {
      context.init.credentials = "include";
      resolve();
    });
  },
};

const redirectToLoginIfUnauthorizedMiddleware: Middleware = {
  post: async ({ response }) => {
    if (response.status === 401) {
      // redirect to account app
      window.location.href = `${process.env.REACT_APP_ACCOUNT_URL}?error=expired&redirectTo=${window.location}`;
    }
  },
};

export type DefaultAPIConfigOptions = {
  basePath?: string;
  apiKey?: string;
  token?: string;
  impersonationForId?: string;
  includeCredentials?: boolean;
  skipRedirectToLoginIfUnauthorized?: boolean;
  languageIsoCode?: string;
};

const defaultApiConfig = (options?: DefaultAPIConfigOptions) => {
  const middleware: Middleware[] = [
    addAPIKeyToHeaderMiddleware(options?.apiKey),
  ];

  const {
    token,
    impersonationForId,
    includeCredentials,
    skipRedirectToLoginIfUnauthorized,
    languageIsoCode,
    basePath,
  } = options || {};

  if (token) {
    middleware.push(addTokenToHeaderMiddleware(token));
  }

  if (impersonationForId) {
    middleware.push(addImpersonationMiddleware(impersonationForId));
  }

  if (includeCredentials) {
    middleware.push(includeCredentialsMiddleware);
  }

  if (!skipRedirectToLoginIfUnauthorized) {
    middleware.push(redirectToLoginIfUnauthorizedMiddleware);
  }

  middleware.push(addUserCultureToHeaderMiddleware(languageIsoCode));

  middleware.push(addDisplayCultureToHeaderMiddleware(languageIsoCode));

  const apiConfig = new Configuration({
    basePath: getBasePath(basePath),
    middleware,
  });

  return apiConfig;
};

export const useBaseAPIConfigAndCacheKey = () => {
  const { i18n } = useTranslation();

  return useCallback(
    (options?: DefaultAPIConfigOptions) => {
      const config = {
        languageIsoCode: i18n.resolvedLanguage, //Set languageIsoCode with possibility to get overwritten from passed options
        ...options,
      };
      return {
        config: defaultApiConfig(config),
        cacheKey: JSON.stringify(config),
      };
    },
    [i18n.resolvedLanguage]
  );
};

export const useBaseAPIConfig = () => {
  const baseApiConfig = useBaseAPIConfigAndCacheKey();

  return useCallback(
    (options?: DefaultAPIConfigOptions) => baseApiConfig(options).config,
    [baseApiConfig]
  );
};

export const useAuthenticatedAPIConfigAndCacheKey = () => {
  const token = useUserToken();
  const impersonationForId = useImpersonationForId();
  const baseApiConfig = useBaseAPIConfigAndCacheKey();

  return useCallback(
    (options?: DefaultAPIConfigOptions) =>
      baseApiConfig({
        token,
        impersonationForId,
        ...options,
      }),
    [baseApiConfig, token, impersonationForId]
  );
};

export const useAuthenticatedAPIConfig = () => {
  const apiConfig = useAuthenticatedAPIConfigAndCacheKey();

  return useCallback(
    (options?: DefaultAPIConfigOptions) => apiConfig(options).config,
    [apiConfig]
  );
};
