/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsUserAlphabet,
    MelcoCoreModelsUserAlphabetFromJSON,
    MelcoCoreModelsUserAlphabetFromJSONTyped,
    MelcoCoreModelsUserAlphabetToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsDesignLetteringElement
 */
export interface MelcoCoreModelsDesignLetteringElement {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDesignLetteringElement
     */
    text?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDesignLetteringElement
     */
    alphabet_name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsDesignLetteringElement
     */
    angle?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsDesignLetteringElement
     */
    width_factor?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsDesignLetteringElement
     */
    slant_angle?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsDesignLetteringElement
     */
    tracking?: number;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDesignLetteringElement
     */
    rotation_direction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDesignLetteringElement
     */
    layout_type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDesignLetteringElement
     */
    justification?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsDesignLetteringElement
     */
    radius?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsDesignLetteringElement
     */
    height?: number;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDesignLetteringElement
     */
    horizontal_stitch_order?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDesignLetteringElement
     */
    vertical_stitch_order?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDesignLetteringElement
     */
    connection_type?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsDesignLetteringElement
     */
    continuous_underlay?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDesignLetteringElement
     */
    default_text?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsDesignLetteringElement
     */
    text_max_length?: number;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDesignLetteringElement
     */
    default_alphabet_id?: string | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsUserAlphabet>}
     * @memberof MelcoCoreModelsDesignLetteringElement
     */
    alphabet_list?: Array<MelcoCoreModelsUserAlphabet> | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsDesignLetteringElement
     */
    renderer_index?: number;
}

export function MelcoCoreModelsDesignLetteringElementFromJSON(json: any): MelcoCoreModelsDesignLetteringElement {
    return MelcoCoreModelsDesignLetteringElementFromJSONTyped(json, false);
}

export function MelcoCoreModelsDesignLetteringElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsDesignLetteringElement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'text': !exists(json, 'text') ? undefined : json['text'],
        'alphabet_name': !exists(json, 'alphabet_name') ? undefined : json['alphabet_name'],
        'angle': !exists(json, 'angle') ? undefined : json['angle'],
        'width_factor': !exists(json, 'width_factor') ? undefined : json['width_factor'],
        'slant_angle': !exists(json, 'slant_angle') ? undefined : json['slant_angle'],
        'tracking': !exists(json, 'tracking') ? undefined : json['tracking'],
        'rotation_direction': !exists(json, 'rotation_direction') ? undefined : json['rotation_direction'],
        'layout_type': !exists(json, 'layout_type') ? undefined : json['layout_type'],
        'justification': !exists(json, 'justification') ? undefined : json['justification'],
        'radius': !exists(json, 'radius') ? undefined : json['radius'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'horizontal_stitch_order': !exists(json, 'horizontal_stitch_order') ? undefined : json['horizontal_stitch_order'],
        'vertical_stitch_order': !exists(json, 'vertical_stitch_order') ? undefined : json['vertical_stitch_order'],
        'connection_type': !exists(json, 'connection_type') ? undefined : json['connection_type'],
        'continuous_underlay': !exists(json, 'continuous_underlay') ? undefined : json['continuous_underlay'],
        'default_text': !exists(json, 'default_text') ? undefined : json['default_text'],
        'text_max_length': !exists(json, 'text_max_length') ? undefined : json['text_max_length'],
        'default_alphabet_id': !exists(json, 'default_alphabet_id') ? undefined : json['default_alphabet_id'],
        'alphabet_list': !exists(json, 'alphabet_list') ? undefined : (json['alphabet_list'] === null ? null : (json['alphabet_list'] as Array<any>).map(MelcoCoreModelsUserAlphabetFromJSON)),
        'renderer_index': !exists(json, 'renderer_index') ? undefined : json['renderer_index'],
    };
}

export function MelcoCoreModelsDesignLetteringElementToJSON(value?: MelcoCoreModelsDesignLetteringElement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text': value.text,
        'alphabet_name': value.alphabet_name,
        'angle': value.angle,
        'width_factor': value.width_factor,
        'slant_angle': value.slant_angle,
        'tracking': value.tracking,
        'rotation_direction': value.rotation_direction,
        'layout_type': value.layout_type,
        'justification': value.justification,
        'radius': value.radius,
        'height': value.height,
        'horizontal_stitch_order': value.horizontal_stitch_order,
        'vertical_stitch_order': value.vertical_stitch_order,
        'connection_type': value.connection_type,
        'continuous_underlay': value.continuous_underlay,
        'default_text': value.default_text,
        'text_max_length': value.text_max_length,
        'default_alphabet_id': value.default_alphabet_id,
        'alphabet_list': value.alphabet_list === undefined ? undefined : (value.alphabet_list === null ? null : (value.alphabet_list as Array<any>).map(MelcoCoreModelsUserAlphabetToJSON)),
        'renderer_index': value.renderer_index,
    };
}


