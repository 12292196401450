import { useFormikContext } from "formik";
import { ConfiguratorApi } from "melco-shared-logic/dist/api/apis/ConfiguratorApi";
import { MelcoCoreModelsSession } from "melco-shared-logic/dist/api/models/MelcoCoreModelsSession";
import { useMutation } from "react-query";
import { OnCompleteCallback } from "..";
import { useAPIConfig } from "./useAPIConfig";

export const useFinishSession = (
  apiKey: string,
  onCompleteCallback: OnCompleteCallback
) => {
  const apiConfig = useAPIConfig(apiKey);
  const { values } = useFormikContext<MelcoCoreModelsSession | undefined>();

  return useMutation(async () => {
    const api = new ConfiguratorApi(apiConfig);

    if (!values) {
      return;
    }

    const completedSession = await api.configuratorSessionsComplete({
      sessionId: values.session_id!,
      melcoCoreModelsSession: values,
    });

    onCompleteCallback(completedSession);
  });
};
