/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsColorListItem,
    MelcoCoreModelsColorListItemFromJSON,
    MelcoCoreModelsColorListItemFromJSONTyped,
    MelcoCoreModelsColorListItemToJSON,
    MelcoCoreModelsCustomWarning,
    MelcoCoreModelsCustomWarningFromJSON,
    MelcoCoreModelsCustomWarningFromJSONTyped,
    MelcoCoreModelsCustomWarningToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsColorCollection
 */
export interface MelcoCoreModelsColorCollection {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsColorCollection
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsColorCollection
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsColorCollection
     */
    service_id?: number;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsColorCollection
     */
    service_name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsColorCollection
     */
    color_count?: number;
    /**
     * 
     * @type {Array<MelcoCoreModelsCustomWarning>}
     * @memberof MelcoCoreModelsColorCollection
     */
    warning_list?: Array<MelcoCoreModelsCustomWarning> | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsColorListItem>}
     * @memberof MelcoCoreModelsColorCollection
     */
    color_list?: Array<MelcoCoreModelsColorListItem> | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsColorCollection
     */
    publish_state?: number;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsColorCollection
     */
    created_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsColorCollection
     */
    publish_date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsColorCollection
     */
    updated_date?: Date | null;
}

export function MelcoCoreModelsColorCollectionFromJSON(json: any): MelcoCoreModelsColorCollection {
    return MelcoCoreModelsColorCollectionFromJSONTyped(json, false);
}

export function MelcoCoreModelsColorCollectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsColorCollection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'service_id': !exists(json, 'service_id') ? undefined : json['service_id'],
        'service_name': !exists(json, 'service_name') ? undefined : json['service_name'],
        'color_count': !exists(json, 'color_count') ? undefined : json['color_count'],
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(MelcoCoreModelsCustomWarningFromJSON)),
        'color_list': !exists(json, 'color_list') ? undefined : (json['color_list'] === null ? null : (json['color_list'] as Array<any>).map(MelcoCoreModelsColorListItemFromJSON)),
        'publish_state': !exists(json, 'publish_state') ? undefined : json['publish_state'],
        'created_date': !exists(json, 'created_date') ? undefined : (new Date(json['created_date'])),
        'publish_date': !exists(json, 'publish_date') ? undefined : (json['publish_date'] === null ? null : new Date(json['publish_date'])),
        'updated_date': !exists(json, 'updated_date') ? undefined : (json['updated_date'] === null ? null : new Date(json['updated_date'])),
    };
}

export function MelcoCoreModelsColorCollectionToJSON(value?: MelcoCoreModelsColorCollection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'service_id': value.service_id,
        'service_name': value.service_name,
        'color_count': value.color_count,
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(MelcoCoreModelsCustomWarningToJSON)),
        'color_list': value.color_list === undefined ? undefined : (value.color_list === null ? null : (value.color_list as Array<any>).map(MelcoCoreModelsColorListItemToJSON)),
        'publish_state': value.publish_state,
        'created_date': value.created_date === undefined ? undefined : (value.created_date.toISOString()),
        'publish_date': value.publish_date === undefined ? undefined : (value.publish_date === null ? null : value.publish_date.toISOString()),
        'updated_date': value.updated_date === undefined ? undefined : (value.updated_date === null ? null : value.updated_date.toISOString()),
    };
}


