import { useCallback } from "react";
import { MelcoCoreModelsDesign } from "melco-shared-logic/dist/api/models/MelcoCoreModelsDesign";
import { MelcoCoreModelsDesignElement } from "melco-shared-logic/dist/api/models/MelcoCoreModelsDesignElement";
import { ElementListHelper } from "melco-shared-logic/dist/helper/elementList/elementListHelper";
import { useField, useFormikContext } from "formik";
import cloneDeep from "lodash/cloneDeep";
import { ActiveView } from "./useActiveView";
import { useProductColorIndexList } from "./useProductColorIndexList";
import { designFormikPrefix } from "../helper/formikPrefixHelper";
import { useElementList } from "./useElementList";
import { ActiveColor } from "./useProductColor";
import { useFetchDesignCollection } from "./useFetchDesignCollection";
import { useDesignFormikPath } from "./useDesignFormikPath";

const { isLettering, isNotActiveGroupColor, isEditable } = ElementListHelper;

const applyLettering = (
  lettering: MelcoCoreModelsDesignElement,
  design: MelcoCoreModelsDesign,
  elementIndex: number | undefined
) => {
  if (elementIndex == null || !lettering.lettering || !design.element_list) {
    return;
  }
  // apply text
  let { text } = lettering.lettering;

  const { text_max_length: textMaxLength } =
    design.element_list[elementIndex].lettering!;

  if (text) {
    if (textMaxLength && textMaxLength > 0) {
      text = text.substring(0, textMaxLength);
    }

    design.element_list[elementIndex].lettering!.text = text;
  }
};

export const useDesignCollection = (
  designCollectionId: string,
  activeView: ActiveView | undefined,
  activeColor: ActiveColor | undefined,
  apiKey: string
) => {
  const allColorIndexes = useProductColorIndexList();

  const { setFieldValue, validateForm } = useFormikContext();

  const { letteringObjects, colorObjects } = useElementList(
    activeView,
    activeColor
  );

  const [availableDesigns] = useFetchDesignCollection(
    designCollectionId,
    apiKey
  );

  const designFormikPath = useDesignFormikPath(activeView, activeColor);

  const currentDesignIdFormikPath = `${designFormikPath}.id`;

  const [{ value: currentDesignId }] = useField<string | undefined>(
    currentDesignIdFormikPath
  );

  const setDesign = useCallback(
    (newDesign: MelcoCoreModelsDesign) => {
      // clone design such that changes to the design will not change the original design
      const design = cloneDeep(newDesign);

      if (!activeView) {
        return;
      }

      const currentDesign = availableDesigns.find(
        (d) => d.id === currentDesignId
      );

      // try to keep personalized text if possible and if the user has personalized it. the lookup is done index based (e.g. first lettering element config of current design is applied to first lettering of new design etc.)
      const newDesignLetteringIndexes: number[] = [];

      (design.element_list ?? []).forEach((e, idx) => {
        if (!isEditable(e) || !isNotActiveGroupColor(e)) {
          return;
        }

        if (isLettering(e)) {
          newDesignLetteringIndexes.push(idx);
        }
      });

      // apply lettering text changes (if it has been changed by the user)
      letteringObjects.forEach((lettering, idx) => {
        const originalLetteringObject = (
          currentDesign?.element_list ?? []
        ).find((e) => e.id === lettering.id);

        const isTextChanged =
          lettering.lettering?.text !==
          originalLetteringObject?.lettering?.text;

        if (isTextChanged) {
          const newDesignElementIndex = newDesignLetteringIndexes[idx];
          applyLettering(lettering, design, newDesignElementIndex);
        }
      });

      // update value for all colors
      // i am not happy with this, but the data model forces us to do so and assume that all colors have the same designs applied with the same element lists that need to be kept in sync
      for (const colorIndex of allColorIndexes) {
        setFieldValue(designFormikPrefix(activeView, colorIndex), design);
      }

      // after all updates have settled, trigger a validation to make sure that the new design gets validated immediately
      setTimeout(validateForm, 0);
    },
    [
      availableDesigns,
      activeView,
      currentDesignId,
      letteringObjects,
      colorObjects,
    ]
  );

  return [availableDesigns, setDesign] as const;
};
