import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFeature } from "flagged";
import { MelcoCoreModelsDesign } from "melco-shared-logic/dist/api/models/MelcoCoreModelsDesign";
import { ModalPicker } from "../modal/picker/ModalPicker";
import { Button } from "../button/Button";
import { FloatingButton } from "../floatingButton/FloatingButton";
import { FloatingButtonGrid } from "../floatingButtonGrid/FloatingButtonGrid";
import { selectThumbnailForSize } from "melco-shared-logic/dist/helper/thumbnails/thumbnailHelper";
import { DesignFloatingButton } from "../floatingButton/DesignFloatingButton";

type DesignPickerProps = {
  isPickerVisible: boolean;
  closePicker: () => void;
  initialDesign: MelcoCoreModelsDesign | undefined;
  onDesignChange: (design: MelcoCoreModelsDesign) => void;
  availableDesigns: MelcoCoreModelsDesign[];
};

export const DesignPicker: React.FC<DesignPickerProps> = ({
  isPickerVisible,
  closePicker,
  initialDesign,
  onDesignChange,
  availableDesigns,
}) => {
  const { t } = useTranslation();

  const isLabeledFeatureEnabled = useFeature("ui/labeled");

  const [selectedDesign, setSelectedDesign] = useState<
    MelcoCoreModelsDesign | undefined
  >(initialDesign);

  // set initial design, if no design has been selected
  useEffect(() => {
    if (!selectedDesign && initialDesign) {
      setSelectedDesign(initialDesign);
    }
  }, [selectedDesign, initialDesign, setSelectedDesign]);

  const onCancel = useCallback(() => {
    closePicker();

    // reset
    setSelectedDesign(initialDesign);
  }, [initialDesign, closePicker, setSelectedDesign]);

  const onOk = useCallback(() => {
    // confirm font change
    if (selectedDesign) {
      onDesignChange(selectedDesign);
    }

    closePicker();
  }, [selectedDesign, closePicker, setSelectedDesign]);

  return (
    <ModalPicker
      isVisible={isPickerVisible}
      title={t("designPicker.title")}
      buttons={[
        <Button key="picker-cancel" onClick={onCancel}>
          {t("actionButtons.cancel")}
        </Button>,
        <Button key="picker-ok" type="primary" onClick={onOk}>
          {t("actionButtons.ok")}
        </Button>,
      ]}
    >
      {isLabeledFeatureEnabled && (
        <DesignFloatingButton design={selectedDesign} />
      )}

      <FloatingButtonGrid>
        {availableDesigns.map((design) => {
          const { id, name, previews } = design;

          const designPreviewThumbnailUrl =
            selectThumbnailForSize(previews ?? [], 64)?.url ?? "";

          return (
            <FloatingButton
              key={id}
              isActive={selectedDesign && selectedDesign.id === id}
              onClick={() => {
                setSelectedDesign(design);
              }}
              size="large"
            >
              <img src={designPreviewThumbnailUrl} alt={name ?? ""} />
            </FloatingButton>
          );
        })}
      </FloatingButtonGrid>
    </ModalPicker>
  );
};
