/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsCustomProductView,
    MelcoCoreModelsCustomProductViewFromJSON,
    MelcoCoreModelsCustomProductViewFromJSONTyped,
    MelcoCoreModelsCustomProductViewToJSON,
    MelcoCoreModelsUserBlank,
    MelcoCoreModelsUserBlankFromJSON,
    MelcoCoreModelsUserBlankFromJSONTyped,
    MelcoCoreModelsUserBlankToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsUpdateCustomProduct
 */
export interface MelcoCoreModelsUpdateCustomProduct {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUpdateCustomProduct
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUpdateCustomProduct
     */
    token?: string | null;
    /**
     * 
     * @type {MelcoCoreModelsUserBlank}
     * @memberof MelcoCoreModelsUpdateCustomProduct
     */
    blank?: MelcoCoreModelsUserBlank;
    /**
     * 
     * @type {Array<MelcoCoreModelsCustomProductView>}
     * @memberof MelcoCoreModelsUpdateCustomProduct
     */
    view_list?: Array<MelcoCoreModelsCustomProductView> | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsUpdateCustomProduct
     */
    configurator_version?: number | null;
}

export function MelcoCoreModelsUpdateCustomProductFromJSON(json: any): MelcoCoreModelsUpdateCustomProduct {
    return MelcoCoreModelsUpdateCustomProductFromJSONTyped(json, false);
}

export function MelcoCoreModelsUpdateCustomProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsUpdateCustomProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'blank': !exists(json, 'blank') ? undefined : MelcoCoreModelsUserBlankFromJSON(json['blank']),
        'view_list': !exists(json, 'view_list') ? undefined : (json['view_list'] === null ? null : (json['view_list'] as Array<any>).map(MelcoCoreModelsCustomProductViewFromJSON)),
        'configurator_version': !exists(json, 'configurator_version') ? undefined : json['configurator_version'],
    };
}

export function MelcoCoreModelsUpdateCustomProductToJSON(value?: MelcoCoreModelsUpdateCustomProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'token': value.token,
        'blank': MelcoCoreModelsUserBlankToJSON(value.blank),
        'view_list': value.view_list === undefined ? undefined : (value.view_list === null ? null : (value.view_list as Array<any>).map(MelcoCoreModelsCustomProductViewToJSON)),
        'configurator_version': value.configurator_version,
    };
}


