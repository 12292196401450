import { useField } from "formik";
import { MelcoCoreModelsCustomProductView } from "melco-shared-logic/dist/api/models/MelcoCoreModelsCustomProductView";

export const useAvailableViews = () => {
  const [{ value: viewList }] = useField<
    MelcoCoreModelsCustomProductView[] | undefined
  >("product.view_list");

  return viewList ?? [];
};
