/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsActivateSupport
 */
export interface MelcoCoreModelsActivateSupport {
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsActivateSupport
     */
    minutes?: number;
}

export function MelcoCoreModelsActivateSupportFromJSON(json: any): MelcoCoreModelsActivateSupport {
    return MelcoCoreModelsActivateSupportFromJSONTyped(json, false);
}

export function MelcoCoreModelsActivateSupportFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsActivateSupport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'minutes': !exists(json, 'minutes') ? undefined : json['minutes'],
    };
}

export function MelcoCoreModelsActivateSupportToJSON(value?: MelcoCoreModelsActivateSupport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'minutes': value.minutes,
    };
}


