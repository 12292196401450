/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsLogListItem
 */
export interface MelcoCoreModelsLogListItem {
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsLogListItem
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsLogListItem
     */
    file_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsLogListItem
     */
    device_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsLogListItem
     */
    category?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsLogListItem
     */
    description?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsLogListItem
     */
    created_on?: Date;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsLogListItem
     */
    product_code?: string | null;
}

export function MelcoCoreModelsLogListItemFromJSON(json: any): MelcoCoreModelsLogListItem {
    return MelcoCoreModelsLogListItemFromJSONTyped(json, false);
}

export function MelcoCoreModelsLogListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsLogListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'file_name': !exists(json, 'file_name') ? undefined : json['file_name'],
        'device_name': !exists(json, 'device_name') ? undefined : json['device_name'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'created_on': !exists(json, 'created_on') ? undefined : (new Date(json['created_on'])),
        'product_code': !exists(json, 'product_code') ? undefined : json['product_code'],
    };
}

export function MelcoCoreModelsLogListItemToJSON(value?: MelcoCoreModelsLogListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'file_name': value.file_name,
        'device_name': value.device_name,
        'category': value.category,
        'description': value.description,
        'created_on': value.created_on === undefined ? undefined : (value.created_on.toISOString()),
        'product_code': value.product_code,
    };
}


