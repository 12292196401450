/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsProductionStatusHistory
 */
export interface MelcoCoreModelsProductionStatusHistory {
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionStatusHistory
     */
    production_status?: number;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsProductionStatusHistory
     */
    created_date?: Date;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsProductionStatusHistory
     */
    personalization_id?: string | null;
}

export function MelcoCoreModelsProductionStatusHistoryFromJSON(json: any): MelcoCoreModelsProductionStatusHistory {
    return MelcoCoreModelsProductionStatusHistoryFromJSONTyped(json, false);
}

export function MelcoCoreModelsProductionStatusHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsProductionStatusHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'production_status': !exists(json, 'production_status') ? undefined : json['production_status'],
        'created_date': !exists(json, 'created_date') ? undefined : (new Date(json['created_date'])),
        'personalization_id': !exists(json, 'personalization_id') ? undefined : json['personalization_id'],
    };
}

export function MelcoCoreModelsProductionStatusHistoryToJSON(value?: MelcoCoreModelsProductionStatusHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'production_status': value.production_status,
        'created_date': value.created_date === undefined ? undefined : (value.created_date.toISOString()),
        'personalization_id': value.personalization_id,
    };
}


