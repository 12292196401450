import { useMemo } from "react";
import { Color } from "@melco/renderer";
import { useField } from "formik";
import { MelcoCoreModelsDesignElement } from "melco-shared-logic/dist/api/models/MelcoCoreModelsDesignElement";
import compact from "lodash/compact";
import uniq from "lodash/uniq";
import { ElementConfiguration, Resource } from "./useRenderer";
import {
  colorToRendererColor,
  rgbColorToRendererColor,
  truncateText,
} from "../helper/elementConfigurationHelper";
import { ActiveView } from "./useActiveView";
import { ActiveColor } from "./useProductColor";
import { useDesignFormikPath } from "./useDesignFormikPath";

export const useElementConfiguration = (
  activeView: ActiveView | undefined,
  activeColor: ActiveColor | undefined,
  designRfmUrl: string | undefined,
  blankPictureUrl: string | undefined
) => {
  const designFormikPath = useDesignFormikPath(activeView, activeColor);

  const [{ value: elementList }] = useField<
    MelcoCoreModelsDesignElement[] | undefined
  >(`${designFormikPath}.element_list`);

  const getRfmUrlForAlphabet = (alphabetId?: string) => {
    if (!alphabetId) {
      return undefined;
    }

    for (const element of elementList ?? []) {
      for (const alphabet of element.lettering?.alphabet_list ?? []) {
        const { id, rfm_url: rfmUrl } = alphabet;
        if (id === alphabetId) {
          if (rfmUrl) {
            return rfmUrl;
          } else {
            console.error("Could not find RFM url for alphabet", alphabetId);
          }
        }
      }
    }
  };

  const elements = (elementList ?? []).map((element) => {
    const {
      editable,
      active_color_group_id: activeColorGroupId,
      default_color_id: defaultColorId,
      color_collection: colorCollection,
    } = element;

    let color: Color | undefined;

    if (editable) {
      const colorFromCollection = (colorCollection?.color_list ?? []).find(
        (c) => defaultColorId && defaultColorId === c.id
      );

      color = colorFromCollection
        ? colorToRendererColor(colorFromCollection)
        : rgbColorToRendererColor(element.color?.rgb ?? "");
    } else {
      color = rgbColorToRendererColor(element.color?.rgb ?? "");
    }

    const lettering = element.lettering
      ? {
          text: truncateText(element.lettering),
          rfmUrl: getRfmUrlForAlphabet(
            element.lettering.default_alphabet_id ?? undefined
          ),
          rendererIndex: element.lettering.renderer_index!,
          isEditable: editable ?? false,
        }
      : undefined;

    return {
      color,
      activeColorGroupId: activeColorGroupId
        ? parseInt(activeColorGroupId)
        : undefined,
      lettering,
    };
  });

  const resources: Resource[] = [];

  if (blankPictureUrl) {
    resources.push({
      url: blankPictureUrl,
      resourceType: "image",
    });
  }

  if (designRfmUrl) {
    resources.push({
      url: designRfmUrl,
      resourceType: "design",
    });
  }

  // deduplicated list of all alphabet rfms used by all lettering objects
  const alphabetResources: Resource[] = compact(
    uniq(elements.map((e) => e.lettering?.rfmUrl))
  ).map((url) => ({ url, resourceType: "alphabet" as const }));

  resources.push(...alphabetResources);

  const elementConfiguration: ElementConfiguration = {
    elements,
    resources,
  };

  const hash = JSON.stringify(elementConfiguration);

  // prevent unnecessary re-rendering by keeping the elements configuration stable if possible
  const memoizedElementConfiguration = useMemo(() => {
    return elementConfiguration;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  return memoizedElementConfiguration;
};
