/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsCustomProductImage,
    MelcoCoreModelsCustomProductImageFromJSON,
    MelcoCoreModelsCustomProductImageFromJSONTyped,
    MelcoCoreModelsCustomProductImageToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsCustomProductView
 */
export interface MelcoCoreModelsCustomProductView {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsCustomProductView
     */
    name?: string | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsCustomProductImage>}
     * @memberof MelcoCoreModelsCustomProductView
     */
    color_list?: Array<MelcoCoreModelsCustomProductImage> | null;
}

export function MelcoCoreModelsCustomProductViewFromJSON(json: any): MelcoCoreModelsCustomProductView {
    return MelcoCoreModelsCustomProductViewFromJSONTyped(json, false);
}

export function MelcoCoreModelsCustomProductViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsCustomProductView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'color_list': !exists(json, 'color_list') ? undefined : (json['color_list'] === null ? null : (json['color_list'] as Array<any>).map(MelcoCoreModelsCustomProductImageFromJSON)),
    };
}

export function MelcoCoreModelsCustomProductViewToJSON(value?: MelcoCoreModelsCustomProductView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'color_list': value.color_list === undefined ? undefined : (value.color_list === null ? null : (value.color_list as Array<any>).map(MelcoCoreModelsCustomProductImageToJSON)),
    };
}


