/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoCoreModelsBoolResponse,
    MelcoCoreModelsBoolResponseFromJSON,
    MelcoCoreModelsBoolResponseToJSON,
    MelcoCoreModelsError,
    MelcoCoreModelsErrorFromJSON,
    MelcoCoreModelsErrorToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsLogEntry,
    MelcoCoreModelsGenericList1MelcoCoreModelsLogEntryFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsLogEntryToJSON,
} from '../models';

export interface DiagnosticsApiDiagnosticsGetLogsByIdRequest {
    id: string;
}

export interface DiagnosticsApiDiagnosticsSendEmailRequest {
    emailAddress?: string;
    cultureCode?: string;
    productCode?: string;
    userActivity?: string;
    countryCode?: string;
}

export interface DiagnosticsApiDiagnosticsUploadLogsRequest {
    deviceName?: string;
    file?: Blob;
}

/**
 * 
 */
export class DiagnosticsApi extends runtime.BaseAPI {

    /**
     * Get azure logs
     */
    async diagnosticsGetLogsByIdRaw(requestParameters: DiagnosticsApiDiagnosticsGetLogsByIdRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsLogEntry>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling diagnosticsGetLogsById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/diagnostics/logs/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsLogEntryFromJSON(jsonValue));
    }

    /**
     * Get azure logs
     */
    async diagnosticsGetLogsById(requestParameters: DiagnosticsApiDiagnosticsGetLogsByIdRequest): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsLogEntry> {
        const response = await this.diagnosticsGetLogsByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * send email
     */
    async diagnosticsSendEmailRaw(requestParameters: DiagnosticsApiDiagnosticsSendEmailRequest): Promise<runtime.ApiResponse<MelcoCoreModelsBoolResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.emailAddress !== undefined) {
            queryParameters['email_address'] = requestParameters.emailAddress;
        }

        if (requestParameters.cultureCode !== undefined) {
            queryParameters['culture_code'] = requestParameters.cultureCode;
        }

        if (requestParameters.productCode !== undefined) {
            queryParameters['product_code'] = requestParameters.productCode;
        }

        if (requestParameters.userActivity !== undefined) {
            queryParameters['user_activity'] = requestParameters.userActivity;
        }

        if (requestParameters.countryCode !== undefined) {
            queryParameters['country_code'] = requestParameters.countryCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/diagnostics/emails/templates/send`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * send email
     */
    async diagnosticsSendEmail(requestParameters: DiagnosticsApiDiagnosticsSendEmailRequest): Promise<MelcoCoreModelsBoolResponse> {
        const response = await this.diagnosticsSendEmailRaw(requestParameters);
        return await response.value();
    }

    /**
     * UploadLogs
     */
    async diagnosticsUploadLogsRaw(requestParameters: DiagnosticsApiDiagnosticsUploadLogsRequest): Promise<runtime.ApiResponse<MelcoCoreModelsBoolResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.deviceName !== undefined) {
            queryParameters['device_name'] = requestParameters.deviceName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/diagnostics/logs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * UploadLogs
     */
    async diagnosticsUploadLogs(requestParameters: DiagnosticsApiDiagnosticsUploadLogsRequest): Promise<MelcoCoreModelsBoolResponse> {
        const response = await this.diagnosticsUploadLogsRaw(requestParameters);
        return await response.value();
    }

}
