/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsThreadColor,
    MelcoCoreModelsThreadColorFromJSON,
    MelcoCoreModelsThreadColorFromJSONTyped,
    MelcoCoreModelsThreadColorToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsThread
 */
export interface MelcoCoreModelsThread {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsThread
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsThread
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsThread
     */
    code?: string | null;
    /**
     * 
     * @type {MelcoCoreModelsThreadColor}
     * @memberof MelcoCoreModelsThread
     */
    color?: MelcoCoreModelsThreadColor;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsThread
     */
    type?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MelcoCoreModelsThread
     */
    weight_list?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsThread
     */
    supplier_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsThread
     */
    supplier_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsThread
     */
    product_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsThread
     */
    product_name?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsThread
     */
    created_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsThread
     */
    updated_date?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsThread
     */
    publish_state?: number;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsThread
     */
    publish_date?: Date | null;
}

export function MelcoCoreModelsThreadFromJSON(json: any): MelcoCoreModelsThread {
    return MelcoCoreModelsThreadFromJSONTyped(json, false);
}

export function MelcoCoreModelsThreadFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsThread {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'color': !exists(json, 'color') ? undefined : MelcoCoreModelsThreadColorFromJSON(json['color']),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'weight_list': !exists(json, 'weight_list') ? undefined : json['weight_list'],
        'supplier_id': !exists(json, 'supplier_id') ? undefined : json['supplier_id'],
        'supplier_name': !exists(json, 'supplier_name') ? undefined : json['supplier_name'],
        'product_id': !exists(json, 'product_id') ? undefined : json['product_id'],
        'product_name': !exists(json, 'product_name') ? undefined : json['product_name'],
        'created_date': !exists(json, 'created_date') ? undefined : (new Date(json['created_date'])),
        'updated_date': !exists(json, 'updated_date') ? undefined : (json['updated_date'] === null ? null : new Date(json['updated_date'])),
        'publish_state': !exists(json, 'publish_state') ? undefined : json['publish_state'],
        'publish_date': !exists(json, 'publish_date') ? undefined : (json['publish_date'] === null ? null : new Date(json['publish_date'])),
    };
}

export function MelcoCoreModelsThreadToJSON(value?: MelcoCoreModelsThread | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'code': value.code,
        'color': MelcoCoreModelsThreadColorToJSON(value.color),
        'type': value.type,
        'weight_list': value.weight_list,
        'supplier_id': value.supplier_id,
        'supplier_name': value.supplier_name,
        'product_id': value.product_id,
        'product_name': value.product_name,
        'created_date': value.created_date === undefined ? undefined : (value.created_date.toISOString()),
        'updated_date': value.updated_date === undefined ? undefined : (value.updated_date === null ? null : value.updated_date.toISOString()),
        'publish_state': value.publish_state,
        'publish_date': value.publish_date === undefined ? undefined : (value.publish_date === null ? null : value.publish_date.toISOString()),
    };
}


