/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsDesignElement,
    MelcoCoreModelsDesignElementFromJSON,
    MelcoCoreModelsDesignElementFromJSONTyped,
    MelcoCoreModelsDesignElementToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsDesignMetadata
 */
export interface MelcoCoreModelsDesignMetadata {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDesignMetadata
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsDesignMetadata
     */
    stitches?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsDesignMetadata
     */
    colors?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsDesignMetadata
     */
    trims?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsDesignMetadata
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsDesignMetadata
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsDesignMetadata
     */
    color_changes?: number;
    /**
     * 
     * @type {Array<MelcoCoreModelsDesignElement>}
     * @memberof MelcoCoreModelsDesignMetadata
     */
    elements?: Array<MelcoCoreModelsDesignElement> | null;
}

export function MelcoCoreModelsDesignMetadataFromJSON(json: any): MelcoCoreModelsDesignMetadata {
    return MelcoCoreModelsDesignMetadataFromJSONTyped(json, false);
}

export function MelcoCoreModelsDesignMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsDesignMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'stitches': !exists(json, 'stitches') ? undefined : json['stitches'],
        'colors': !exists(json, 'colors') ? undefined : json['colors'],
        'trims': !exists(json, 'trims') ? undefined : json['trims'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'color_changes': !exists(json, 'color_changes') ? undefined : json['color_changes'],
        'elements': !exists(json, 'elements') ? undefined : (json['elements'] === null ? null : (json['elements'] as Array<any>).map(MelcoCoreModelsDesignElementFromJSON)),
    };
}

export function MelcoCoreModelsDesignMetadataToJSON(value?: MelcoCoreModelsDesignMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'stitches': value.stitches,
        'colors': value.colors,
        'trims': value.trims,
        'height': value.height,
        'width': value.width,
        'color_changes': value.color_changes,
        'elements': value.elements === undefined ? undefined : (value.elements === null ? null : (value.elements as Array<any>).map(MelcoCoreModelsDesignElementToJSON)),
    };
}


