/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsEfsMappingCondition,
    MelcoCoreModelsEfsMappingConditionFromJSON,
    MelcoCoreModelsEfsMappingConditionFromJSONTyped,
    MelcoCoreModelsEfsMappingConditionToJSON,
    MelcoCoreModelsEfsMappingFileAttribute,
    MelcoCoreModelsEfsMappingFileAttributeFromJSON,
    MelcoCoreModelsEfsMappingFileAttributeFromJSONTyped,
    MelcoCoreModelsEfsMappingFileAttributeToJSON,
    MelcoCoreModelsEfsMappingGroup,
    MelcoCoreModelsEfsMappingGroupFromJSON,
    MelcoCoreModelsEfsMappingGroupFromJSONTyped,
    MelcoCoreModelsEfsMappingGroupToJSON,
    MelcoCoreModelsEfsMappingOutput,
    MelcoCoreModelsEfsMappingOutputFromJSON,
    MelcoCoreModelsEfsMappingOutputFromJSONTyped,
    MelcoCoreModelsEfsMappingOutputToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsEfsCreateMapping
 */
export interface MelcoCoreModelsEfsCreateMapping {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsCreateMapping
     */
    format_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsCreateMapping
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsEfsCreateMapping
     */
    global?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsCreateMapping
     */
    config_type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsCreateMapping
     */
    service_type?: string | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsEfsMappingCondition>}
     * @memberof MelcoCoreModelsEfsCreateMapping
     */
    conditions?: Array<MelcoCoreModelsEfsMappingCondition> | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsEfsMappingOutput>}
     * @memberof MelcoCoreModelsEfsCreateMapping
     */
    outputs?: Array<MelcoCoreModelsEfsMappingOutput> | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsEfsMappingFileAttribute>}
     * @memberof MelcoCoreModelsEfsCreateMapping
     */
    file_name_configuration?: Array<MelcoCoreModelsEfsMappingFileAttribute> | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsEfsMappingGroup>}
     * @memberof MelcoCoreModelsEfsCreateMapping
     */
    folder_groups?: Array<MelcoCoreModelsEfsMappingGroup> | null;
}

export function MelcoCoreModelsEfsCreateMappingFromJSON(json: any): MelcoCoreModelsEfsCreateMapping {
    return MelcoCoreModelsEfsCreateMappingFromJSONTyped(json, false);
}

export function MelcoCoreModelsEfsCreateMappingFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsEfsCreateMapping {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'format_id': !exists(json, 'format_id') ? undefined : json['format_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'global': !exists(json, 'global') ? undefined : json['global'],
        'config_type': !exists(json, 'config_type') ? undefined : json['config_type'],
        'service_type': !exists(json, 'service_type') ? undefined : json['service_type'],
        'conditions': !exists(json, 'conditions') ? undefined : (json['conditions'] === null ? null : (json['conditions'] as Array<any>).map(MelcoCoreModelsEfsMappingConditionFromJSON)),
        'outputs': !exists(json, 'outputs') ? undefined : (json['outputs'] === null ? null : (json['outputs'] as Array<any>).map(MelcoCoreModelsEfsMappingOutputFromJSON)),
        'file_name_configuration': !exists(json, 'file_name_configuration') ? undefined : (json['file_name_configuration'] === null ? null : (json['file_name_configuration'] as Array<any>).map(MelcoCoreModelsEfsMappingFileAttributeFromJSON)),
        'folder_groups': !exists(json, 'folder_groups') ? undefined : (json['folder_groups'] === null ? null : (json['folder_groups'] as Array<any>).map(MelcoCoreModelsEfsMappingGroupFromJSON)),
    };
}

export function MelcoCoreModelsEfsCreateMappingToJSON(value?: MelcoCoreModelsEfsCreateMapping | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'format_id': value.format_id,
        'name': value.name,
        'global': value.global,
        'config_type': value.config_type,
        'service_type': value.service_type,
        'conditions': value.conditions === undefined ? undefined : (value.conditions === null ? null : (value.conditions as Array<any>).map(MelcoCoreModelsEfsMappingConditionToJSON)),
        'outputs': value.outputs === undefined ? undefined : (value.outputs === null ? null : (value.outputs as Array<any>).map(MelcoCoreModelsEfsMappingOutputToJSON)),
        'file_name_configuration': value.file_name_configuration === undefined ? undefined : (value.file_name_configuration === null ? null : (value.file_name_configuration as Array<any>).map(MelcoCoreModelsEfsMappingFileAttributeToJSON)),
        'folder_groups': value.folder_groups === undefined ? undefined : (value.folder_groups === null ? null : (value.folder_groups as Array<any>).map(MelcoCoreModelsEfsMappingGroupToJSON)),
    };
}


