import { useCallback, useEffect, useMemo } from "react";
import { useField, useFormikContext } from "formik";
import { ActiveColor } from "./useProductColor";
import { ActiveView } from "./useActiveView";
import { useProductColorIndexList } from "./useProductColorIndexList";
import { elementFormikPrefix } from "../helper/formikPrefixHelper";
import { MelcoCoreModelsColorCollection } from "melco-shared-logic/dist/api/models/MelcoCoreModelsColorCollection";

export const useElementColors = (
  activeView: ActiveView,
  elementIndex: number | undefined
) => {
  const allColorIndexes = useProductColorIndexList();

  const { setFieldValue } = useFormikContext();

  const formikPrefix = elementFormikPrefix(activeView, elementIndex ?? -1);

  const [{ value: defaultColorId }] = useField<string | undefined>(
    `${formikPrefix}.default_color_id`
  );

  const [{ value: colorCollection }] = useField<
    MelcoCoreModelsColorCollection | undefined
  >(`${formikPrefix}.color_collection`);

  const availableColors = useMemo(() => {
    return (colorCollection?.color_list ?? []).map((c) => {
      const { id, name, color } = c;

      return {
        id: id!,
        name: name ?? "",
        argb: color?.argb ?? "00000000",
      } as ActiveColor;
    });
  }, [colorCollection]);

  const activeColor = useMemo(() => {
    return availableColors.find(
      (c) => defaultColorId && c.id === defaultColorId
    );
  }, [availableColors, defaultColorId]);

  const setColor = useCallback(
    (color: ActiveColor | undefined) => {
      // update value for all colors
      // i am not happy with this, but the data model forces us to do so and assume that all colors have the same designs applied with the same element lists that need to be kept in sync
      for (const colorIndex of allColorIndexes) {
        setFieldValue(
          `${elementFormikPrefix(
            activeView,
            elementIndex ?? -1,
            colorIndex
          )}.default_color_id`,
          color?.id
        );
      }
    },
    [allColorIndexes, activeView, elementIndex, setFieldValue]
  );

  // select the first color by default, if no valid default color id is set
  useEffect(() => {
    if (!activeColor) {
      const firstColor = availableColors[0];

      if (firstColor) {
        setColor(firstColor);
      }
    }
  }, [activeColor, setColor, availableColors]);

  return [availableColors, activeColor, setColor] as const;
};
