/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsDesignElementColor
 */
export interface MelcoCoreModelsDesignElementColor {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDesignElementColor
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDesignElementColor
     */
    manufacturer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDesignElementColor
     */
    product_line?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDesignElementColor
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDesignElementColor
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDesignElementColor
     */
    rgb?: string | null;
}

export function MelcoCoreModelsDesignElementColorFromJSON(json: any): MelcoCoreModelsDesignElementColor {
    return MelcoCoreModelsDesignElementColorFromJSONTyped(json, false);
}

export function MelcoCoreModelsDesignElementColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsDesignElementColor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'manufacturer': !exists(json, 'manufacturer') ? undefined : json['manufacturer'],
        'product_line': !exists(json, 'product_line') ? undefined : json['product_line'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'rgb': !exists(json, 'rgb') ? undefined : json['rgb'],
    };
}

export function MelcoCoreModelsDesignElementColorToJSON(value?: MelcoCoreModelsDesignElementColor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'manufacturer': value.manufacturer,
        'product_line': value.product_line,
        'name': value.name,
        'code': value.code,
        'rgb': value.rgb,
    };
}


