/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsCustomWarning,
    MelcoCoreModelsCustomWarningFromJSON,
    MelcoCoreModelsCustomWarningFromJSONTyped,
    MelcoCoreModelsCustomWarningToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsSupplier
 */
export interface MelcoCoreModelsSupplier {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsSupplier
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsSupplier
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsSupplier
     */
    supplier_type?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsSupplier
     */
    publish_state: number;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsSupplier
     */
    created_date: Date;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsSupplier
     */
    publish_date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsSupplier
     */
    updated_date?: Date | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsCustomWarning>}
     * @memberof MelcoCoreModelsSupplier
     */
    warning_list?: Array<MelcoCoreModelsCustomWarning> | null;
}

export function MelcoCoreModelsSupplierFromJSON(json: any): MelcoCoreModelsSupplier {
    return MelcoCoreModelsSupplierFromJSONTyped(json, false);
}

export function MelcoCoreModelsSupplierFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsSupplier {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'supplier_type': !exists(json, 'supplier_type') ? undefined : json['supplier_type'],
        'publish_state': json['publish_state'],
        'created_date': (new Date(json['created_date'])),
        'publish_date': !exists(json, 'publish_date') ? undefined : (json['publish_date'] === null ? null : new Date(json['publish_date'])),
        'updated_date': !exists(json, 'updated_date') ? undefined : (json['updated_date'] === null ? null : new Date(json['updated_date'])),
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(MelcoCoreModelsCustomWarningFromJSON)),
    };
}

export function MelcoCoreModelsSupplierToJSON(value?: MelcoCoreModelsSupplier | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'supplier_type': value.supplier_type,
        'publish_state': value.publish_state,
        'created_date': (value.created_date.toISOString()),
        'publish_date': value.publish_date === undefined ? undefined : (value.publish_date === null ? null : value.publish_date.toISOString()),
        'updated_date': value.updated_date === undefined ? undefined : (value.updated_date === null ? null : value.updated_date.toISOString()),
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(MelcoCoreModelsCustomWarningToJSON)),
    };
}


