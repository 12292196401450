/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsIdentityDevice,
    MelcoCoreModelsIdentityDeviceFromJSON,
    MelcoCoreModelsIdentityDeviceFromJSONTyped,
    MelcoCoreModelsIdentityDeviceToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsSecurityApiKeyAuth
 */
export interface MelcoCoreModelsSecurityApiKeyAuth {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsSecurityApiKeyAuth
     */
    api_key: string;
    /**
     * 
     * @type {MelcoCoreModelsIdentityDevice}
     * @memberof MelcoCoreModelsSecurityApiKeyAuth
     */
    device_info: MelcoCoreModelsIdentityDevice;
}

export function MelcoCoreModelsSecurityApiKeyAuthFromJSON(json: any): MelcoCoreModelsSecurityApiKeyAuth {
    return MelcoCoreModelsSecurityApiKeyAuthFromJSONTyped(json, false);
}

export function MelcoCoreModelsSecurityApiKeyAuthFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsSecurityApiKeyAuth {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'api_key': json['api_key'],
        'device_info': MelcoCoreModelsIdentityDeviceFromJSON(json['device_info']),
    };
}

export function MelcoCoreModelsSecurityApiKeyAuthToJSON(value?: MelcoCoreModelsSecurityApiKeyAuth | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'api_key': value.api_key,
        'device_info': MelcoCoreModelsIdentityDeviceToJSON(value.device_info),
    };
}


