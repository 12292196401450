/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsReferences
 */
export interface MelcoCoreModelsReferences {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsReferences
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsReferences
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsReferences
     */
    parent_id: string;
}

export function MelcoCoreModelsReferencesFromJSON(json: any): MelcoCoreModelsReferences {
    return MelcoCoreModelsReferencesFromJSONTyped(json, false);
}

export function MelcoCoreModelsReferencesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsReferences {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'parent_id': json['parent_id'],
    };
}

export function MelcoCoreModelsReferencesToJSON(value?: MelcoCoreModelsReferences | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'parent_id': value.parent_id,
    };
}


