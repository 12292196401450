import { useUnauthenticatedAPIQuery } from "melco-shared-logic/dist/hooks/api/useAPIQuery";
import { ConfiguratorApi } from "melco-shared-logic/dist/api/apis/ConfiguratorApi";
import { useAPIConfigOptions } from "./useAPIConfig";

export const useProduct = (token: string, apiKey: string) => {
  const apiConfigOptions = useAPIConfigOptions(apiKey);

  const { data, isLoading } = useUnauthenticatedAPIQuery(
    ConfiguratorApi,
    ["product", token],
    async (api) => {
      const response = await api.configuratorSessionsProductsGetProduct({
        token,
      });

      return response;
    },
    {
      apiConfigOptions,
    }
  );

  return [data, isLoading] as const;
};
