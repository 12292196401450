/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsSessionPreview,
    MelcoCoreModelsSessionPreviewFromJSON,
    MelcoCoreModelsSessionPreviewFromJSONTyped,
    MelcoCoreModelsSessionPreviewToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsSessionListItem
 */
export interface MelcoCoreModelsSessionListItem {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsSessionListItem
     */
    session_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsSessionListItem
     */
    product_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsSessionListItem
     */
    product_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsSessionListItem
     */
    product_token?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsSessionListItem
     */
    blank_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsSessionListItem
     */
    blank_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsSessionListItem
     */
    color_name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsSessionListItem
     */
    session_status?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsSessionListItem
     */
    production_status?: number;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsSessionListItem
     */
    start_timestamp?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsSessionListItem
     */
    end_timestamp?: Date | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsSessionPreview>}
     * @memberof MelcoCoreModelsSessionListItem
     */
    previews?: Array<MelcoCoreModelsSessionPreview> | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsSessionListItem
     */
    total?: number;
}

export function MelcoCoreModelsSessionListItemFromJSON(json: any): MelcoCoreModelsSessionListItem {
    return MelcoCoreModelsSessionListItemFromJSONTyped(json, false);
}

export function MelcoCoreModelsSessionListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsSessionListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'session_id': !exists(json, 'session_id') ? undefined : json['session_id'],
        'product_id': !exists(json, 'product_id') ? undefined : json['product_id'],
        'product_name': !exists(json, 'product_name') ? undefined : json['product_name'],
        'product_token': !exists(json, 'product_token') ? undefined : json['product_token'],
        'blank_id': !exists(json, 'blank_id') ? undefined : json['blank_id'],
        'blank_name': !exists(json, 'blank_name') ? undefined : json['blank_name'],
        'color_name': !exists(json, 'color_name') ? undefined : json['color_name'],
        'session_status': !exists(json, 'session_status') ? undefined : json['session_status'],
        'production_status': !exists(json, 'production_status') ? undefined : json['production_status'],
        'start_timestamp': !exists(json, 'start_timestamp') ? undefined : (json['start_timestamp'] === null ? null : new Date(json['start_timestamp'])),
        'end_timestamp': !exists(json, 'end_timestamp') ? undefined : (json['end_timestamp'] === null ? null : new Date(json['end_timestamp'])),
        'previews': !exists(json, 'previews') ? undefined : (json['previews'] === null ? null : (json['previews'] as Array<any>).map(MelcoCoreModelsSessionPreviewFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function MelcoCoreModelsSessionListItemToJSON(value?: MelcoCoreModelsSessionListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'session_id': value.session_id,
        'product_id': value.product_id,
        'product_name': value.product_name,
        'product_token': value.product_token,
        'blank_id': value.blank_id,
        'blank_name': value.blank_name,
        'color_name': value.color_name,
        'session_status': value.session_status,
        'production_status': value.production_status,
        'start_timestamp': value.start_timestamp === undefined ? undefined : (value.start_timestamp === null ? null : value.start_timestamp.toISOString()),
        'end_timestamp': value.end_timestamp === undefined ? undefined : (value.end_timestamp === null ? null : value.end_timestamp.toISOString()),
        'previews': value.previews === undefined ? undefined : (value.previews === null ? null : (value.previews as Array<any>).map(MelcoCoreModelsSessionPreviewToJSON)),
        'total': value.total,
    };
}


