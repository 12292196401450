/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MelcoCoreConstantsFulfillmentClientRunStatus {
    InProgress = 'InProgress',
    Cancelled = 'Cancelled',
    Completed = 'Completed'
}

export function MelcoCoreConstantsFulfillmentClientRunStatusFromJSON(json: any): MelcoCoreConstantsFulfillmentClientRunStatus {
    return MelcoCoreConstantsFulfillmentClientRunStatusFromJSONTyped(json, false);
}

export function MelcoCoreConstantsFulfillmentClientRunStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreConstantsFulfillmentClientRunStatus {
    return json as MelcoCoreConstantsFulfillmentClientRunStatus;
}

export function MelcoCoreConstantsFulfillmentClientRunStatusToJSON(value?: MelcoCoreConstantsFulfillmentClientRunStatus | null): any {
    return value as any;
}

