/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoCoreModelsError,
    MelcoCoreModelsErrorFromJSON,
    MelcoCoreModelsErrorToJSON,
    MelcoCoreModelsForwardLinkRequest,
    MelcoCoreModelsForwardLinkRequestFromJSON,
    MelcoCoreModelsForwardLinkRequestToJSON,
    MelcoCoreModelsForwardLinkResponse,
    MelcoCoreModelsForwardLinkResponseFromJSON,
    MelcoCoreModelsForwardLinkResponseToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsForwardLinkListItem,
    MelcoCoreModelsGenericList1MelcoCoreModelsForwardLinkListItemFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsForwardLinkListItemToJSON,
} from '../models';

export interface LinkForwardingApiForwardsCreateRequest {
    melcoCoreModelsForwardLinkRequest?: MelcoCoreModelsForwardLinkRequest;
}

export interface LinkForwardingApiForwardsDeleteRequest {
    id: string;
}

export interface LinkForwardingApiForwardsEditRequest {
    id: string;
    melcoCoreModelsForwardLinkRequest?: MelcoCoreModelsForwardLinkRequest;
}

export interface LinkForwardingApiForwardsGetRequest {
    id: string;
}

export interface LinkForwardingApiForwardsGetByKeyRequest {
    key: string;
}

export interface LinkForwardingApiForwardsGetListRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
}

/**
 * 
 */
export class LinkForwardingApi extends runtime.BaseAPI {

    /**
     * create new link
     * create new forward link
     */
    async forwardsCreateRaw(requestParameters: LinkForwardingApiForwardsCreateRequest): Promise<runtime.ApiResponse<MelcoCoreModelsForwardLinkResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/url/forwards`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsForwardLinkRequestToJSON(requestParameters.melcoCoreModelsForwardLinkRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsForwardLinkResponseFromJSON(jsonValue));
    }

    /**
     * create new link
     * create new forward link
     */
    async forwardsCreate(requestParameters: LinkForwardingApiForwardsCreateRequest): Promise<MelcoCoreModelsForwardLinkResponse> {
        const response = await this.forwardsCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete link
     */
    async forwardsDeleteRaw(requestParameters: LinkForwardingApiForwardsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling forwardsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/url/forwards/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete link
     */
    async forwardsDelete(requestParameters: LinkForwardingApiForwardsDeleteRequest): Promise<void> {
        await this.forwardsDeleteRaw(requestParameters);
    }

    /**
     * update existing link (updates url only)
     * update existing link
     */
    async forwardsEditRaw(requestParameters: LinkForwardingApiForwardsEditRequest): Promise<runtime.ApiResponse<MelcoCoreModelsForwardLinkResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling forwardsEdit.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/url/forwards/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsForwardLinkRequestToJSON(requestParameters.melcoCoreModelsForwardLinkRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsForwardLinkResponseFromJSON(jsonValue));
    }

    /**
     * update existing link (updates url only)
     * update existing link
     */
    async forwardsEdit(requestParameters: LinkForwardingApiForwardsEditRequest): Promise<MelcoCoreModelsForwardLinkResponse> {
        const response = await this.forwardsEditRaw(requestParameters);
        return await response.value();
    }

    /**
     * get link
     * get link
     */
    async forwardsGetRaw(requestParameters: LinkForwardingApiForwardsGetRequest): Promise<runtime.ApiResponse<MelcoCoreModelsForwardLinkResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling forwardsGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/url/forwards/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsForwardLinkResponseFromJSON(jsonValue));
    }

    /**
     * get link
     * get link
     */
    async forwardsGet(requestParameters: LinkForwardingApiForwardsGetRequest): Promise<MelcoCoreModelsForwardLinkResponse> {
        const response = await this.forwardsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * get link
     * get link
     */
    async forwardsGetByKeyRaw(requestParameters: LinkForwardingApiForwardsGetByKeyRequest): Promise<runtime.ApiResponse<MelcoCoreModelsForwardLinkResponse>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling forwardsGetByKey.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/url/forwards/key/{Key}`.replace(`{${"Key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsForwardLinkResponseFromJSON(jsonValue));
    }

    /**
     * get link
     * get link
     */
    async forwardsGetByKey(requestParameters: LinkForwardingApiForwardsGetByKeyRequest): Promise<MelcoCoreModelsForwardLinkResponse> {
        const response = await this.forwardsGetByKeyRaw(requestParameters);
        return await response.value();
    }

    /**
     * get list of links
     * get list of links
     */
    async forwardsGetListRaw(requestParameters: LinkForwardingApiForwardsGetListRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsForwardLinkListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/url/forwards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsForwardLinkListItemFromJSON(jsonValue));
    }

    /**
     * get list of links
     * get list of links
     */
    async forwardsGetList(requestParameters: LinkForwardingApiForwardsGetListRequest): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsForwardLinkListItem> {
        const response = await this.forwardsGetListRaw(requestParameters);
        return await response.value();
    }

}
