import { MelcoCoreModelsSession } from "melco-shared-logic/dist/api/models/MelcoCoreModelsSession";
import { useCallback, useEffect, useState } from "react";
import { useConfigurator } from "./useConfigurator";

export const useGetOrStartSession = (
  apiKey: string,
  token: string,
  sessionId?: string,
  isViewModeEnabled?: boolean
) => {
  const {
    isLoading,
    error,
    mutateAsync: getOrStartSessionAPICall,
  } = useConfigurator(apiKey);

  const [session, setSession] = useState<MelcoCoreModelsSession | undefined>();

  const getOrStartSession = useCallback(async () => {
    try {
      const session = await getOrStartSessionAPICall({
        token,
        sessionId,
        getExistingSession: Boolean(isViewModeEnabled && sessionId),
      });

      setSession(session);
    } catch (e) {
      console.error(e);
    }
  }, [token, sessionId, isViewModeEnabled]);

  useEffect(() => {
    getOrStartSession();
  }, [token, sessionId, isViewModeEnabled]);

  return {
    session,
    isLoading,
    error,
    onRestart: getOrStartSession,
  };
};
