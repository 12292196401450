/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoAnalyticsModelsAvailabilityStatusListItem
 */
export interface MelcoAnalyticsModelsAvailabilityStatusListItem {
    /**
     * 
     * @type {string}
     * @memberof MelcoAnalyticsModelsAvailabilityStatusListItem
     */
    app_id?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoAnalyticsModelsAvailabilityStatusListItem
     */
    time_stamp?: Date;
    /**
     * 
     * @type {number}
     * @memberof MelcoAnalyticsModelsAvailabilityStatusListItem
     */
    passed?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoAnalyticsModelsAvailabilityStatusListItem
     */
    failed?: number;
}

export function MelcoAnalyticsModelsAvailabilityStatusListItemFromJSON(json: any): MelcoAnalyticsModelsAvailabilityStatusListItem {
    return MelcoAnalyticsModelsAvailabilityStatusListItemFromJSONTyped(json, false);
}

export function MelcoAnalyticsModelsAvailabilityStatusListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoAnalyticsModelsAvailabilityStatusListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'app_id': !exists(json, 'app_id') ? undefined : json['app_id'],
        'time_stamp': !exists(json, 'time_stamp') ? undefined : (new Date(json['time_stamp'])),
        'passed': !exists(json, 'passed') ? undefined : json['passed'],
        'failed': !exists(json, 'failed') ? undefined : json['failed'],
    };
}

export function MelcoAnalyticsModelsAvailabilityStatusListItemToJSON(value?: MelcoAnalyticsModelsAvailabilityStatusListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'app_id': value.app_id,
        'time_stamp': value.time_stamp === undefined ? undefined : (value.time_stamp.toISOString()),
        'passed': value.passed,
        'failed': value.failed,
    };
}


