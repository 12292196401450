/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsReferences,
    MelcoCoreModelsReferencesFromJSON,
    MelcoCoreModelsReferencesFromJSONTyped,
    MelcoCoreModelsReferencesToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsBlankReferences
 */
export interface MelcoCoreModelsBlankReferences {
    /**
     * 
     * @type {Array<MelcoCoreModelsReferences>}
     * @memberof MelcoCoreModelsBlankReferences
     */
    custom_product_list?: Array<MelcoCoreModelsReferences> | null;
}

export function MelcoCoreModelsBlankReferencesFromJSON(json: any): MelcoCoreModelsBlankReferences {
    return MelcoCoreModelsBlankReferencesFromJSONTyped(json, false);
}

export function MelcoCoreModelsBlankReferencesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsBlankReferences {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom_product_list': !exists(json, 'custom_product_list') ? undefined : (json['custom_product_list'] === null ? null : (json['custom_product_list'] as Array<any>).map(MelcoCoreModelsReferencesFromJSON)),
    };
}

export function MelcoCoreModelsBlankReferencesToJSON(value?: MelcoCoreModelsBlankReferences | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom_product_list': value.custom_product_list === undefined ? undefined : (value.custom_product_list === null ? null : (value.custom_product_list as Array<any>).map(MelcoCoreModelsReferencesToJSON)),
    };
}


