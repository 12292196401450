import classNames from "classnames";
import { MelcoCoreModelsDesign } from "melco-shared-logic/dist/api/models/MelcoCoreModelsDesign";
import { selectThumbnailForSize } from "melco-shared-logic/dist/helper/thumbnails/thumbnailHelper";

type SelectableDesignProps = {
  design: MelcoCoreModelsDesign;
  isSelected: boolean;
  onSelection?: () => void;
};

export const SelectableDesign: React.FC<SelectableDesignProps> = ({
  design,
  isSelected,
  onSelection,
}) => {
  const { name, previews } = design;

  const designPreviewThumbnailUrl =
    selectThumbnailForSize(previews ?? [], 200)?.url ?? "";

  const imagePreview = <img src={designPreviewThumbnailUrl} alt={name ?? ""} />;

  return (
    <li
      className={classNames("mfc__design-collection-design", {
        "mfc__design-collection-design-selected": isSelected,
      })}
    >
      {onSelection ? (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            onSelection();
          }}
          title={name ?? ""}
        >
          {imagePreview}
        </a>
      ) : (
        imagePreview
      )}
    </li>
  );
};
