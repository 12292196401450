/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoCoreModelsApiKey,
    MelcoCoreModelsApiKeyFromJSON,
    MelcoCoreModelsApiKeyToJSON,
    MelcoCoreModelsBoolResponse,
    MelcoCoreModelsBoolResponseFromJSON,
    MelcoCoreModelsBoolResponseToJSON,
    MelcoCoreModelsCreateApiKey,
    MelcoCoreModelsCreateApiKeyFromJSON,
    MelcoCoreModelsCreateApiKeyToJSON,
    MelcoCoreModelsCreateSupplier,
    MelcoCoreModelsCreateSupplierFromJSON,
    MelcoCoreModelsCreateSupplierToJSON,
    MelcoCoreModelsCreateSupplierProduct,
    MelcoCoreModelsCreateSupplierProductFromJSON,
    MelcoCoreModelsCreateSupplierProductToJSON,
    MelcoCoreModelsDRMBridgeSerialNumber,
    MelcoCoreModelsDRMBridgeSerialNumberFromJSON,
    MelcoCoreModelsDRMBridgeSerialNumberToJSON,
    MelcoCoreModelsDRMChangeLicenseLevel,
    MelcoCoreModelsDRMChangeLicenseLevelFromJSON,
    MelcoCoreModelsDRMChangeLicenseLevelToJSON,
    MelcoCoreModelsDRMLegacySerialNumber,
    MelcoCoreModelsDRMLegacySerialNumberFromJSON,
    MelcoCoreModelsDRMLegacySerialNumberToJSON,
    MelcoCoreModelsDRMLicenseCreateResponse,
    MelcoCoreModelsDRMLicenseCreateResponseFromJSON,
    MelcoCoreModelsDRMLicenseCreateResponseToJSON,
    MelcoCoreModelsDRMTransferSerialNumber,
    MelcoCoreModelsDRMTransferSerialNumberFromJSON,
    MelcoCoreModelsDRMTransferSerialNumberToJSON,
    MelcoCoreModelsDRMUpgradeLicense,
    MelcoCoreModelsDRMUpgradeLicenseFromJSON,
    MelcoCoreModelsDRMUpgradeLicenseToJSON,
    MelcoCoreModelsDRMUserLicense,
    MelcoCoreModelsDRMUserLicenseFromJSON,
    MelcoCoreModelsDRMUserLicenseToJSON,
    MelcoCoreModelsDRMUserSerialNumber,
    MelcoCoreModelsDRMUserSerialNumberFromJSON,
    MelcoCoreModelsDRMUserSerialNumberToJSON,
    MelcoCoreModelsDRMUserSerialNumberResponse,
    MelcoCoreModelsDRMUserSerialNumberResponseFromJSON,
    MelcoCoreModelsDRMUserSerialNumberResponseToJSON,
    MelcoCoreModelsEmail,
    MelcoCoreModelsEmailFromJSON,
    MelcoCoreModelsEmailToJSON,
    MelcoCoreModelsError,
    MelcoCoreModelsErrorFromJSON,
    MelcoCoreModelsErrorToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsApiKey,
    MelcoCoreModelsGenericList1MelcoCoreModelsApiKeyFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsApiKeyToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsCategories,
    MelcoCoreModelsGenericList1MelcoCoreModelsCategoriesFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsCategoriesToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsDRMSerialNumberHistory,
    MelcoCoreModelsGenericList1MelcoCoreModelsDRMSerialNumberHistoryFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsDRMSerialNumberHistoryToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsEmailListItem,
    MelcoCoreModelsGenericList1MelcoCoreModelsEmailListItemFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsEmailListItemToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsHoopListItem,
    MelcoCoreModelsGenericList1MelcoCoreModelsHoopListItemFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsHoopListItemToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsLocaleModule,
    MelcoCoreModelsGenericList1MelcoCoreModelsLocaleModuleFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsLocaleModuleToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsLocaleString,
    MelcoCoreModelsGenericList1MelcoCoreModelsLocaleStringFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsLocaleStringToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsLocaleStringListItem,
    MelcoCoreModelsGenericList1MelcoCoreModelsLocaleStringListItemFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsLocaleStringListItemToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsSupplier,
    MelcoCoreModelsGenericList1MelcoCoreModelsSupplierFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsSupplierToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsSupplierProduct,
    MelcoCoreModelsGenericList1MelcoCoreModelsSupplierProductFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsSupplierProductToJSON,
    MelcoCoreModelsHoop,
    MelcoCoreModelsHoopFromJSON,
    MelcoCoreModelsHoopToJSON,
    MelcoCoreModelsHoopsCreateHoop,
    MelcoCoreModelsHoopsCreateHoopFromJSON,
    MelcoCoreModelsHoopsCreateHoopToJSON,
    MelcoCoreModelsLocaleString,
    MelcoCoreModelsLocaleStringFromJSON,
    MelcoCoreModelsLocaleStringToJSON,
    MelcoCoreModelsSupplier,
    MelcoCoreModelsSupplierFromJSON,
    MelcoCoreModelsSupplierToJSON,
    MelcoCoreModelsSupplierProduct,
    MelcoCoreModelsSupplierProductFromJSON,
    MelcoCoreModelsSupplierProductToJSON,
    MelcoCoreModelsUpdateSupplier,
    MelcoCoreModelsUpdateSupplierFromJSON,
    MelcoCoreModelsUpdateSupplierToJSON,
    MelcoCoreModelsUpdateSupplierProduct,
    MelcoCoreModelsUpdateSupplierProductFromJSON,
    MelcoCoreModelsUpdateSupplierProductToJSON,
    MelcoCoreModelsUserProductPermissions,
    MelcoCoreModelsUserProductPermissionsFromJSON,
    MelcoCoreModelsUserProductPermissionsToJSON,
} from '../models';

export interface ControlCenterApiAdminApiKeysCreateRequest {
    melcoCoreModelsCreateApiKey?: MelcoCoreModelsCreateApiKey;
}

export interface ControlCenterApiAdminApiKeysDeleteRequest {
    id: string;
}

export interface ControlCenterApiAdminApiKeysGetListRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    productCode?: string;
    searchText?: string;
}

export interface ControlCenterApiGetBySerialNumberRequest {
    serialNumber: string;
}

export interface ControlCenterApiGetHistoryBySerialNumberRequest {
    serialNumber: string;
}

export interface ControlCenterApiLicensesManagerRequest {
    melcoCoreModelsDRMUserLicense?: MelcoCoreModelsDRMUserLicense;
}

export interface ControlCenterApiLicensesManagerChangeEmailRequest {
    melcoCoreModelsDRMBridgeSerialNumber?: MelcoCoreModelsDRMBridgeSerialNumber;
}

export interface ControlCenterApiLicensesManagerUpdateRequest {
    melcoCoreModelsDRMChangeLicenseLevel?: MelcoCoreModelsDRMChangeLicenseLevel;
}

export interface ControlCenterApiLicensesManagerUpgradeRequest {
    melcoCoreModelsDRMUpgradeLicense?: MelcoCoreModelsDRMUpgradeLicense;
}

export interface ControlCenterApiManageCategoriesSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
    publishState?: string;
    categoryType?: string;
}

export interface ControlCenterApiManageGetEmailRequest {
    id: string;
}

export interface ControlCenterApiManageHoopsCreateDraftRequest {
    melcoCoreModelsHoopsCreateHoop?: MelcoCoreModelsHoopsCreateHoop;
}

export interface ControlCenterApiManageHoopsDeleteRequest {
    id: string;
}

export interface ControlCenterApiManageHoopsDuplicateDraftRequest {
    id: string;
}

export interface ControlCenterApiManageHoopsGetDefRequest {
    id: string;
    publishState?: string;
}

export interface ControlCenterApiManageHoopsPublishRequest {
    id: string;
}

export interface ControlCenterApiManageHoopsRevertRequest {
    id: string;
}

export interface ControlCenterApiManageHoopsSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    publishState?: string;
    searchText?: string;
    name?: string;
    productList?: string;
    machineList?: string;
    categoryList?: string;
    version?: string;
}

export interface ControlCenterApiManageHoopsUpdateRequest {
    id: string;
    melcoCoreModelsHoopsCreateHoop?: MelcoCoreModelsHoopsCreateHoop;
}

export interface ControlCenterApiManageHoopsUploadRequest {
    id: string;
    file?: Blob;
}

export interface ControlCenterApiManageLocalizationEditRequest {
    id: string;
    moduleCode: string;
    melcoCoreModelsLocaleString?: MelcoCoreModelsLocaleString;
}

export interface ControlCenterApiManageLocalizationExportRequest {
    moduleCode: string;
    cultureCode?: string;
}

export interface ControlCenterApiManageLocalizationGetRequest {
    id: string;
    moduleCode: string;
}

export interface ControlCenterApiManageLocalizationGetListRequest {
    moduleCode: string;
    cultureCode?: string;
    searchText?: string;
    offset?: number;
    limit?: number;
    sortBy?: string;
}

export interface ControlCenterApiManageLocalizationImportRequest {
    moduleCode: string;
    cultureCode?: string;
    file?: Blob;
}

export interface ControlCenterApiManageSearchEmailsRequest {
    searchText?: string;
    offset?: number;
    limit?: number;
    sortBy?: string;
    emailAddress?: string;
    productCodeList?: string;
    startTimestamp?: Date;
    endTimestamp?: Date;
}

export interface ControlCenterApiManageSupplierDeleteRequest {
    id: string;
}

export interface ControlCenterApiManageSupplierDraftCreateRequest {
    melcoCoreModelsCreateSupplier?: MelcoCoreModelsCreateSupplier;
}

export interface ControlCenterApiManageSupplierDraftUpdateRequest {
    id: string;
    melcoCoreModelsUpdateSupplier?: MelcoCoreModelsUpdateSupplier;
}

export interface ControlCenterApiManageSupplierDuplicateDraftRequest {
    id: string;
}

export interface ControlCenterApiManageSupplierGetRequest {
    id: string;
    publishState?: string;
}

export interface ControlCenterApiManageSupplierProductDeleteRequest {
    id: string;
}

export interface ControlCenterApiManageSupplierProductDraftCreateRequest {
    melcoCoreModelsCreateSupplierProduct?: MelcoCoreModelsCreateSupplierProduct;
}

export interface ControlCenterApiManageSupplierProductDraftUpdateRequest {
    id: string;
    melcoCoreModelsUpdateSupplierProduct?: MelcoCoreModelsUpdateSupplierProduct;
}

export interface ControlCenterApiManageSupplierProductDuplicateDraftRequest {
    id: string;
}

export interface ControlCenterApiManageSupplierProductPublishRequest {
    id: string;
}

export interface ControlCenterApiManageSupplierProductRevertRequest {
    id: string;
}

export interface ControlCenterApiManageSupplierProductsGetRequest {
    id: string;
    publishState?: string;
}

export interface ControlCenterApiManageSupplierProductsSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
    name?: string;
    supplierId?: string;
    supplierName?: string;
    publishState?: string;
    supplierType?: string;
}

export interface ControlCenterApiManageSupplierPublishRequest {
    id: string;
}

export interface ControlCenterApiManageSupplierRevertRequest {
    id: string;
}

export interface ControlCenterApiManageSupplierSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
    name?: string;
    publishState?: string;
    supplierType?: string;
}

export interface ControlCenterApiResendEmailSerialNumberRequest {
    serialNumber: string;
}

export interface ControlCenterApiTransferSerialNumberRequest {
    melcoCoreModelsDRMTransferSerialNumber?: MelcoCoreModelsDRMTransferSerialNumber;
}

export interface ControlCenterApiUserAccountEditPermissionRequest {
    userId: string;
    melcoCoreModelsUserProductPermissions?: MelcoCoreModelsUserProductPermissions;
}

export interface ControlCenterApiUserAccountGetPermissionRequest {
    userId: string;
}

/**
 * 
 */
export class ControlCenterApi extends runtime.BaseAPI {

    /**
     * Create New Api Key
     */
    async adminApiKeysCreateRaw(requestParameters: ControlCenterApiAdminApiKeysCreateRequest): Promise<runtime.ApiResponse<MelcoCoreModelsApiKey>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/admin/apikeys`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsCreateApiKeyToJSON(requestParameters.melcoCoreModelsCreateApiKey),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsApiKeyFromJSON(jsonValue));
    }

    /**
     * Create New Api Key
     */
    async adminApiKeysCreate(requestParameters: ControlCenterApiAdminApiKeysCreateRequest): Promise<MelcoCoreModelsApiKey> {
        const response = await this.adminApiKeysCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete Api Key
     */
    async adminApiKeysDeleteRaw(requestParameters: ControlCenterApiAdminApiKeysDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling adminApiKeysDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/apikeys/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Api Key
     */
    async adminApiKeysDelete(requestParameters: ControlCenterApiAdminApiKeysDeleteRequest): Promise<void> {
        await this.adminApiKeysDeleteRaw(requestParameters);
    }

    /**
     * Get ApiKey List
     */
    async adminApiKeysGetListRaw(requestParameters: ControlCenterApiAdminApiKeysGetListRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsApiKey>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.productCode !== undefined) {
            queryParameters['product_code'] = requestParameters.productCode;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/apikeys`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsApiKeyFromJSON(jsonValue));
    }

    /**
     * Get ApiKey List
     */
    async adminApiKeysGetList(requestParameters: ControlCenterApiAdminApiKeysGetListRequest): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsApiKey> {
        const response = await this.adminApiKeysGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     * get serial number
     */
    async getBySerialNumberRaw(requestParameters: ControlCenterApiGetBySerialNumberRequest): Promise<runtime.ApiResponse<MelcoCoreModelsDRMUserSerialNumber>> {
        if (requestParameters.serialNumber === null || requestParameters.serialNumber === undefined) {
            throw new runtime.RequiredError('serialNumber','Required parameter requestParameters.serialNumber was null or undefined when calling getBySerialNumber.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/products/licenses/{serial_number}`.replace(`{${"serial_number"}}`, encodeURIComponent(String(requestParameters.serialNumber))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsDRMUserSerialNumberFromJSON(jsonValue));
    }

    /**
     * get serial number
     */
    async getBySerialNumber(requestParameters: ControlCenterApiGetBySerialNumberRequest): Promise<MelcoCoreModelsDRMUserSerialNumber> {
        const response = await this.getBySerialNumberRaw(requestParameters);
        return await response.value();
    }

    /**
     * get history by serial number
     */
    async getHistoryBySerialNumberRaw(requestParameters: ControlCenterApiGetHistoryBySerialNumberRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsDRMSerialNumberHistory>> {
        if (requestParameters.serialNumber === null || requestParameters.serialNumber === undefined) {
            throw new runtime.RequiredError('serialNumber','Required parameter requestParameters.serialNumber was null or undefined when calling getHistoryBySerialNumber.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/products/licenses/{serial_number}/history`.replace(`{${"serial_number"}}`, encodeURIComponent(String(requestParameters.serialNumber))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsDRMSerialNumberHistoryFromJSON(jsonValue));
    }

    /**
     * get history by serial number
     */
    async getHistoryBySerialNumber(requestParameters: ControlCenterApiGetHistoryBySerialNumberRequest): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsDRMSerialNumberHistory> {
        const response = await this.getHistoryBySerialNumberRaw(requestParameters);
        return await response.value();
    }

    /**
     * create license
     */
    async licensesManagerRaw(requestParameters: ControlCenterApiLicensesManagerRequest): Promise<runtime.ApiResponse<MelcoCoreModelsDRMLicenseCreateResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/license/manager`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsDRMUserLicenseToJSON(requestParameters.melcoCoreModelsDRMUserLicense),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsDRMLicenseCreateResponseFromJSON(jsonValue));
    }

    /**
     * create license
     */
    async licensesManager(requestParameters: ControlCenterApiLicensesManagerRequest): Promise<MelcoCoreModelsDRMLicenseCreateResponse> {
        const response = await this.licensesManagerRaw(requestParameters);
        return await response.value();
    }

    /**
     * change email
     */
    async licensesManagerChangeEmailRaw(requestParameters: ControlCenterApiLicensesManagerChangeEmailRequest): Promise<runtime.ApiResponse<MelcoCoreModelsBoolResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/license/manager/change_email`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsDRMBridgeSerialNumberToJSON(requestParameters.melcoCoreModelsDRMBridgeSerialNumber),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * change email
     */
    async licensesManagerChangeEmail(requestParameters: ControlCenterApiLicensesManagerChangeEmailRequest): Promise<MelcoCoreModelsBoolResponse> {
        const response = await this.licensesManagerChangeEmailRaw(requestParameters);
        return await response.value();
    }

    /**
     * generate legacy license
     */
    async licensesManagerLegacyLicenseRaw(): Promise<runtime.ApiResponse<MelcoCoreModelsDRMLegacySerialNumber>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/license/manager/legacy_license`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsDRMLegacySerialNumberFromJSON(jsonValue));
    }

    /**
     * generate legacy license
     */
    async licensesManagerLegacyLicense(): Promise<MelcoCoreModelsDRMLegacySerialNumber> {
        const response = await this.licensesManagerLegacyLicenseRaw();
        return await response.value();
    }

    /**
     * change level for license
     */
    async licensesManagerUpdateRaw(requestParameters: ControlCenterApiLicensesManagerUpdateRequest): Promise<runtime.ApiResponse<MelcoCoreModelsDRMUserSerialNumberResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/license/manager/update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsDRMChangeLicenseLevelToJSON(requestParameters.melcoCoreModelsDRMChangeLicenseLevel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsDRMUserSerialNumberResponseFromJSON(jsonValue));
    }

    /**
     * change level for license
     */
    async licensesManagerUpdate(requestParameters: ControlCenterApiLicensesManagerUpdateRequest): Promise<MelcoCoreModelsDRMUserSerialNumberResponse> {
        const response = await this.licensesManagerUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * upgrade license
     */
    async licensesManagerUpgradeRaw(requestParameters: ControlCenterApiLicensesManagerUpgradeRequest): Promise<runtime.ApiResponse<MelcoCoreModelsDRMLicenseCreateResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/license/manager/upgrade`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsDRMUpgradeLicenseToJSON(requestParameters.melcoCoreModelsDRMUpgradeLicense),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsDRMLicenseCreateResponseFromJSON(jsonValue));
    }

    /**
     * upgrade license
     */
    async licensesManagerUpgrade(requestParameters: ControlCenterApiLicensesManagerUpgradeRequest): Promise<MelcoCoreModelsDRMLicenseCreateResponse> {
        const response = await this.licensesManagerUpgradeRaw(requestParameters);
        return await response.value();
    }

    /**
     * list of categories
     * get categories list
     */
    async manageCategoriesSearchRaw(requestParameters: ControlCenterApiManageCategoriesSearchRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsCategories>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        if (requestParameters.categoryType !== undefined) {
            queryParameters['category_type'] = requestParameters.categoryType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsCategoriesFromJSON(jsonValue));
    }

    /**
     * list of categories
     * get categories list
     */
    async manageCategoriesSearch(requestParameters: ControlCenterApiManageCategoriesSearchRequest): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsCategories> {
        const response = await this.manageCategoriesSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get email from cloud
     */
    async manageGetEmailRaw(requestParameters: ControlCenterApiManageGetEmailRequest): Promise<runtime.ApiResponse<MelcoCoreModelsEmail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageGetEmail.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/history/emails/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsEmailFromJSON(jsonValue));
    }

    /**
     * Get email from cloud
     */
    async manageGetEmail(requestParameters: ControlCenterApiManageGetEmailRequest): Promise<MelcoCoreModelsEmail> {
        const response = await this.manageGetEmailRaw(requestParameters);
        return await response.value();
    }

    /**
     * creates hoop
     * create hoop
     */
    async manageHoopsCreateDraftRaw(requestParameters: ControlCenterApiManageHoopsCreateDraftRequest): Promise<runtime.ApiResponse<MelcoCoreModelsHoop>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/hoops/draft`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsHoopsCreateHoopToJSON(requestParameters.melcoCoreModelsHoopsCreateHoop),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsHoopFromJSON(jsonValue));
    }

    /**
     * creates hoop
     * create hoop
     */
    async manageHoopsCreateDraft(requestParameters: ControlCenterApiManageHoopsCreateDraftRequest): Promise<MelcoCoreModelsHoop> {
        const response = await this.manageHoopsCreateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete hoop
     */
    async manageHoopsDeleteRaw(requestParameters: ControlCenterApiManageHoopsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageHoopsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/hoops/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete hoop
     */
    async manageHoopsDelete(requestParameters: ControlCenterApiManageHoopsDeleteRequest): Promise<void> {
        await this.manageHoopsDeleteRaw(requestParameters);
    }

    /**
     * duplicate hoop
     * duplicate hoop draft
     */
    async manageHoopsDuplicateDraftRaw(requestParameters: ControlCenterApiManageHoopsDuplicateDraftRequest): Promise<runtime.ApiResponse<MelcoCoreModelsHoop>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageHoopsDuplicateDraft.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/hoops/{Id}/duplicate/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsHoopFromJSON(jsonValue));
    }

    /**
     * duplicate hoop
     * duplicate hoop draft
     */
    async manageHoopsDuplicateDraft(requestParameters: ControlCenterApiManageHoopsDuplicateDraftRequest): Promise<MelcoCoreModelsHoop> {
        const response = await this.manageHoopsDuplicateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * get hoop
     * get hoop by Id
     */
    async manageHoopsGetDefRaw(requestParameters: ControlCenterApiManageHoopsGetDefRequest): Promise<runtime.ApiResponse<MelcoCoreModelsHoop>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageHoopsGetDef.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/hoops/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsHoopFromJSON(jsonValue));
    }

    /**
     * get hoop
     * get hoop by Id
     */
    async manageHoopsGetDef(requestParameters: ControlCenterApiManageHoopsGetDefRequest): Promise<MelcoCoreModelsHoop> {
        const response = await this.manageHoopsGetDefRaw(requestParameters);
        return await response.value();
    }

    /**
     * publish hoop
     * publish hoop
     */
    async manageHoopsPublishRaw(requestParameters: ControlCenterApiManageHoopsPublishRequest): Promise<runtime.ApiResponse<MelcoCoreModelsHoop>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageHoopsPublish.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/hoops/{Id}/publish`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsHoopFromJSON(jsonValue));
    }

    /**
     * publish hoop
     * publish hoop
     */
    async manageHoopsPublish(requestParameters: ControlCenterApiManageHoopsPublishRequest): Promise<MelcoCoreModelsHoop> {
        const response = await this.manageHoopsPublishRaw(requestParameters);
        return await response.value();
    }

    /**
     * revert unpublished changes
     */
    async manageHoopsRevertRaw(requestParameters: ControlCenterApiManageHoopsRevertRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageHoopsRevert.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/hoops/{Id}/revert`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * revert unpublished changes
     */
    async manageHoopsRevert(requestParameters: ControlCenterApiManageHoopsRevertRequest): Promise<void> {
        await this.manageHoopsRevertRaw(requestParameters);
    }

    /**
     * get hoop
     * get hoop list
     */
    async manageHoopsSearchRaw(requestParameters: ControlCenterApiManageHoopsSearchRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsHoopListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.productList !== undefined) {
            queryParameters['product_list'] = requestParameters.productList;
        }

        if (requestParameters.machineList !== undefined) {
            queryParameters['machine_list'] = requestParameters.machineList;
        }

        if (requestParameters.categoryList !== undefined) {
            queryParameters['category_list'] = requestParameters.categoryList;
        }

        if (requestParameters.version !== undefined) {
            queryParameters['version'] = requestParameters.version;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/hoops`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsHoopListItemFromJSON(jsonValue));
    }

    /**
     * get hoop
     * get hoop list
     */
    async manageHoopsSearch(requestParameters: ControlCenterApiManageHoopsSearchRequest): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsHoopListItem> {
        const response = await this.manageHoopsSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * update existing hoop draft
     */
    async manageHoopsUpdateRaw(requestParameters: ControlCenterApiManageHoopsUpdateRequest): Promise<runtime.ApiResponse<MelcoCoreModelsHoop>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageHoopsUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/hoops/{Id}/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsHoopsCreateHoopToJSON(requestParameters.melcoCoreModelsHoopsCreateHoop),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsHoopFromJSON(jsonValue));
    }

    /**
     * update existing hoop draft
     */
    async manageHoopsUpdate(requestParameters: ControlCenterApiManageHoopsUpdateRequest): Promise<MelcoCoreModelsHoop> {
        const response = await this.manageHoopsUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * upload hoop asset
     * upload hoop asset
     */
    async manageHoopsUploadRaw(requestParameters: ControlCenterApiManageHoopsUploadRequest): Promise<runtime.ApiResponse<MelcoCoreModelsHoop>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageHoopsUpload.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/manage/hoops/{Id}/draft/upload`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsHoopFromJSON(jsonValue));
    }

    /**
     * upload hoop asset
     * upload hoop asset
     */
    async manageHoopsUpload(requestParameters: ControlCenterApiManageHoopsUploadRequest): Promise<MelcoCoreModelsHoop> {
        const response = await this.manageHoopsUploadRaw(requestParameters);
        return await response.value();
    }

    /**
     * Edit locale string by id
     */
    async manageLocalizationEditRaw(requestParameters: ControlCenterApiManageLocalizationEditRequest): Promise<runtime.ApiResponse<MelcoCoreModelsLocaleString>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageLocalizationEdit.');
        }

        if (requestParameters.moduleCode === null || requestParameters.moduleCode === undefined) {
            throw new runtime.RequiredError('moduleCode','Required parameter requestParameters.moduleCode was null or undefined when calling manageLocalizationEdit.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/localization/{module_code}/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"module_code"}}`, encodeURIComponent(String(requestParameters.moduleCode))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsLocaleStringToJSON(requestParameters.melcoCoreModelsLocaleString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsLocaleStringFromJSON(jsonValue));
    }

    /**
     * Edit locale string by id
     */
    async manageLocalizationEdit(requestParameters: ControlCenterApiManageLocalizationEditRequest): Promise<MelcoCoreModelsLocaleString> {
        const response = await this.manageLocalizationEditRaw(requestParameters);
        return await response.value();
    }

    /**
     * export list of localization strings as csv
     */
    async manageLocalizationExportRaw(requestParameters: ControlCenterApiManageLocalizationExportRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.moduleCode === null || requestParameters.moduleCode === undefined) {
            throw new runtime.RequiredError('moduleCode','Required parameter requestParameters.moduleCode was null or undefined when calling manageLocalizationExport.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cultureCode !== undefined) {
            queryParameters['culture_code'] = requestParameters.cultureCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/localization/{module_code}/export`.replace(`{${"module_code"}}`, encodeURIComponent(String(requestParameters.moduleCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * export list of localization strings as csv
     */
    async manageLocalizationExport(requestParameters: ControlCenterApiManageLocalizationExportRequest): Promise<Blob> {
        const response = await this.manageLocalizationExportRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get string by id
     */
    async manageLocalizationGetRaw(requestParameters: ControlCenterApiManageLocalizationGetRequest): Promise<runtime.ApiResponse<MelcoCoreModelsLocaleString>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageLocalizationGet.');
        }

        if (requestParameters.moduleCode === null || requestParameters.moduleCode === undefined) {
            throw new runtime.RequiredError('moduleCode','Required parameter requestParameters.moduleCode was null or undefined when calling manageLocalizationGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/localization/{module_code}/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"module_code"}}`, encodeURIComponent(String(requestParameters.moduleCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsLocaleStringFromJSON(jsonValue));
    }

    /**
     * Get string by id
     */
    async manageLocalizationGet(requestParameters: ControlCenterApiManageLocalizationGetRequest): Promise<MelcoCoreModelsLocaleString> {
        const response = await this.manageLocalizationGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get list of strings
     */
    async manageLocalizationGetListRaw(requestParameters: ControlCenterApiManageLocalizationGetListRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsLocaleStringListItem>> {
        if (requestParameters.moduleCode === null || requestParameters.moduleCode === undefined) {
            throw new runtime.RequiredError('moduleCode','Required parameter requestParameters.moduleCode was null or undefined when calling manageLocalizationGetList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cultureCode !== undefined) {
            queryParameters['culture_code'] = requestParameters.cultureCode;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/localization/{module_code}`.replace(`{${"module_code"}}`, encodeURIComponent(String(requestParameters.moduleCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsLocaleStringListItemFromJSON(jsonValue));
    }

    /**
     * Get list of strings
     */
    async manageLocalizationGetList(requestParameters: ControlCenterApiManageLocalizationGetListRequest): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsLocaleStringListItem> {
        const response = await this.manageLocalizationGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     * get module list
     */
    async manageLocalizationGetModuleListRaw(): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsLocaleModule>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/localization/modules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsLocaleModuleFromJSON(jsonValue));
    }

    /**
     * get module list
     */
    async manageLocalizationGetModuleList(): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsLocaleModule> {
        const response = await this.manageLocalizationGetModuleListRaw();
        return await response.value();
    }

    /**
     * import list of localization strings
     */
    async manageLocalizationImportRaw(requestParameters: ControlCenterApiManageLocalizationImportRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsLocaleString>> {
        if (requestParameters.moduleCode === null || requestParameters.moduleCode === undefined) {
            throw new runtime.RequiredError('moduleCode','Required parameter requestParameters.moduleCode was null or undefined when calling manageLocalizationImport.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cultureCode !== undefined) {
            queryParameters['culture_code'] = requestParameters.cultureCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/manage/localization/{module_code}/import`.replace(`{${"module_code"}}`, encodeURIComponent(String(requestParameters.moduleCode))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsLocaleStringFromJSON(jsonValue));
    }

    /**
     * import list of localization strings
     */
    async manageLocalizationImport(requestParameters: ControlCenterApiManageLocalizationImportRequest): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsLocaleString> {
        const response = await this.manageLocalizationImportRaw(requestParameters);
        return await response.value();
    }

    /**
     * Search emails
     */
    async manageSearchEmailsRaw(requestParameters: ControlCenterApiManageSearchEmailsRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsEmailListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.emailAddress !== undefined) {
            queryParameters['email_address'] = requestParameters.emailAddress;
        }

        if (requestParameters.productCodeList !== undefined) {
            queryParameters['product_code_list'] = requestParameters.productCodeList;
        }

        if (requestParameters.startTimestamp !== undefined) {
            queryParameters['start_timestamp'] = (requestParameters.startTimestamp as any).toISOString();
        }

        if (requestParameters.endTimestamp !== undefined) {
            queryParameters['end_timestamp'] = (requestParameters.endTimestamp as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/history/emails`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsEmailListItemFromJSON(jsonValue));
    }

    /**
     * Search emails
     */
    async manageSearchEmails(requestParameters: ControlCenterApiManageSearchEmailsRequest): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsEmailListItem> {
        const response = await this.manageSearchEmailsRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete supplier
     */
    async manageSupplierDeleteRaw(requestParameters: ControlCenterApiManageSupplierDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageSupplierDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete supplier
     */
    async manageSupplierDelete(requestParameters: ControlCenterApiManageSupplierDeleteRequest): Promise<void> {
        await this.manageSupplierDeleteRaw(requestParameters);
    }

    /**
     * create supplier draft
     * Create new supplier draft
     */
    async manageSupplierDraftCreateRaw(requestParameters: ControlCenterApiManageSupplierDraftCreateRequest): Promise<runtime.ApiResponse<MelcoCoreModelsSupplier>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/suppliers/draft`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsCreateSupplierToJSON(requestParameters.melcoCoreModelsCreateSupplier),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsSupplierFromJSON(jsonValue));
    }

    /**
     * create supplier draft
     * Create new supplier draft
     */
    async manageSupplierDraftCreate(requestParameters: ControlCenterApiManageSupplierDraftCreateRequest): Promise<MelcoCoreModelsSupplier> {
        const response = await this.manageSupplierDraftCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * update existing supplier draft
     */
    async manageSupplierDraftUpdateRaw(requestParameters: ControlCenterApiManageSupplierDraftUpdateRequest): Promise<runtime.ApiResponse<MelcoCoreModelsSupplier>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageSupplierDraftUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/suppliers/{Id}/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsUpdateSupplierToJSON(requestParameters.melcoCoreModelsUpdateSupplier),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsSupplierFromJSON(jsonValue));
    }

    /**
     * update existing supplier draft
     */
    async manageSupplierDraftUpdate(requestParameters: ControlCenterApiManageSupplierDraftUpdateRequest): Promise<MelcoCoreModelsSupplier> {
        const response = await this.manageSupplierDraftUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * duplicate supplier
     * duplicate supplier draft
     */
    async manageSupplierDuplicateDraftRaw(requestParameters: ControlCenterApiManageSupplierDuplicateDraftRequest): Promise<runtime.ApiResponse<MelcoCoreModelsSupplier>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageSupplierDuplicateDraft.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/{Id}/duplicate/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsSupplierFromJSON(jsonValue));
    }

    /**
     * duplicate supplier
     * duplicate supplier draft
     */
    async manageSupplierDuplicateDraft(requestParameters: ControlCenterApiManageSupplierDuplicateDraftRequest): Promise<MelcoCoreModelsSupplier> {
        const response = await this.manageSupplierDuplicateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * get supplier
     * get supplier by Id
     */
    async manageSupplierGetRaw(requestParameters: ControlCenterApiManageSupplierGetRequest): Promise<runtime.ApiResponse<MelcoCoreModelsSupplier>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageSupplierGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsSupplierFromJSON(jsonValue));
    }

    /**
     * get supplier
     * get supplier by Id
     */
    async manageSupplierGet(requestParameters: ControlCenterApiManageSupplierGetRequest): Promise<MelcoCoreModelsSupplier> {
        const response = await this.manageSupplierGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete supplier product
     */
    async manageSupplierProductDeleteRaw(requestParameters: ControlCenterApiManageSupplierProductDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageSupplierProductDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/products/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete supplier product
     */
    async manageSupplierProductDelete(requestParameters: ControlCenterApiManageSupplierProductDeleteRequest): Promise<void> {
        await this.manageSupplierProductDeleteRaw(requestParameters);
    }

    /**
     * create supplier product draft
     * Create new supplier product draft
     */
    async manageSupplierProductDraftCreateRaw(requestParameters: ControlCenterApiManageSupplierProductDraftCreateRequest): Promise<runtime.ApiResponse<MelcoCoreModelsSupplierProduct>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/suppliers/products/draft`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsCreateSupplierProductToJSON(requestParameters.melcoCoreModelsCreateSupplierProduct),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsSupplierProductFromJSON(jsonValue));
    }

    /**
     * create supplier product draft
     * Create new supplier product draft
     */
    async manageSupplierProductDraftCreate(requestParameters: ControlCenterApiManageSupplierProductDraftCreateRequest): Promise<MelcoCoreModelsSupplierProduct> {
        const response = await this.manageSupplierProductDraftCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * update existing supplier product draft
     */
    async manageSupplierProductDraftUpdateRaw(requestParameters: ControlCenterApiManageSupplierProductDraftUpdateRequest): Promise<runtime.ApiResponse<MelcoCoreModelsSupplierProduct>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageSupplierProductDraftUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/suppliers/products/{Id}/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsUpdateSupplierProductToJSON(requestParameters.melcoCoreModelsUpdateSupplierProduct),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsSupplierProductFromJSON(jsonValue));
    }

    /**
     * update existing supplier product draft
     */
    async manageSupplierProductDraftUpdate(requestParameters: ControlCenterApiManageSupplierProductDraftUpdateRequest): Promise<MelcoCoreModelsSupplierProduct> {
        const response = await this.manageSupplierProductDraftUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * duplicate supplier product
     * duplicate supplier product draft
     */
    async manageSupplierProductDuplicateDraftRaw(requestParameters: ControlCenterApiManageSupplierProductDuplicateDraftRequest): Promise<runtime.ApiResponse<MelcoCoreModelsSupplierProduct>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageSupplierProductDuplicateDraft.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/products/{Id}/duplicate/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsSupplierProductFromJSON(jsonValue));
    }

    /**
     * duplicate supplier product
     * duplicate supplier product draft
     */
    async manageSupplierProductDuplicateDraft(requestParameters: ControlCenterApiManageSupplierProductDuplicateDraftRequest): Promise<MelcoCoreModelsSupplierProduct> {
        const response = await this.manageSupplierProductDuplicateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * publish supplier product
     * publish supplier product
     */
    async manageSupplierProductPublishRaw(requestParameters: ControlCenterApiManageSupplierProductPublishRequest): Promise<runtime.ApiResponse<MelcoCoreModelsSupplierProduct>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageSupplierProductPublish.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/products/{Id}/publish`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsSupplierProductFromJSON(jsonValue));
    }

    /**
     * publish supplier product
     * publish supplier product
     */
    async manageSupplierProductPublish(requestParameters: ControlCenterApiManageSupplierProductPublishRequest): Promise<MelcoCoreModelsSupplierProduct> {
        const response = await this.manageSupplierProductPublishRaw(requestParameters);
        return await response.value();
    }

    /**
     * revert unpublished changes
     */
    async manageSupplierProductRevertRaw(requestParameters: ControlCenterApiManageSupplierProductRevertRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageSupplierProductRevert.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/products/{Id}/revert`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * revert unpublished changes
     */
    async manageSupplierProductRevert(requestParameters: ControlCenterApiManageSupplierProductRevertRequest): Promise<void> {
        await this.manageSupplierProductRevertRaw(requestParameters);
    }

    /**
     * get supplier product
     * get supplier product by id
     */
    async manageSupplierProductsGetRaw(requestParameters: ControlCenterApiManageSupplierProductsGetRequest): Promise<runtime.ApiResponse<MelcoCoreModelsSupplierProduct>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageSupplierProductsGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/products/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsSupplierProductFromJSON(jsonValue));
    }

    /**
     * get supplier product
     * get supplier product by id
     */
    async manageSupplierProductsGet(requestParameters: ControlCenterApiManageSupplierProductsGetRequest): Promise<MelcoCoreModelsSupplierProduct> {
        const response = await this.manageSupplierProductsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * list of supported products
     * search supplier products
     */
    async manageSupplierProductsSearchRaw(requestParameters: ControlCenterApiManageSupplierProductsSearchRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsSupplierProduct>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.supplierId !== undefined) {
            queryParameters['supplier_id'] = requestParameters.supplierId;
        }

        if (requestParameters.supplierName !== undefined) {
            queryParameters['supplier_name'] = requestParameters.supplierName;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        if (requestParameters.supplierType !== undefined) {
            queryParameters['supplier_type'] = requestParameters.supplierType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsSupplierProductFromJSON(jsonValue));
    }

    /**
     * list of supported products
     * search supplier products
     */
    async manageSupplierProductsSearch(requestParameters: ControlCenterApiManageSupplierProductsSearchRequest): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsSupplierProduct> {
        const response = await this.manageSupplierProductsSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * publish supplier
     * publish supplier
     */
    async manageSupplierPublishRaw(requestParameters: ControlCenterApiManageSupplierPublishRequest): Promise<runtime.ApiResponse<MelcoCoreModelsSupplier>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageSupplierPublish.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/{Id}/publish`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsSupplierFromJSON(jsonValue));
    }

    /**
     * publish supplier
     * publish supplier
     */
    async manageSupplierPublish(requestParameters: ControlCenterApiManageSupplierPublishRequest): Promise<MelcoCoreModelsSupplier> {
        const response = await this.manageSupplierPublishRaw(requestParameters);
        return await response.value();
    }

    /**
     * revert unpublished changes
     */
    async manageSupplierRevertRaw(requestParameters: ControlCenterApiManageSupplierRevertRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageSupplierRevert.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/{Id}/revert`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * revert unpublished changes
     */
    async manageSupplierRevert(requestParameters: ControlCenterApiManageSupplierRevertRequest): Promise<void> {
        await this.manageSupplierRevertRaw(requestParameters);
    }

    /**
     * list of supplier
     * search supplier list
     */
    async manageSupplierSearchRaw(requestParameters: ControlCenterApiManageSupplierSearchRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsSupplier>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        if (requestParameters.supplierType !== undefined) {
            queryParameters['supplier_type'] = requestParameters.supplierType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsSupplierFromJSON(jsonValue));
    }

    /**
     * list of supplier
     * search supplier list
     */
    async manageSupplierSearch(requestParameters: ControlCenterApiManageSupplierSearchRequest): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsSupplier> {
        const response = await this.manageSupplierSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * resend email mapped to serial number
     */
    async resendEmailSerialNumberRaw(requestParameters: ControlCenterApiResendEmailSerialNumberRequest): Promise<runtime.ApiResponse<MelcoCoreModelsBoolResponse>> {
        if (requestParameters.serialNumber === null || requestParameters.serialNumber === undefined) {
            throw new runtime.RequiredError('serialNumber','Required parameter requestParameters.serialNumber was null or undefined when calling resendEmailSerialNumber.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/products/licenses/{serial_number}/resend_email`.replace(`{${"serial_number"}}`, encodeURIComponent(String(requestParameters.serialNumber))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * resend email mapped to serial number
     */
    async resendEmailSerialNumber(requestParameters: ControlCenterApiResendEmailSerialNumberRequest): Promise<MelcoCoreModelsBoolResponse> {
        const response = await this.resendEmailSerialNumberRaw(requestParameters);
        return await response.value();
    }

    /**
     * transfer serial number
     */
    async transferSerialNumberRaw(requestParameters: ControlCenterApiTransferSerialNumberRequest): Promise<runtime.ApiResponse<MelcoCoreModelsBoolResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/products/licenses/transfer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsDRMTransferSerialNumberToJSON(requestParameters.melcoCoreModelsDRMTransferSerialNumber),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * transfer serial number
     */
    async transferSerialNumber(requestParameters: ControlCenterApiTransferSerialNumberRequest): Promise<MelcoCoreModelsBoolResponse> {
        const response = await this.transferSerialNumberRaw(requestParameters);
        return await response.value();
    }

    /**
     * edit user permissions for control center
     */
    async userAccountEditPermissionRaw(requestParameters: ControlCenterApiUserAccountEditPermissionRequest): Promise<runtime.ApiResponse<MelcoCoreModelsUserProductPermissions>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userAccountEditPermission.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/account/{user_id}/manage_access`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsUserProductPermissionsToJSON(requestParameters.melcoCoreModelsUserProductPermissions),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsUserProductPermissionsFromJSON(jsonValue));
    }

    /**
     * edit user permissions for control center
     */
    async userAccountEditPermission(requestParameters: ControlCenterApiUserAccountEditPermissionRequest): Promise<MelcoCoreModelsUserProductPermissions> {
        const response = await this.userAccountEditPermissionRaw(requestParameters);
        return await response.value();
    }

    /**
     * get user product permissions from control center
     */
    async userAccountGetPermissionRaw(requestParameters: ControlCenterApiUserAccountGetPermissionRequest): Promise<runtime.ApiResponse<MelcoCoreModelsUserProductPermissions>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userAccountGetPermission.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/account/{user_id}/manage_access`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsUserProductPermissionsFromJSON(jsonValue));
    }

    /**
     * get user product permissions from control center
     */
    async userAccountGetPermission(requestParameters: ControlCenterApiUserAccountGetPermissionRequest): Promise<MelcoCoreModelsUserProductPermissions> {
        const response = await this.userAccountGetPermissionRaw(requestParameters);
        return await response.value();
    }

}
