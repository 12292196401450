/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsSelectListItem,
    MelcoCoreModelsSelectListItemFromJSON,
    MelcoCoreModelsSelectListItemFromJSONTyped,
    MelcoCoreModelsSelectListItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsFusionDefaultAppSetting
 */
export interface MelcoCoreModelsFusionDefaultAppSetting {
    /**
     * 
     * @type {Array<MelcoCoreModelsSelectListItem>}
     * @memberof MelcoCoreModelsFusionDefaultAppSetting
     */
    measurements?: Array<MelcoCoreModelsSelectListItem> | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsSelectListItem>}
     * @memberof MelcoCoreModelsFusionDefaultAppSetting
     */
    emb_formats?: Array<MelcoCoreModelsSelectListItem> | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsFusionDefaultAppSetting
     */
    max_canvas_size?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsFusionDefaultAppSetting
     */
    min_image_size?: number;
}

export function MelcoCoreModelsFusionDefaultAppSettingFromJSON(json: any): MelcoCoreModelsFusionDefaultAppSetting {
    return MelcoCoreModelsFusionDefaultAppSettingFromJSONTyped(json, false);
}

export function MelcoCoreModelsFusionDefaultAppSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsFusionDefaultAppSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'measurements': !exists(json, 'measurements') ? undefined : (json['measurements'] === null ? null : (json['measurements'] as Array<any>).map(MelcoCoreModelsSelectListItemFromJSON)),
        'emb_formats': !exists(json, 'emb_formats') ? undefined : (json['emb_formats'] === null ? null : (json['emb_formats'] as Array<any>).map(MelcoCoreModelsSelectListItemFromJSON)),
        'max_canvas_size': !exists(json, 'max_canvas_size') ? undefined : json['max_canvas_size'],
        'min_image_size': !exists(json, 'min_image_size') ? undefined : json['min_image_size'],
    };
}

export function MelcoCoreModelsFusionDefaultAppSettingToJSON(value?: MelcoCoreModelsFusionDefaultAppSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'measurements': value.measurements === undefined ? undefined : (value.measurements === null ? null : (value.measurements as Array<any>).map(MelcoCoreModelsSelectListItemToJSON)),
        'emb_formats': value.emb_formats === undefined ? undefined : (value.emb_formats === null ? null : (value.emb_formats as Array<any>).map(MelcoCoreModelsSelectListItemToJSON)),
        'max_canvas_size': value.max_canvas_size,
        'min_image_size': value.min_image_size,
    };
}


