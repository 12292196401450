/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsUsersUserAddress,
    MelcoCoreModelsUsersUserAddressFromJSON,
    MelcoCoreModelsUsersUserAddressFromJSONTyped,
    MelcoCoreModelsUsersUserAddressToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsUsersEditSubUser
 */
export interface MelcoCoreModelsUsersEditSubUser {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUsersEditSubUser
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUsersEditSubUser
     */
    first_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUsersEditSubUser
     */
    last_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUsersEditSubUser
     */
    company_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUsersEditSubUser
     */
    website?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUsersEditSubUser
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUsersEditSubUser
     */
    gender_code?: string | null;
    /**
     * 
     * @type {MelcoCoreModelsUsersUserAddress}
     * @memberof MelcoCoreModelsUsersEditSubUser
     */
    address?: MelcoCoreModelsUsersUserAddress;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUsersEditSubUser
     */
    api_key?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsUsersEditSubUser
     */
    service_terms?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUsersEditSubUser
     */
    parent_user_id?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsUsersEditSubUser
     */
    is_locked_out?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsUsersEditSubUser
     */
    is_disabled?: boolean;
}

export function MelcoCoreModelsUsersEditSubUserFromJSON(json: any): MelcoCoreModelsUsersEditSubUser {
    return MelcoCoreModelsUsersEditSubUserFromJSONTyped(json, false);
}

export function MelcoCoreModelsUsersEditSubUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsUsersEditSubUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'first_name': !exists(json, 'first_name') ? undefined : json['first_name'],
        'last_name': !exists(json, 'last_name') ? undefined : json['last_name'],
        'company_name': !exists(json, 'company_name') ? undefined : json['company_name'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'gender_code': !exists(json, 'gender_code') ? undefined : json['gender_code'],
        'address': !exists(json, 'address') ? undefined : MelcoCoreModelsUsersUserAddressFromJSON(json['address']),
        'api_key': !exists(json, 'api_key') ? undefined : json['api_key'],
        'service_terms': !exists(json, 'service_terms') ? undefined : json['service_terms'],
        'parent_user_id': !exists(json, 'parent_user_id') ? undefined : json['parent_user_id'],
        'is_locked_out': !exists(json, 'is_locked_out') ? undefined : json['is_locked_out'],
        'is_disabled': !exists(json, 'is_disabled') ? undefined : json['is_disabled'],
    };
}

export function MelcoCoreModelsUsersEditSubUserToJSON(value?: MelcoCoreModelsUsersEditSubUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'first_name': value.first_name,
        'last_name': value.last_name,
        'company_name': value.company_name,
        'website': value.website,
        'phone': value.phone,
        'gender_code': value.gender_code,
        'address': MelcoCoreModelsUsersUserAddressToJSON(value.address),
        'api_key': value.api_key,
        'service_terms': value.service_terms,
        'parent_user_id': value.parent_user_id,
        'is_locked_out': value.is_locked_out,
        'is_disabled': value.is_disabled,
    };
}


