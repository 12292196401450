/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsCustomWarning,
    MelcoCoreModelsCustomWarningFromJSON,
    MelcoCoreModelsCustomWarningFromJSONTyped,
    MelcoCoreModelsCustomWarningToJSON,
    MelcoCoreModelsProductPermissions,
    MelcoCoreModelsProductPermissionsFromJSON,
    MelcoCoreModelsProductPermissionsFromJSONTyped,
    MelcoCoreModelsProductPermissionsToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsGenericList1MelcoCoreModelsProductPermissions
 */
export interface MelcoCoreModelsGenericList1MelcoCoreModelsProductPermissions {
    /**
     * 
     * @type {Array<MelcoCoreModelsProductPermissions>}
     * @memberof MelcoCoreModelsGenericList1MelcoCoreModelsProductPermissions
     */
    list: Array<MelcoCoreModelsProductPermissions>;
    /**
     * 
     * @type {Array<MelcoCoreModelsCustomWarning>}
     * @memberof MelcoCoreModelsGenericList1MelcoCoreModelsProductPermissions
     */
    warning_list?: Array<MelcoCoreModelsCustomWarning> | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsGenericList1MelcoCoreModelsProductPermissions
     */
    total: number;
}

export function MelcoCoreModelsGenericList1MelcoCoreModelsProductPermissionsFromJSON(json: any): MelcoCoreModelsGenericList1MelcoCoreModelsProductPermissions {
    return MelcoCoreModelsGenericList1MelcoCoreModelsProductPermissionsFromJSONTyped(json, false);
}

export function MelcoCoreModelsGenericList1MelcoCoreModelsProductPermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsGenericList1MelcoCoreModelsProductPermissions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(MelcoCoreModelsProductPermissionsFromJSON)),
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(MelcoCoreModelsCustomWarningFromJSON)),
        'total': json['total'],
    };
}

export function MelcoCoreModelsGenericList1MelcoCoreModelsProductPermissionsToJSON(value?: MelcoCoreModelsGenericList1MelcoCoreModelsProductPermissions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': ((value.list as Array<any>).map(MelcoCoreModelsProductPermissionsToJSON)),
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(MelcoCoreModelsCustomWarningToJSON)),
        'total': value.total,
    };
}


