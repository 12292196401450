import { useCallback, useEffect, useReducer, useState } from "react";
import {
  StitchElement,
  Color,
  Alphabet,
  ElementFactory,
  ImageElement,
  GLRenderer,
} from "@melco/renderer";
import { renderStateReducer } from "@melco/renderer/dist/events";
import {
  ConfiguratorElementConfigurationChangedAction,
  ConfiguratorRenderState,
  ConfiguratorRenderStateUtil,
  ConfiguratorSetBlankPictureAndDesignAction,
  ConfiguratorZoomToFitAction,
} from "../renderer/ConfiguratorRendererActions";
import { MelcoCoreModelsDesign } from "melco-shared-logic/dist/api/models/MelcoCoreModelsDesign";
import { MelcoCoreModelsRendererMatrix } from "melco-shared-logic/dist/api/models/MelcoCoreModelsRendererMatrix";

export type RendererElement = {
  color?: Color;
  activeColorGroupId?: number;
  lettering?: RendererLetteringElement;
};

export type RendererLetteringElement = {
  text: string;
  rfmUrl?: string;
  rendererIndex: number;
  isEditable: boolean;
};

export type ElementConfiguration = {
  elements: RendererElement[];
  resources: Resource[];
};

export type Resource = {
  url: string;
  resourceType: ResourceType;
};

type ResourceType = "design" | "alphabet" | "image";

export type Resources = {
  [url: string]: StitchElement | Alphabet | ImageElement;
};

const initDefaultState = () => {
  return ConfiguratorRenderStateUtil.createDefault();
};

export const getRenderResourceByURL = (resources: Resources, url: string) =>
  resources[url];

export const useRenderer = (
  blankPictureUrl: string,
  design: MelcoCoreModelsDesign,
  designRendererMatrix: MelcoCoreModelsRendererMatrix | undefined,
  elementConfiguration: ElementConfiguration
) => {
  const canvasId = "mfc-renderer-preview";

  const [rendererState, rendererDispatch] = useReducer(
    renderStateReducer,
    initDefaultState()
  );

  const [factory, setFactory] = useState<ElementFactory | null | undefined>(
    undefined
  );
  const [canvas, setCanvas] = useState<HTMLElement | undefined>(undefined);
  const [renderer, setRenderer] = useState<GLRenderer | undefined>(undefined);
  const [resources, setResources] = useState<Resources>({});

  const initCallback = useCallback(
    (
      factory: ElementFactory | null,
      canvas?: HTMLElement,
      renderer?: GLRenderer
    ) => {
      if (factory) {
        setFactory(factory);
      }
      if (canvas) {
        setCanvas(canvas);
      }

      if (renderer) {
        setRenderer(renderer);
      }
    },
    [setFactory, setCanvas, setRenderer]
  );

  // handle canvas size changes
  useEffect(() => {
    if (canvas && renderer) {
      const resizeObserver = new ResizeObserver(() => {
        renderer.handleCanvasSizeChanged();

        // force recalculation of new viewport
        const { clientWidth, clientHeight } = canvas;

        if (factory?.elementUtil) {
          rendererDispatch(
            new ConfiguratorZoomToFitAction(
              undefined,
              clientWidth,
              clientHeight,
              factory.elementUtil
            )
          );
        }
      });

      resizeObserver.observe(canvas);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [canvas, renderer, rendererDispatch, factory?.elementUtil]);

  const downloadResource = useCallback(
    async (resourceUrl: string, elementType: ResourceType) => {
      if (!factory) {
        console.error(
          "Downloading resources is only allowed after the renderer is initialized"
        );
        return;
      }

      // we already have downloaded this item
      if (resources[resourceUrl]) {
        return resources[resourceUrl];
      }

      switch (elementType) {
        case "design":
          const designElement = await factory.createDesignElement({
            designMetadataUrl: resourceUrl,
          });
          setResources((r) => ({ ...r, [resourceUrl]: designElement }));

          return;

        case "alphabet":
          const alphabetElement = await factory.createAlphabet(resourceUrl);
          setResources((r) => ({ ...r, [resourceUrl]: alphabetElement }));

          return;

        case "image":
          const imageElement = await factory.createImageElement({
            imageUrl: resourceUrl,
          });
          setResources((r) => ({ ...r, [resourceUrl]: imageElement }));

          return;
      }
    },
    [factory, resources, setResources]
  );

  useEffect(() => {
    const downloadResources = async () => {
      for (const resource of elementConfiguration.resources) {
        await downloadResource(resource.url, resource.resourceType);
      }
    };

    if (factory) {
      downloadResources();
    }
  }, [factory, downloadResource, elementConfiguration.resources]);

  const isLoading = !factory;

  const rendererDesignElement = design?.rfm_url
    ? getRenderResourceByURL(resources, design.rfm_url)
    : undefined;

  const rendererBlankPictureElement = getRenderResourceByURL(
    resources,
    blankPictureUrl
  );

  // update scene when blank picture or design has been loaded or changed
  useEffect(() => {
    if (isLoading) {
      return;
    }

    rendererDispatch(
      new ConfiguratorSetBlankPictureAndDesignAction(
        rendererBlankPictureElement as ImageElement,
        rendererDesignElement as StitchElement,
        designRendererMatrix
      )
    );

    if (canvas) {
      // zoom to fit product
      const { clientWidth, clientHeight } = canvas;

      if (factory?.elementUtil) {
        rendererDispatch(
          new ConfiguratorZoomToFitAction(
            undefined,
            clientWidth,
            clientHeight,
            factory.elementUtil
          )
        );
      }
    }
  }, [
    isLoading,
    rendererDesignElement,
    rendererBlankPictureElement,
    rendererDispatch,
    canvas,
    factory?.elementUtil,
  ]);

  // update rendered preview when element configuration changes
  useEffect(() => {
    if (isLoading || !rendererDesignElement) {
      return;
    }

    rendererDispatch(
      new ConfiguratorElementConfigurationChangedAction(
        rendererDesignElement as StitchElement,
        elementConfiguration,
        resources
      )
    );
  }, [
    isLoading,
    resources,
    rendererDesignElement,
    rendererDispatch,
    elementConfiguration,
  ]);

  return {
    canvasId,
    initCallback,
    rendererState: rendererState as ConfiguratorRenderState,
    rendererDispatch,
    isLoading,
    resources,
    rendererDesignElement,
    rendererBlankPictureElement,
    canvas,
    elementUtil: factory?.elementUtil,
  };
};
