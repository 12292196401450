/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsCustomProductView,
    MelcoCoreModelsCustomProductViewFromJSON,
    MelcoCoreModelsCustomProductViewFromJSONTyped,
    MelcoCoreModelsCustomProductViewToJSON,
    MelcoCoreModelsCustomWarning,
    MelcoCoreModelsCustomWarningFromJSON,
    MelcoCoreModelsCustomWarningFromJSONTyped,
    MelcoCoreModelsCustomWarningToJSON,
    MelcoCoreModelsPreview,
    MelcoCoreModelsPreviewFromJSON,
    MelcoCoreModelsPreviewFromJSONTyped,
    MelcoCoreModelsPreviewToJSON,
    MelcoCoreModelsUserBlank,
    MelcoCoreModelsUserBlankFromJSON,
    MelcoCoreModelsUserBlankFromJSONTyped,
    MelcoCoreModelsUserBlankToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsCustomProduct
 */
export interface MelcoCoreModelsCustomProduct {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsCustomProduct
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsCustomProduct
     */
    token?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsCustomProduct
     */
    name?: string | null;
    /**
     * 
     * @type {MelcoCoreModelsUserBlank}
     * @memberof MelcoCoreModelsCustomProduct
     */
    blank?: MelcoCoreModelsUserBlank;
    /**
     * 
     * @type {Array<MelcoCoreModelsCustomProductView>}
     * @memberof MelcoCoreModelsCustomProduct
     */
    view_list?: Array<MelcoCoreModelsCustomProductView> | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsCustomProduct
     */
    version?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsCustomProduct
     */
    publish_state?: number;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsCustomProduct
     */
    created_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsCustomProduct
     */
    publish_date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsCustomProduct
     */
    updated_date?: Date | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsPreview>}
     * @memberof MelcoCoreModelsCustomProduct
     */
    default_previews?: Array<MelcoCoreModelsPreview> | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsCustomProduct
     */
    configurator_version?: number | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsCustomWarning>}
     * @memberof MelcoCoreModelsCustomProduct
     */
    warning_list?: Array<MelcoCoreModelsCustomWarning> | null;
}

export function MelcoCoreModelsCustomProductFromJSON(json: any): MelcoCoreModelsCustomProduct {
    return MelcoCoreModelsCustomProductFromJSONTyped(json, false);
}

export function MelcoCoreModelsCustomProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsCustomProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'blank': !exists(json, 'blank') ? undefined : MelcoCoreModelsUserBlankFromJSON(json['blank']),
        'view_list': !exists(json, 'view_list') ? undefined : (json['view_list'] === null ? null : (json['view_list'] as Array<any>).map(MelcoCoreModelsCustomProductViewFromJSON)),
        'version': !exists(json, 'version') ? undefined : json['version'],
        'publish_state': !exists(json, 'publish_state') ? undefined : json['publish_state'],
        'created_date': !exists(json, 'created_date') ? undefined : (new Date(json['created_date'])),
        'publish_date': !exists(json, 'publish_date') ? undefined : (json['publish_date'] === null ? null : new Date(json['publish_date'])),
        'updated_date': !exists(json, 'updated_date') ? undefined : (json['updated_date'] === null ? null : new Date(json['updated_date'])),
        'default_previews': !exists(json, 'default_previews') ? undefined : (json['default_previews'] === null ? null : (json['default_previews'] as Array<any>).map(MelcoCoreModelsPreviewFromJSON)),
        'configurator_version': !exists(json, 'configurator_version') ? undefined : json['configurator_version'],
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(MelcoCoreModelsCustomWarningFromJSON)),
    };
}

export function MelcoCoreModelsCustomProductToJSON(value?: MelcoCoreModelsCustomProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'token': value.token,
        'name': value.name,
        'blank': MelcoCoreModelsUserBlankToJSON(value.blank),
        'view_list': value.view_list === undefined ? undefined : (value.view_list === null ? null : (value.view_list as Array<any>).map(MelcoCoreModelsCustomProductViewToJSON)),
        'version': value.version,
        'publish_state': value.publish_state,
        'created_date': value.created_date === undefined ? undefined : (value.created_date.toISOString()),
        'publish_date': value.publish_date === undefined ? undefined : (value.publish_date === null ? null : value.publish_date.toISOString()),
        'updated_date': value.updated_date === undefined ? undefined : (value.updated_date === null ? null : value.updated_date.toISOString()),
        'default_previews': value.default_previews === undefined ? undefined : (value.default_previews === null ? null : (value.default_previews as Array<any>).map(MelcoCoreModelsPreviewToJSON)),
        'configurator_version': value.configurator_version,
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(MelcoCoreModelsCustomWarningToJSON)),
    };
}


