// FIXME: Refactor this so there is no duplication between Fusion and the configurator
import { ColorUtil } from "@melco/renderer";
import { MelcoCoreModelsColorListItem } from "melco-shared-logic/dist/api/models/MelcoCoreModelsColorListItem";
import { MelcoCoreModelsDesignLetteringElement } from "melco-shared-logic/dist/api/models/MelcoCoreModelsDesignLetteringElement";
import { ColorHelper } from "melco-ui/dist/helper/ColorHelper";

export const { argbToRGB, isValidARGB } = ColorHelper;

export const colorToRendererColor = (color?: MelcoCoreModelsColorListItem) => {
  if (
    !color ||
    !color.color?.argb ||
    !isValidARGB(color.color.argb ?? undefined)
  ) {
    return;
  }

  const rgb = argbToRGB(color.color?.argb ?? undefined);

  return rgbColorToRendererColor(rgb);
};

export const rgbColorToRendererColor = (rgb: string) => {
  const rgbAsHexNumber = parseInt(rgb.replace(/^#/, ""), 16);

  return ColorUtil.fromHexVal(rgbAsHexNumber, 1.0);
};

export const truncateText = (
  lettering?: MelcoCoreModelsDesignLetteringElement
) => {
  if (!lettering) {
    return "";
  }

  const { text, text_max_length: textMaxLength } = lettering;

  if (!text) {
    return "";
  }

  if (textMaxLength && textMaxLength > 0) {
    return text.substring(0, textMaxLength);
  }

  return text;
};
