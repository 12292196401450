/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoCoreModelsBoolResponse,
    MelcoCoreModelsBoolResponseFromJSON,
    MelcoCoreModelsBoolResponseToJSON,
    MelcoCoreModelsError,
    MelcoCoreModelsErrorFromJSON,
    MelcoCoreModelsErrorToJSON,
    MelcoCoreModelsQRCode,
    MelcoCoreModelsQRCodeFromJSON,
    MelcoCoreModelsQRCodeToJSON,
    MelcoCoreModelsUserEmail,
    MelcoCoreModelsUserEmailFromJSON,
    MelcoCoreModelsUserEmailToJSON,
} from '../models';

export interface UtilityApiUtilityGenerateQRCodeRequest {
    text?: string;
    width?: number;
}

export interface UtilityApiUtilityGenerateQRCodePreviewRequest {
    text?: string;
    width?: number;
}

export interface UtilityApiUtilityValidateRequest {
    melcoCoreModelsUserEmail?: MelcoCoreModelsUserEmail;
}

/**
 * 
 */
export class UtilityApi extends runtime.BaseAPI {

    /**
     * generate qr code
     */
    async utilityGenerateQRCodeRaw(requestParameters: UtilityApiUtilityGenerateQRCodeRequest): Promise<runtime.ApiResponse<MelcoCoreModelsQRCode>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }

        if (requestParameters.width !== undefined) {
            queryParameters['width'] = requestParameters.width;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/utility/qrcode/url`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsQRCodeFromJSON(jsonValue));
    }

    /**
     * generate qr code
     */
    async utilityGenerateQRCode(requestParameters: UtilityApiUtilityGenerateQRCodeRequest): Promise<MelcoCoreModelsQRCode> {
        const response = await this.utilityGenerateQRCodeRaw(requestParameters);
        return await response.value();
    }

    /**
     * generate qr code
     */
    async utilityGenerateQRCodePreviewRaw(requestParameters: UtilityApiUtilityGenerateQRCodePreviewRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }

        if (requestParameters.width !== undefined) {
            queryParameters['width'] = requestParameters.width;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/utility/qrcode/preview`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * generate qr code
     */
    async utilityGenerateQRCodePreview(requestParameters: UtilityApiUtilityGenerateQRCodePreviewRequest): Promise<void> {
        await this.utilityGenerateQRCodePreviewRaw(requestParameters);
    }

    /**
     * validate email address
     */
    async utilityValidateRaw(requestParameters: UtilityApiUtilityValidateRequest): Promise<runtime.ApiResponse<MelcoCoreModelsBoolResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/utility/email/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsUserEmailToJSON(requestParameters.melcoCoreModelsUserEmail),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * validate email address
     */
    async utilityValidate(requestParameters: UtilityApiUtilityValidateRequest): Promise<MelcoCoreModelsBoolResponse> {
        const response = await this.utilityValidateRaw(requestParameters);
        return await response.value();
    }

}
