/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsEfsSettingEfsDefaultFormat,
    MelcoCoreModelsEfsSettingEfsDefaultFormatFromJSON,
    MelcoCoreModelsEfsSettingEfsDefaultFormatFromJSONTyped,
    MelcoCoreModelsEfsSettingEfsDefaultFormatToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsEfsSettingEfsUserResponseSetting
 */
export interface MelcoCoreModelsEfsSettingEfsUserResponseSetting {
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsEfsSettingEfsUserResponseSetting
     */
    enable_advanced_options?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsEfsSettingEfsUserResponseSetting
     */
    enable_debug?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsEfsSettingEfsUserResponseSetting
     */
    enable_folder_grouping?: boolean;
    /**
     * 
     * @type {Array<MelcoCoreModelsEfsSettingEfsDefaultFormat>}
     * @memberof MelcoCoreModelsEfsSettingEfsUserResponseSetting
     */
    output_format?: Array<MelcoCoreModelsEfsSettingEfsDefaultFormat> | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsSettingEfsUserResponseSetting
     */
    measurement_unit: string;
}

export function MelcoCoreModelsEfsSettingEfsUserResponseSettingFromJSON(json: any): MelcoCoreModelsEfsSettingEfsUserResponseSetting {
    return MelcoCoreModelsEfsSettingEfsUserResponseSettingFromJSONTyped(json, false);
}

export function MelcoCoreModelsEfsSettingEfsUserResponseSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsEfsSettingEfsUserResponseSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enable_advanced_options': !exists(json, 'enable_advanced_options') ? undefined : json['enable_advanced_options'],
        'enable_debug': !exists(json, 'enable_debug') ? undefined : json['enable_debug'],
        'enable_folder_grouping': !exists(json, 'enable_folder_grouping') ? undefined : json['enable_folder_grouping'],
        'output_format': !exists(json, 'output_format') ? undefined : (json['output_format'] === null ? null : (json['output_format'] as Array<any>).map(MelcoCoreModelsEfsSettingEfsDefaultFormatFromJSON)),
        'measurement_unit': json['measurement_unit'],
    };
}

export function MelcoCoreModelsEfsSettingEfsUserResponseSettingToJSON(value?: MelcoCoreModelsEfsSettingEfsUserResponseSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enable_advanced_options': value.enable_advanced_options,
        'enable_debug': value.enable_debug,
        'enable_folder_grouping': value.enable_folder_grouping,
        'output_format': value.output_format === undefined ? undefined : (value.output_format === null ? null : (value.output_format as Array<any>).map(MelcoCoreModelsEfsSettingEfsDefaultFormatToJSON)),
        'measurement_unit': value.measurement_unit,
    };
}


