/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoAnalyticsModelsUserWidget,
    MelcoAnalyticsModelsUserWidgetFromJSON,
    MelcoAnalyticsModelsUserWidgetFromJSONTyped,
    MelcoAnalyticsModelsUserWidgetToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoAnalyticsModelsManageUserWidgets
 */
export interface MelcoAnalyticsModelsManageUserWidgets {
    /**
     * 
     * @type {Array<MelcoAnalyticsModelsUserWidget>}
     * @memberof MelcoAnalyticsModelsManageUserWidgets
     */
    widget_list?: Array<MelcoAnalyticsModelsUserWidget> | null;
}

export function MelcoAnalyticsModelsManageUserWidgetsFromJSON(json: any): MelcoAnalyticsModelsManageUserWidgets {
    return MelcoAnalyticsModelsManageUserWidgetsFromJSONTyped(json, false);
}

export function MelcoAnalyticsModelsManageUserWidgetsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoAnalyticsModelsManageUserWidgets {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'widget_list': !exists(json, 'widget_list') ? undefined : (json['widget_list'] === null ? null : (json['widget_list'] as Array<any>).map(MelcoAnalyticsModelsUserWidgetFromJSON)),
    };
}

export function MelcoAnalyticsModelsManageUserWidgetsToJSON(value?: MelcoAnalyticsModelsManageUserWidgets | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'widget_list': value.widget_list === undefined ? undefined : (value.widget_list === null ? null : (value.widget_list as Array<any>).map(MelcoAnalyticsModelsUserWidgetToJSON)),
    };
}


