/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsSettingEfsDefaultFormat,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsSettingEfsDefaultFormatFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsSettingEfsDefaultFormatFromJSONTyped,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsSettingEfsDefaultFormatToJSON,
    MelcoCoreModelsSelectListItem,
    MelcoCoreModelsSelectListItemFromJSON,
    MelcoCoreModelsSelectListItemFromJSONTyped,
    MelcoCoreModelsSelectListItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsEfsSettingEfsDefaultAppSetting
 */
export interface MelcoCoreModelsEfsSettingEfsDefaultAppSetting {
    /**
     * 
     * @type {Array<MelcoCoreModelsSelectListItem>}
     * @memberof MelcoCoreModelsEfsSettingEfsDefaultAppSetting
     */
    measurements?: Array<MelcoCoreModelsSelectListItem> | null;
    /**
     * 
     * @type {MelcoCoreModelsGenericList1MelcoCoreModelsEfsSettingEfsDefaultFormat}
     * @memberof MelcoCoreModelsEfsSettingEfsDefaultAppSetting
     */
    output_format_list?: MelcoCoreModelsGenericList1MelcoCoreModelsEfsSettingEfsDefaultFormat;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsEfsSettingEfsDefaultAppSetting
     */
    enable_folder_grouping?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsEfsSettingEfsDefaultAppSetting
     */
    enable_debug?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsEfsSettingEfsDefaultAppSetting
     */
    enable_advanced_options?: boolean;
}

export function MelcoCoreModelsEfsSettingEfsDefaultAppSettingFromJSON(json: any): MelcoCoreModelsEfsSettingEfsDefaultAppSetting {
    return MelcoCoreModelsEfsSettingEfsDefaultAppSettingFromJSONTyped(json, false);
}

export function MelcoCoreModelsEfsSettingEfsDefaultAppSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsEfsSettingEfsDefaultAppSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'measurements': !exists(json, 'measurements') ? undefined : (json['measurements'] === null ? null : (json['measurements'] as Array<any>).map(MelcoCoreModelsSelectListItemFromJSON)),
        'output_format_list': !exists(json, 'output_format_list') ? undefined : MelcoCoreModelsGenericList1MelcoCoreModelsEfsSettingEfsDefaultFormatFromJSON(json['output_format_list']),
        'enable_folder_grouping': !exists(json, 'enable_folder_grouping') ? undefined : json['enable_folder_grouping'],
        'enable_debug': !exists(json, 'enable_debug') ? undefined : json['enable_debug'],
        'enable_advanced_options': !exists(json, 'enable_advanced_options') ? undefined : json['enable_advanced_options'],
    };
}

export function MelcoCoreModelsEfsSettingEfsDefaultAppSettingToJSON(value?: MelcoCoreModelsEfsSettingEfsDefaultAppSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'measurements': value.measurements === undefined ? undefined : (value.measurements === null ? null : (value.measurements as Array<any>).map(MelcoCoreModelsSelectListItemToJSON)),
        'output_format_list': MelcoCoreModelsGenericList1MelcoCoreModelsEfsSettingEfsDefaultFormatToJSON(value.output_format_list),
        'enable_folder_grouping': value.enable_folder_grouping,
        'enable_debug': value.enable_debug,
        'enable_advanced_options': value.enable_advanced_options,
    };
}


