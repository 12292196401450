/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsDesignElement,
    MelcoCoreModelsDesignElementFromJSON,
    MelcoCoreModelsDesignElementFromJSONTyped,
    MelcoCoreModelsDesignElementToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsUpdateDesign
 */
export interface MelcoCoreModelsUpdateDesign {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUpdateDesign
     */
    name?: string | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsDesignElement>}
     * @memberof MelcoCoreModelsUpdateDesign
     */
    element_list?: Array<MelcoCoreModelsDesignElement> | null;
}

export function MelcoCoreModelsUpdateDesignFromJSON(json: any): MelcoCoreModelsUpdateDesign {
    return MelcoCoreModelsUpdateDesignFromJSONTyped(json, false);
}

export function MelcoCoreModelsUpdateDesignFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsUpdateDesign {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'element_list': !exists(json, 'element_list') ? undefined : (json['element_list'] === null ? null : (json['element_list'] as Array<any>).map(MelcoCoreModelsDesignElementFromJSON)),
    };
}

export function MelcoCoreModelsUpdateDesignToJSON(value?: MelcoCoreModelsUpdateDesign | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'element_list': value.element_list === undefined ? undefined : (value.element_list === null ? null : (value.element_list as Array<any>).map(MelcoCoreModelsDesignElementToJSON)),
    };
}


