/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsCustomWarning,
    MelcoCoreModelsCustomWarningFromJSON,
    MelcoCoreModelsCustomWarningFromJSONTyped,
    MelcoCoreModelsCustomWarningToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsDRMUserSerialNumberResponse
 */
export interface MelcoCoreModelsDRMUserSerialNumberResponse {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    melco_customer_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    language?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    serial_number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    display_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    product_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    product_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    batch?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    referral?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    product_level_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    product_version_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    product_version_number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    product_level_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    product_level_short_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    product_level_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    product_level_description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    is_trial_available?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    number_trial_days?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    saleable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    buy_url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    details_url?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    activated_on?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    assigned_user_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    assigned_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    assigned_user_name?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    assigned_date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    master_user_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    master_user_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    master_name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    type?: number;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    created_date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    updated_date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    bridge_email_address?: string | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsCustomWarning>}
     * @memberof MelcoCoreModelsDRMUserSerialNumberResponse
     */
    warning_list?: Array<MelcoCoreModelsCustomWarning> | null;
}

export function MelcoCoreModelsDRMUserSerialNumberResponseFromJSON(json: any): MelcoCoreModelsDRMUserSerialNumberResponse {
    return MelcoCoreModelsDRMUserSerialNumberResponseFromJSONTyped(json, false);
}

export function MelcoCoreModelsDRMUserSerialNumberResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsDRMUserSerialNumberResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'melco_customer_id': !exists(json, 'melco_customer_id') ? undefined : json['melco_customer_id'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'serial_number': !exists(json, 'serial_number') ? undefined : json['serial_number'],
        'display_name': !exists(json, 'display_name') ? undefined : json['display_name'],
        'product_code': !exists(json, 'product_code') ? undefined : json['product_code'],
        'product_name': !exists(json, 'product_name') ? undefined : json['product_name'],
        'batch': !exists(json, 'batch') ? undefined : json['batch'],
        'referral': !exists(json, 'referral') ? undefined : json['referral'],
        'product_level_id': !exists(json, 'product_level_id') ? undefined : json['product_level_id'],
        'product_version_id': !exists(json, 'product_version_id') ? undefined : json['product_version_id'],
        'product_version_number': !exists(json, 'product_version_number') ? undefined : json['product_version_number'],
        'product_level_code': !exists(json, 'product_level_code') ? undefined : json['product_level_code'],
        'product_level_short_code': !exists(json, 'product_level_short_code') ? undefined : json['product_level_short_code'],
        'product_level_name': !exists(json, 'product_level_name') ? undefined : json['product_level_name'],
        'product_level_description': !exists(json, 'product_level_description') ? undefined : json['product_level_description'],
        'is_trial_available': !exists(json, 'is_trial_available') ? undefined : json['is_trial_available'],
        'number_trial_days': !exists(json, 'number_trial_days') ? undefined : json['number_trial_days'],
        'saleable': !exists(json, 'saleable') ? undefined : json['saleable'],
        'buy_url': !exists(json, 'buy_url') ? undefined : json['buy_url'],
        'details_url': !exists(json, 'details_url') ? undefined : json['details_url'],
        'activated_on': !exists(json, 'activated_on') ? undefined : (json['activated_on'] === null ? null : new Date(json['activated_on'])),
        'assigned_user_id': !exists(json, 'assigned_user_id') ? undefined : json['assigned_user_id'],
        'assigned_name': !exists(json, 'assigned_name') ? undefined : json['assigned_name'],
        'assigned_user_name': !exists(json, 'assigned_user_name') ? undefined : json['assigned_user_name'],
        'assigned_date': !exists(json, 'assigned_date') ? undefined : (json['assigned_date'] === null ? null : new Date(json['assigned_date'])),
        'master_user_id': !exists(json, 'master_user_id') ? undefined : json['master_user_id'],
        'master_user_name': !exists(json, 'master_user_name') ? undefined : json['master_user_name'],
        'master_name': !exists(json, 'master_name') ? undefined : json['master_name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'created_date': !exists(json, 'created_date') ? undefined : (json['created_date'] === null ? null : new Date(json['created_date'])),
        'updated_date': !exists(json, 'updated_date') ? undefined : (json['updated_date'] === null ? null : new Date(json['updated_date'])),
        'bridge_email_address': !exists(json, 'bridge_email_address') ? undefined : json['bridge_email_address'],
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(MelcoCoreModelsCustomWarningFromJSON)),
    };
}

export function MelcoCoreModelsDRMUserSerialNumberResponseToJSON(value?: MelcoCoreModelsDRMUserSerialNumberResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'melco_customer_id': value.melco_customer_id,
        'language': value.language,
        'id': value.id,
        'serial_number': value.serial_number,
        'display_name': value.display_name,
        'product_code': value.product_code,
        'product_name': value.product_name,
        'batch': value.batch,
        'referral': value.referral,
        'product_level_id': value.product_level_id,
        'product_version_id': value.product_version_id,
        'product_version_number': value.product_version_number,
        'product_level_code': value.product_level_code,
        'product_level_short_code': value.product_level_short_code,
        'product_level_name': value.product_level_name,
        'product_level_description': value.product_level_description,
        'is_trial_available': value.is_trial_available,
        'number_trial_days': value.number_trial_days,
        'saleable': value.saleable,
        'buy_url': value.buy_url,
        'details_url': value.details_url,
        'activated_on': value.activated_on === undefined ? undefined : (value.activated_on === null ? null : value.activated_on.toISOString()),
        'assigned_user_id': value.assigned_user_id,
        'assigned_name': value.assigned_name,
        'assigned_user_name': value.assigned_user_name,
        'assigned_date': value.assigned_date === undefined ? undefined : (value.assigned_date === null ? null : value.assigned_date.toISOString()),
        'master_user_id': value.master_user_id,
        'master_user_name': value.master_user_name,
        'master_name': value.master_name,
        'type': value.type,
        'created_date': value.created_date === undefined ? undefined : (value.created_date === null ? null : value.created_date.toISOString()),
        'updated_date': value.updated_date === undefined ? undefined : (value.updated_date === null ? null : value.updated_date.toISOString()),
        'bridge_email_address': value.bridge_email_address,
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(MelcoCoreModelsCustomWarningToJSON)),
    };
}


