import { MelcoCoreModelsUserImage } from "melco-shared-logic/dist/api/models/MelcoCoreModelsUserImage";
import { useMemo } from "react";
import { useField } from "formik";
import { ActiveColor } from "./useProductColor";

const getColorById = (
  colorId: string,
  blankPictures?: MelcoCoreModelsUserImage[]
) => {
  const blankImage = (blankPictures ?? []).find((bp) => bp.id === colorId);

  if (!blankImage) {
    return;
  }

  const { color_hex, color_name } = blankImage;

  return {
    id: colorId,
    name: color_name ?? "",
    argb: color_hex ?? "00000000",
  } as ActiveColor;
};

const getAvailableColors = (
  colorIds?: string[],
  blankPictures?: MelcoCoreModelsUserImage[]
) => {
  return (colorIds ?? []).flatMap((colorId) => {
    const color = getColorById(colorId, blankPictures);

    return color ? [color] : [];
  });
};

export const useAvailableProductColors = () => {
  const [{ value: colors }] = useField<string[] | undefined>(
    "product.blank.colors"
  );

  const [{ value: blankPictures }] = useField<
    MelcoCoreModelsUserImage[] | undefined
  >("product.blank.images");

  const availableProductColors = useMemo(
    () => getAvailableColors(colors, blankPictures),
    [colors, blankPictures]
  );

  return availableProductColors;
};
