/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsCustomWarning,
    MelcoCoreModelsCustomWarningFromJSON,
    MelcoCoreModelsCustomWarningFromJSONTyped,
    MelcoCoreModelsCustomWarningToJSON,
    MelcoCoreModelsDesignCollectionDefaultDesign,
    MelcoCoreModelsDesignCollectionDefaultDesignFromJSON,
    MelcoCoreModelsDesignCollectionDefaultDesignFromJSONTyped,
    MelcoCoreModelsDesignCollectionDefaultDesignToJSON,
    MelcoCoreModelsDesignListItem,
    MelcoCoreModelsDesignListItemFromJSON,
    MelcoCoreModelsDesignListItemFromJSONTyped,
    MelcoCoreModelsDesignListItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsDesignCollection
 */
export interface MelcoCoreModelsDesignCollection {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDesignCollection
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDesignCollection
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsDesignCollection
     */
    design_count?: number;
    /**
     * 
     * @type {MelcoCoreModelsDesignCollectionDefaultDesign}
     * @memberof MelcoCoreModelsDesignCollection
     */
    default_design?: MelcoCoreModelsDesignCollectionDefaultDesign;
    /**
     * 
     * @type {Array<MelcoCoreModelsDesignListItem>}
     * @memberof MelcoCoreModelsDesignCollection
     */
    design_list?: Array<MelcoCoreModelsDesignListItem> | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsCustomWarning>}
     * @memberof MelcoCoreModelsDesignCollection
     */
    warning_list?: Array<MelcoCoreModelsCustomWarning> | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsDesignCollection
     */
    publish_state?: number;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsDesignCollection
     */
    created_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsDesignCollection
     */
    publish_date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsDesignCollection
     */
    updated_date?: Date | null;
}

export function MelcoCoreModelsDesignCollectionFromJSON(json: any): MelcoCoreModelsDesignCollection {
    return MelcoCoreModelsDesignCollectionFromJSONTyped(json, false);
}

export function MelcoCoreModelsDesignCollectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsDesignCollection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'design_count': !exists(json, 'design_count') ? undefined : json['design_count'],
        'default_design': !exists(json, 'default_design') ? undefined : MelcoCoreModelsDesignCollectionDefaultDesignFromJSON(json['default_design']),
        'design_list': !exists(json, 'design_list') ? undefined : (json['design_list'] === null ? null : (json['design_list'] as Array<any>).map(MelcoCoreModelsDesignListItemFromJSON)),
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(MelcoCoreModelsCustomWarningFromJSON)),
        'publish_state': !exists(json, 'publish_state') ? undefined : json['publish_state'],
        'created_date': !exists(json, 'created_date') ? undefined : (new Date(json['created_date'])),
        'publish_date': !exists(json, 'publish_date') ? undefined : (json['publish_date'] === null ? null : new Date(json['publish_date'])),
        'updated_date': !exists(json, 'updated_date') ? undefined : (json['updated_date'] === null ? null : new Date(json['updated_date'])),
    };
}

export function MelcoCoreModelsDesignCollectionToJSON(value?: MelcoCoreModelsDesignCollection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'design_count': value.design_count,
        'default_design': MelcoCoreModelsDesignCollectionDefaultDesignToJSON(value.default_design),
        'design_list': value.design_list === undefined ? undefined : (value.design_list === null ? null : (value.design_list as Array<any>).map(MelcoCoreModelsDesignListItemToJSON)),
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(MelcoCoreModelsCustomWarningToJSON)),
        'publish_state': value.publish_state,
        'created_date': value.created_date === undefined ? undefined : (value.created_date.toISOString()),
        'publish_date': value.publish_date === undefined ? undefined : (value.publish_date === null ? null : value.publish_date.toISOString()),
        'updated_date': value.updated_date === undefined ? undefined : (value.updated_date === null ? null : value.updated_date.toISOString()),
    };
}


