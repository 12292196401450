/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsForwardLinkRequest
 */
export interface MelcoCoreModelsForwardLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsForwardLinkRequest
     */
    key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsForwardLinkRequest
     */
    target_url?: string | null;
}

export function MelcoCoreModelsForwardLinkRequestFromJSON(json: any): MelcoCoreModelsForwardLinkRequest {
    return MelcoCoreModelsForwardLinkRequestFromJSONTyped(json, false);
}

export function MelcoCoreModelsForwardLinkRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsForwardLinkRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'target_url': !exists(json, 'target_url') ? undefined : json['target_url'],
    };
}

export function MelcoCoreModelsForwardLinkRequestToJSON(value?: MelcoCoreModelsForwardLinkRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'target_url': value.target_url,
    };
}


