/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsEmailOptInListItem
 */
export interface MelcoCoreModelsEmailOptInListItem {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEmailOptInListItem
     */
    optin_type?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsEmailOptInListItem
     */
    legal_policy_version?: number;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEmailOptInListItem
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsEmailOptInListItem
     */
    accepted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEmailOptInListItem
     */
    source?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEmailOptInListItem
     */
    ip_address?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsEmailOptInListItem
     */
    time_stamp?: Date;
}

export function MelcoCoreModelsEmailOptInListItemFromJSON(json: any): MelcoCoreModelsEmailOptInListItem {
    return MelcoCoreModelsEmailOptInListItemFromJSONTyped(json, false);
}

export function MelcoCoreModelsEmailOptInListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsEmailOptInListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optin_type': !exists(json, 'optin_type') ? undefined : json['optin_type'],
        'legal_policy_version': !exists(json, 'legal_policy_version') ? undefined : json['legal_policy_version'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'accepted': !exists(json, 'accepted') ? undefined : json['accepted'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'ip_address': !exists(json, 'ip_address') ? undefined : json['ip_address'],
        'time_stamp': !exists(json, 'time_stamp') ? undefined : (new Date(json['time_stamp'])),
    };
}

export function MelcoCoreModelsEmailOptInListItemToJSON(value?: MelcoCoreModelsEmailOptInListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'optin_type': value.optin_type,
        'legal_policy_version': value.legal_policy_version,
        'email': value.email,
        'accepted': value.accepted,
        'source': value.source,
        'ip_address': value.ip_address,
        'time_stamp': value.time_stamp === undefined ? undefined : (value.time_stamp.toISOString()),
    };
}


