/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsEfsQueueJobDesignInfo
 */
export interface MelcoCoreModelsEfsQueueJobDesignInfo {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsQueueJobDesignInfo
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsQueueJobDesignInfo
     */
    version?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsQueueJobDesignInfo
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsEfsQueueJobDesignInfo
     */
    db_user_id?: number;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsQueueJobDesignInfo
     */
    publish_state?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsEfsQueueJobDesignInfo
     */
    print_queue_type?: string | null;
}

export function MelcoCoreModelsEfsQueueJobDesignInfoFromJSON(json: any): MelcoCoreModelsEfsQueueJobDesignInfo {
    return MelcoCoreModelsEfsQueueJobDesignInfoFromJSONTyped(json, false);
}

export function MelcoCoreModelsEfsQueueJobDesignInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsEfsQueueJobDesignInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'db_user_id': !exists(json, 'db_user_id') ? undefined : json['db_user_id'],
        'publish_state': !exists(json, 'publish_state') ? undefined : json['publish_state'],
        'print_queue_type': !exists(json, 'print_queue_type') ? undefined : json['print_queue_type'],
    };
}

export function MelcoCoreModelsEfsQueueJobDesignInfoToJSON(value?: MelcoCoreModelsEfsQueueJobDesignInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'version': value.version,
        'name': value.name,
        'db_user_id': value.db_user_id,
        'publish_state': value.publish_state,
        'print_queue_type': value.print_queue_type,
    };
}


