import { useField } from "formik";
import { MelcoCoreModelsDesign } from "melco-shared-logic/dist/api/models/MelcoCoreModelsDesign";
import { MelcoCoreModelsRendererMatrix } from "melco-shared-logic/dist/api/models/MelcoCoreModelsRendererMatrix";
import { useDesignFormikPath } from "./useDesignFormikPath";
import { ActiveView } from "./useActiveView";
import { ActiveColor } from "./useProductColor";

export const useDesign = (
  activeView: ActiveView | undefined,
  activeColor: ActiveColor | undefined
) => {
  const designFormikPath = useDesignFormikPath(activeView, activeColor);
  const rendererMatrixFormikPath = designFormikPath.replace(
    /\.design$/,
    ".renderer_matrix"
  );

  const [{ value: design }] = useField<MelcoCoreModelsDesign | undefined>(
    designFormikPath
  );

  const [{ value: designRendererMatrix }] = useField<
    MelcoCoreModelsRendererMatrix | undefined
  >(rendererMatrixFormikPath);

  return [design, designRendererMatrix] as const;
};
